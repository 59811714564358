import React from "react";
import { Form } from "antd";
import { TextField } from "@mui/material";
import MuiSkeleton from "../../Loading/mui_skeleton";

const MuiInput = (props) => {
  const {
    field,
    label,
    message,
    is_required,
    class_name,
    input_type,
    is_loading,
  } = props;
  return (
    <Form.Item
      name={field}
      rules={[
        {
          required: is_required || false,
          message: message || "",
        },
      ]}
      className="no_margin_form_input"
    >
      {is_loading ? (
        <MuiSkeleton
          variant_shape={"rectangle"}
          width={"100%"}
          height={60}
          animation_type={"wave"}
          color={"#f1f1f1"}
          border_radius={10}
        />
      ) : (
        <TextField
          required={is_required || false}
          id="filled-required"
          label={label}
          variant="standard"
          className={class_name}
          type={input_type || "text"}
        />
      )}
    </Form.Item>
  );
};
export default MuiInput;
