import React from "react";
import { navigate } from "@helpers/navigator";
import EpassDownloadModal from "@views/components/epass_download_modal/epass_download_modal";
import PaperBlock from "@views/components/system/PaperBlock/PaperBlock";
import { ROUTES } from "@views/routes/my_routes";
import { Button, Card, Col, Row, Table } from "antd";

const UserDashboard = () => {
  const handle_nilgiries = () => {
    navigate(ROUTES.APPLY_EPASS);
  };

  const handle_kodaikkanal = () => {
    navigate(ROUTES.APPLY_EPASS);
  };

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const headerStyle = {
    background: "#f0f2f5", // Customize the header background color
    fontWeight: "bold", // Add any other styles you need
  };

  const handle_download = () => {};

  const dataSource = [
    {
      key: "1",
      destination: "Nilgiris",
      appliedOn: "30/10/2024",
      validity: "30/11/2024",
    },
    {
      key: "2",
      destination: "Kodaikkanal",
      appliedOn: "31/10/2024",
      validity: "30/11/2024",
    },
    {
      key: "2",
      destination: "Nilgiris",
      appliedOn: "1/11/2024",
      validity: "30/11/2024",
    },
    {
      key: "2",
      destination: "Nilgiris",
      appliedOn: "12/12/2024",
      validity: "30/12/2024",
    },
    // Add more data as needed
  ];

  const columns = [
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "Applied On",
      dataIndex: "appliedOn",
      key: "appliedOn",
      align: "center",
    },
    {
      title: "Validity",
      dataIndex: "validity",
      key: "validity",
      align: "center",
    },
    {
      title: "Download",
      key: "download",
      align: "center",
      render: (text, record) => (
        <Button type="primary" onClick={() => handle_download(record)}>
          Download
        </Button>
      ),
    },
  ];

  return (
    <>
      <Row gutter={[16, 16]} className={"location-section"}>
        <Col xs={24} sm={12}>
          <Card
            className={"cursor-pointer location-card1"}
            bordered={false}
            onClick={handle_nilgiries}
          >
            <b>Nilgiris</b>
          </Card>
        </Col>
        <Col xs={24} sm={12}>
          <Card
            className={"cursor-pointer location-card2"}
            bordered={false}
            onClick={handle_kodaikkanal}
          >
            <b>Kodaikkanal</b>
          </Card>
        </Col>
        <Col xs={24}>
          <div className={"recent-approvals"}>
            <h5>
              <b>Recent Approvals</b>
            </h5>
            <Table
              dataSource={dataSource}
              columns={columns}
              rowClassName={rowClassName}
              pagination={false}
              style={{ marginTop: 20 }}
              headerStyle={headerStyle}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserDashboard;
