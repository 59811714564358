import { to_disabled_range } from "@helpers/constants";
import { export_to_excel } from "@helpers/functions";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { Button, Card, DatePicker, Spin, Table } from "antd";
import { capitalize } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { query_state_on_date_basis_report } from "@services/redux/slices/reports/state_on_date_basis_report";
const { RangePicker } = DatePicker;

const StateOnDateBasisReport = () => {
  const dispatch = useDispatch();
  const { Column, ColumnGroup } = Table;
  const [deviceType, setDeviceType] = useState("desktop");
  const [report_items, set_report_items] = useState([]);
  const [items, set_items] = useState([]);
  const [filters, set_filters] = useState([]);

  const { items: state_report_items, loading: state_reports_loading } =
    useDynamicSelector("get_state_on_date_basis_report");

  console.log("state_report_items", state_report_items);
  console.log("report_items", report_items);

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth < 768) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  useEffect(() => {
    if (state_report_items) {
      set_report_items(state_report_items);
    }
  }, [state_report_items]);

  useEffect(() => {
    get_state_report_list();
  }, [filters]);

  const get_state_report_list = () => {
    let key = [{ key: "get_state_on_date_basis_report", loading: true }];
    let query = query_state_on_date_basis_report;
    let variables = {
      filter: filters,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    let data = report_items?.map((item) => ({
      date_time: item.date,
      total_count: item.total_count,
      ...item.counts,
    }));
    set_items(data);
  }, [report_items]);

  const columns = [
    <Column
      title={"Date"}
      dataIndex="date"
      fixed="left"
      width={"150px"}
      sortable={false}
      render={(record) => {
        return moment(record).format("DD/MM/YYYY");
      }}
    />,
    report_items?.[0]?.counts.map((list) => {
      return (
        <Column
          title={list?.field_name}
          dataIndex=""
          align="right"
          sortable={false}
          width={"200px"}
          render={(record) => {
            return record?.counts?.find(
              (district) => list?.field_name === district?.field_name
            )?.count;
          }}
        />
      );
    }),
    <Column
      title={"Total"}
      dataIndex="total_count"
      fixed="right"
      width={"150px"}
      sortable={false}
    />,
  ];

  const vehicle_list_download = () => {
    let arranged_data = arrange_xl_report_data(items);
    export_to_excel(arranged_data ?? [], "State on Date Basis Report");
  };

  const arrange_xl_report_data = (data_items) => {
    let totalCounts = {
      ["S.No"]: "Total",
      ["Date"]: "",
    };

    report_items[0].counts.forEach((item) => {
      totalCounts[item.field_name] = 0;
    });

    const rearranged_data = [];
    for (let i = 0; i < report_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
      };
      report_items[0].counts.forEach((item) => {
        const itemData = report_items[i]?.counts?.find(
          (dataItem) => dataItem.field_name === item.field_name
        );
        row_data[item.field_name] = itemData.count;
      });
      row_data["Total Count"] = data_items[i]?.total_count;

      rearranged_data.push(row_data);
    }

    rearranged_data.push(totalCounts);

    return rearranged_data;
  };

  const date_range_picker_applied = (dates) => {
    console.log("date_range_picker_applied", dates);

    if (dates === null) {
      set_filters({
        ...filters,
        from_date: null,
        to_date: null,
      });
    }
    if (dates) {
      set_filters({
        ...filters,
        from_date: new Date(dates[0]),
        to_date: new Date(dates[1]),
      });
    }
  };

  return (
    <>
      <div className="list-wrapper">
        <div className="excel-download-button">
          <div style={{ width: "260px" }}>
            <RangePicker
              width={"200px"}
              placeholder={["From Date", "To Date"]}
              onChange={date_range_picker_applied}
              allowClear={true}
              // value={[
              //   filters.applied_from_date || null,
              //   filters.applied_to_date || null,
              // ]}
              format="DD/MM/YYYY"
              disabledDate={(date) =>
                to_disabled_range(date, "2024-05-06", "2024-06-30")
              }
            />
          </div>
          <Button type="primary" onClick={vehicle_list_download}>
            Download
          </Button>
        </div>

        <div>
          {deviceType === "mobile" ? (
            <div style={{ marginTop: "10px" }}>
              {state_reports_loading && (
                <div className="spinner">
                  <Spin size="large" />
                </div>
              )}
              {items &&
                items?.map((x) => {
                  console.log(x, "x");

                  return (
                    <Card style={{ marginBottom: "15px" }}>
                      <table>
                        <tr>
                          <th>Date</th>
                          <td>{moment(x?.date).format("DD/MM/YYYY")}</td>
                        </tr>
                        <tr>
                          <th>Bus</th>
                          <td>{x?.bus_count || 0}</td>
                        </tr>
                        <tr>
                          <th>Car</th>
                          <td>{x?.car_count || 0}</td>
                        </tr>

                        <tr>
                          <th>Mini Bus</th>
                          <td>{x?.mini_bus_count || 0}</td>
                        </tr>
                        <tr>
                          <th>Two Wheelers</th>
                          <td>{x?.two_wheelers_count || 0}</td>
                        </tr>
                        <tr>
                          <th>Van</th>
                          <td>{x?.van_count || 0}</td>
                        </tr>
                        <tr>
                          <th>Others</th>
                          <td>{x?.others_count || 0}</td>
                        </tr>
                        <tr>
                          <th>Total Count</th>
                          <td>{x?.total_count || 0}</td>
                        </tr>
                      </table>
                    </Card>
                  );
                })}
            </div>
          ) : (
            <Table
              dataSource={report_items}
              pagination={false}
              // loading={state_reports_loading}
              rowClassName={"custom-row"}
              bordered
              scroll={{
                x: 1300,
              }}
            >
              {columns}
            </Table>
          )}
        </div>
      </div>
    </>
  );
};

export default StateOnDateBasisReport;
