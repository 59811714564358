import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import useStyles from "./user-jss";
import { Form, Input } from "antd";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { FormControlLabel, TextField } from "@mui/material";
import { ArrowForward, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  dynamicClear,
  dynamicRequest,
  login,
  query_get_captcha,
  useDynamicSelector,
  userLogin,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { storeItem } from "@helpers/storage";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import MuiInput from "@views/components/system/Forms/form_elements/mui_input";
import MuiPassword from "@views/components/system/Forms/form_elements/mui_password";
import SubmitButton from "@views/components/system/Forms/form_elements/submit_button";
import { MethodType } from "@helpers/service_call";
import { MdOutlineRefresh } from "react-icons/md";
import { IoReloadOutline } from "react-icons/io5";
import { HiPuzzle } from "react-icons/hi";
import { decrypt_data, encrypt_data } from "@helpers/crypto";
import { navigate } from "@helpers/navigator";
import { showToast } from "@helpers/toast";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

function LoginFormAdmin(props) {
  const { classes, cx } = useStyles();
  const { deco, is_india } = props;

  const {
    loading: captcha_loading,
    error: captcha_error,
    captcha,
    id,
  } = useDynamicSelector("request_captcha");

  const dispatch = useDispatch();

  const [entered_captcha, setCaptcha] = useState("");
  const [has_otp, set_has_otp] = useState(true);
  const canvasRef = useRef();

  const {
    data,
    error,
    loading: login_loading,
  } = useDynamicSelector("login_admin");

  console.log("data", data);

  useEffect(() => {
    get_login_captcha();
  }, []);

  useEffect(() => {
    if (error?.message) {
      showToast({ type: "error", message: error?.message });
      dispatch(dynamicClear("login_admin"));
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      let decrypted_data = decrypt_data(data);
      storeItem("user", decrypted_data);
      console.log("decrypt_data", decrypted_data);
      navigate(ROUTES.ADMIN_DASHBOARD);
    }
  }, [data]);

  useEffect(() => {
    if (captcha) {
      console.log("captcha", captcha);
      createCaptcha();
    }
  }, [captcha]);

  const handle_submit = (values) => {
    let user_login_key = [{ key: "login_admin", loading: true }];
    let user_login_query = login;
    let user_login_variables = {
      data: {
        username: values?.username,
        password: values?.password,
        captcha: encrypt_data(values?.captcha),
        captcha_id: id,
      },
    };
    dispatch(
      dynamicRequest(user_login_key, user_login_query, user_login_variables)
    );
  };

  const createCaptcha = () => {
    let decryptCaptcha = decrypt_data(captcha);

    if (canvasRef && canvasRef.current) {
      let canvas = canvasRef.current;
      canvas.width = 150;
      canvas.height = 100;
      let ctx = canvas.getContext("2d");
      ctx.font = "bold 20px Arial";
      ctx.fillStyle = "#13304e";
      ctx.fillText(decryptCaptcha, 10, 20);
      ctx.textAlign = "center";
      ctx.alignItems = "center";
      ctx.justifyContent = "center";
    }
  };

  const handle_click = () => {
    navigate(ROUTES.DASHBOARD);
  };

  const get_login_captcha = () => {
    let key = [{ key: "request_captcha", loading: true }];
    let query = query_get_captcha;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  return (
    <>
      <div className={classes.topBar}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        ></div>
      </div>
      <section className={classes.socmedSideLogin}>
        <div className={classes.btnArea}></div>
      </section>
      <section>
        <Form onFinish={handle_submit}>
          <MuiInput
            field={"username"}
            label={"Username"}
            message={"Username is required"}
            is_required={true}
            class_name={classes.field}
          />

          <MuiPassword
            field={"password"}
            label={"Password"}
            message={"Password is required"}
            is_required={true}
            class_name={classes.field}
          />
          <>
            <div className="captcha-section">
              <div>
                <div>
                  <div
                    style={{
                      borderRadius: "10px",
                      // backgroundColor: "#aec4d9",
                      alignItems: "center",
                      width: "140px",
                      height: "40px",
                      border: "1px solid grey",
                    }}
                  >
                    <canvas
                      ref={canvasRef}
                      style={{
                        marginLeft: "50px",
                        objectFit: "cover",
                        marginTop: "6px",
                        display: captcha_loading ? "none" : "block",
                      }}
                    />
                    {captcha_loading && (
                      <div class="leap-frog">
                        <div class="leap-frog__dot"></div>
                        <div class="leap-frog__dot"></div>
                        <div class="leap-frog__dot"></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ marginTop: "7px", margin: "5px" }}>
                  <IoReloadOutline
                    onClick={get_login_captcha}
                    size={20}
                    style={{ cursor: "pointer", color: "#14304e" }}
                  />
                </span>
                <Form.Item
                  className="form-captcha"
                  name={"captcha"}
                  rules={[
                    {
                      required: true,
                      message: "Captcha is required",
                    },
                  ]}
                >
                  <Input
                    style={{
                      borderRadius: "40px",
                      height: "45px",
                      // borderColor: "#14304e",
                    }}
                    className="captcha-input"
                    maxLength={6}
                    placeholder="Captcha"
                    // defaultValue={text}
                    // onPressEnter={handleKeypress}
                    onChange={(txt) => setCaptcha(txt.target.value)}
                    onChangeText={(txt) => setCaptcha(txt)}
                    autoComplete={false}
                    prefix={
                      entered_captcha ? (
                        // <HiPuzzle color="#14304e" />
                        <></>
                      ) : (
                        // <HiPuzzle color="#b5a4a4" />
                        <></>
                      )
                    }
                  />
                </Form.Item>
              </div>
            </div>
          </>
          <SubmitButton
            label={"Login"}
            show_icon={true}
            class_name={classes.btnArea}
            icon_class_name={cx(classes.rightIcon, classes.iconSmall)}
            loading={login_loading}
            // handle_click={handle_click}
          />
        </Form>
      </section>
    </>
    // </Paper>
  );
}

export default LoginFormAdmin;
