import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Info from "@mui/icons-material/Info";
import Warning from "@mui/icons-material/Warning";
import Check from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/RemoveCircle";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
// import messageStyles from "../styles/Messages.scss";
import messageStyles from "../../../components/styles/components/Messages.scss";
// import link from 'dan-api/ui/link';
import useStyles from "./header-jss";
import { IoIosNotificationsOutline } from "react-icons/io";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { clearLocalStorage, retrieveItem } from "@helpers/storage";
import { dynamicClear } from "@services/redux";
import { useDispatch } from "react-redux";
import { setItem } from "@helpers/encrypt_storage";
import { useHistory } from "react-router-dom";

const avatarApi = [
  "/images/avatars/pp_girl.svg",
  "/images/avatars/pp_girl.svg",
  "/images/avatars/pp_girl2.svg",
  "/images/avatars/pp_girl3.svg",
  "/images/avatars/pp_girl4.svg",
  "/images/avatars/pp_girl5.svg",
  "/images/avatars/pp_boy.svg",
  "/images/avatars/pp_boy2.svg",
  "/images/avatars/pp_boy3.svg",
  "/images/avatars/pp_boy4.svg",
  "/images/avatars/pp_boy5.svg",
];
const dummy = {
  user: {
    name: "John Doe",
    title: "Administrator",
    avatar: avatarApi[6],
    status: "online",
  },
  text: {
    title: "Lorem ipsum",
    subtitle: "Ut a lorem eu odio cursus laoreet.",
    sentences:
      "Donec lacus sem, scelerisque sed ligula nec, iaculis porttitor mauris.",
    paragraph:
      "Sed rutrum augue libero, id faucibus quam aliquet sed. Phasellus interdum orci quam, volutpat ornare eros rhoncus sed. Donec vestibulum leo a auctor convallis. In dignissim consectetur molestie. Vivamus interdum tempor dui, nec posuere augue consequat sit amet. Suspendisse quis semper quam. Nullam nec neque sem.",
    date: "Jan 9, 2018",
  },
};
function UserMenu(props) {
  const { classes, cx } = useStyles();
  const [menuState, setMenuState] = useState({
    anchorEl: null,
    openMenu: null,
  });
  const user = retrieveItem("user");
  const history = useHistory();
  const dispatch = useDispatch();

  const handleMenu = (menu) => (event) => {
    const { openMenu } = menuState;
    setMenuState({
      openMenu: openMenu === menu ? null : menu,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    setMenuState({ anchorEl: null, openMenu: null });
  };

  const Logout = () => {
    dispatch(dynamicClear("login_admin"));
    setItem("user", "");
    clearLocalStorage();
    navigate(ROUTES.ADMIN_LOGIN);
  };

  const { dark } = props;
  const { anchorEl, openMenu } = menuState;
  return (
    <div>
      {/* <IconButton
        aria-haspopup="true"
        onClick={handleMenu("notification")}
        color="inherit"
        className={cx(classes.notifIcon, dark ? classes.dark : classes.light)}
        size="large"
      >
        <Badge className={classes.badge} badgeContent={4} color="secondary">
          <i className="ion-ios-notifications-outline" />
        </Badge>
      </IconButton> */}
      <Menu
        id="menu-notification"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.notifMenu}
        PaperProps={{
          style: {
            width: 350,
          },
        }}
        open={openMenu === "notification"}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <div className={messageStyles.messageInfo}>
            <ListItemAvatar>
              <Avatar alt="User Name" src={avatarApi[0]} />
            </ListItemAvatar>
            <ListItemText
              primary={dummy.text.subtitle}
              secondary={dummy.text.date}
            />
          </div>
        </MenuItem>
        <Divider variant="inset" />
        <MenuItem onClick={handleClose}>
          <div className={messageStyles.messageInfo}>
            <ListItemAvatar>
              <Avatar className={cx(classes.flex, messageStyles.icon)}>
                <Info />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={dummy.text.sentences}
              className={classes.textNotif}
              secondary={dummy.text.date}
            />
          </div>
        </MenuItem>
        <Divider variant="inset" />
        <MenuItem onClick={handleClose}>
          <div className={messageStyles.messageSuccess}>
            <ListItemAvatar>
              <Avatar className={messageStyles.icon}>
                <Check />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={dummy.text.subtitle}
              className={classes.textNotif}
              secondary={dummy.text.date}
            />
          </div>
        </MenuItem>
        <Divider variant="inset" />
        <MenuItem onClick={handleClose}>
          <div className={messageStyles.messageWarning}>
            <ListItemAvatar>
              <Avatar className={messageStyles.icon}>
                <Warning />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={dummy.text.subtitle}
              className={classes.textNotif}
              secondary={dummy.text.date}
            />
          </div>
        </MenuItem>
        <Divider variant="inset" />
        <MenuItem onClick={handleClose}>
          <div className={messageStyles.messageError}>
            <ListItemAvatar>
              <Avatar className={messageStyles.icon}>
                <Error />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Suspendisse pharetra pulvinar sollicitudin. Aenean ut orci eu odio cursus lobortis eget tempus velit. "
              className={classes.textNotif}
              secondary="Jan 9, 2016"
            />
          </div>
        </MenuItem>
      </Menu>
      <div
        onClick={handleMenu("user-setting")}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <h5 className="login-user-name-header">
          <b>{user?.user?.name}</b>
        </h5>
        <Button>
          <Avatar
            alt={dummy.user.name}
            src={require("../../../../assets/icons/profile.png")}
          />
        </Button>
      </div>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openMenu === "user-setting"}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={() => {}}>My Profile</MenuItem> */}
        {/* <MenuItem onClick={() => {}}>My Calendar</MenuItem> */}

        {/* <Divider /> */}
        <MenuItem onClick={Logout} to="/login">
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          Log Out
        </MenuItem>
      </Menu>
    </div>
  );
}

UserMenu.propTypes = {
  dark: PropTypes.bool,
};

UserMenu.defaultProps = {
  dark: false,
};

export default UserMenu;
