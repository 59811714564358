import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function Modal(props) {
  const {
    content,
    is_open,
    scroll,
    title,
    footer,
    handleClose,
    footer_content,
    width,
    full_width,
    is_closable,
  } = props;

  return (
    <Dialog
      open={is_open}
      onClose={!is_closable ? handleClose : () => {}}
      scroll={scroll}
      fullWidth={full_width}
      maxWidth={width ? width : "md"}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{
          alignItems: "center",
          display: "flex",
        }}
      >
        {title}
        {!is_closable && (
          <IconButton
            onClick={handleClose}
            aria-label="Close"
            size="large"
            style={{ marginLeft: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent
        // sx={{
        //   width: width || 500,
        // }}
        dividers={true}
      >
        {content}
      </DialogContent>
      {footer && footer_content}
    </Dialog>
  );
}
