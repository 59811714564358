import { useCheckLogin } from "@helpers/auth";
import { GoogleAPIKey } from "@helpers/constants";
import { navigate } from "@helpers/navigator";
import { Box } from "@mui/material";
import LoginForm from "@views/components/auth/login_form";
import LoginFormAdmin from "@views/components/auth/login_form_admin";
import MapWithMarker from "@views/components/map/map_with_marker";
import { ROUTES } from "@views/routes/my_routes";

import React, { useEffect, useState } from "react";
const ScannedLocations = (props) => {
  let map_list = [
    {
      latlong: [11.3375389, 76.9389192],
      total_scans: 10,
    },
    {
      latlong: [11.317599698091646, 76.9323905767175],
      total_scans: 262,
    },
    {
      latlong: [11.531474452414852, 76.25075729971576],
      total_scans: 704,
    },
    {
      latlong: [11.579889757142855, 76.25347468178593],
      total_scans: 560,
    },
    {
      latlong: [11.297524374117645, 76.71544107764696],
      total_scans: 255,
    },
    {
      latlong: [11.583536745565745, 76.34609675275232],
      total_scans: 654,
    },
    {
      latlong: [11.312179445922768, 76.92866413133049],
      total_scans: 233,
    },
    {
      latlong: [11.470156708957875, 76.41229740396233],
      total_scans: 1161,
    },
    {
      latlong: [11.53516833335488, 76.52372146112849],
      total_scans: 1559,
    },
    {
      latlong: [11.33813698430992, 76.87836750197718],
      total_scans: 4608,
    },
    {
      latlong: [11.577774744852315, 76.62822239113162],
      total_scans: 2943,
    },
    {
      latlong: [11.318552191689033, 76.93287352198406],
      total_scans: 746,
    },
    {
      latlong: [11.318222176740539, 76.93245897246861],
      total_scans: 632,
    },
    {
      latlong: [11.466691158823515, 76.41253190980369],
      total_scans: 306,
    },
    {
      latlong: [11.361494996575345, 76.91890471506849],
      total_scans: 146,
    },
  ];

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <MapWithMarker locations={map_list} />
    </div>
  );
};
export default ScannedLocations;
