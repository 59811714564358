import React, { useEffect, useState } from "react";
import {
//   get_roles_query,
//   create_role_mutation,
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  useDynamicSelector,
//   update_role_mutation,
//   delete_role_mutation,
//   get_ui_modules_query,
} from "@services/redux";
import {
  Button,
  Select,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { makeStyles } from "tss-react/mui";
import { Form, Input } from "antd";
import Modal from "@views/components/system/Modal/modal";
import { create_role_mutation, get_roles_query, get_ui_modules_query, update_role_mutation } from "@services/redux/slices/access_rights/graphql_access_right";

const useStyles = makeStyles()((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ManageRole = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { loading: create_role_loading, status: create_role_status } =
    useDynamicSelector("create_role");
  const { loading: update_role_loading, status: update_role_status } =
    useDynamicSelector("update_role");
  const { loading: delete_role_loading, status: delete_role_status } =
    useDynamicSelector("delete_role");
  const role_action = useDynamicSelector("role_action");
  const role_item = useDynamicSelector("role_item");
  const { items: pages } = useDynamicSelector("pages");
  const [page_options, setPageOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const { classes } = useStyles();

  useEffect(() => {
    loadRoles(true);
  }, []);

  useEffect(() => {
    let _options = [];
    if (pages) {
      for (let i = 0; i < pages.length; i++) {
        _options.push({
          value: pages[i].id,
          label: pages[i].name,
        });
      }
      setPageOptions(_options);
    }
  }, [pages]);

  useEffect(() => {
    if (role_action === "Add") {
      form.resetFields();

      setOpen(true);
    } else if (role_action === "Edit") {
      form.setFieldsValue(role_item);
      setOpen(true);
    } else if (role_action === "Delete") {
      setOpen(true);
    }
  }, [role_action]);

  useEffect(() => {
    if (create_role_status === "success") {
      toast.success("Role added successfully");
      loadRoles(false);
      loadPages(false);
      closeModal();
      dispatch(dynamicClear("create_role"));
    } else if (create_role_status === "failure") {
      toast.error("Role cannot be created");

      dispatch(dynamicClear("create_role"));
    }
  }, [create_role_status]);

  useEffect(() => {
    if (update_role_status === "success") {
      toast.success("Role updated successfully");

      loadRoles(false);
      loadPages(false);

      closeModal();
      dispatch(dynamicClear("update_role"));
    } else if (update_role_status === "failure") {
      toast.error("Role cannot be updated");

      dispatch(dynamicClear("update_role"));
    }
  }, [update_role_status]);

  useEffect(() => {
    if (delete_role_status === "success") {
      toast.success("Role deleted successfully");

      loadRoles(false);
      loadPages(false);

      closeModal();
      dispatch(dynamicClear("delete_role"));
    } else if (delete_role_status === "failure") {
      toast.error("Role cannot be deleted");

      dispatch(dynamicClear("delete_role"));
    }
  }, [delete_role_status]);

  const loadRoles = (should_load) => {
    let keys = [{ key: "get_system_role_list", loading: should_load }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_roles_query, variables));
  };
  const loadPages = (should_load) => {
    let keys = [{ key: "get_system_ui_module_list", loading: should_load }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_ui_modules_query, variables));
  };
  const openAddRole = () => {
    dispatch(dynamicSet("role_action", "Add"));
  };

  const closeModal = () => {
    dispatch(dynamicSet("role_action", ""));
    setOpen(false);
  };

  const handleSubmit = (values) => {
    // event.preventDefault();
    // const formData = new FormData(event.target);
    // const values = Object.fromEntries(formData.entries());
    if (role_action === "Add") {
      let keys = [{ key: "create_role", loading: true }];
      let variables = {
        data: values,
      };
      dispatch(dynamicRequest(keys, create_role_mutation, variables, "M"));
    } else if (role_action === "Edit") {
      let keys = [{ key: "update_role", loading: true }];
      let variables = {
        id: role_item.id,
        data: values,
      };
      dispatch(dynamicRequest(keys, update_role_mutation, variables, "M"));
    }
  };

  const handleDelete = () => {
    let keys = [{ key: "delete_role", loading: true }];
    let variables = {
      id: role_item.id,
    };
    dispatch(dynamicRequest(keys, delete_role_mutation, variables, "M"));
  };
  return (
    <>
      <Box width="100%">
        <Button variant="contained" onClick={openAddRole}>
          Add Role
        </Button>
      </Box>

      <Modal
        is_open={open}
        handleClose={closeModal}
        scroll={"body"}
        width={350}
        content={
          <>
            {role_action !== "Delete" && (
              <Form
                form={form}
                name={"manage_role"}
                className="access_rights_form"
                layout={"horizontal"}
                onFinish={handleSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label="Name"
                  name="name"
                  style={{ color: "black" }}
                  rules={[{ required: true, message: "Name is mandatory" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Home Page"
                  name="home_ui_module_id"
                  style={{ color: "black" }}
                >
                  <Select variant="standard" sx={{ width: "100%" }}>
                    {page_options?.map((x, i) => {
                      return (
                        <MenuItem key={i} value={x.value}>
                          {x.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Form>
            )}
            {role_action === "Delete" && (
              <Typography
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                Do you want to delete the role "
                <Typography fontWeight="bold">{role_item.name}</Typography>"?
              </Typography>
            )}
          </>
        }
        title={`${role_action} Role`}
        footer={true}
        footer_content={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              margin: 10,
            }}
          >
            <Button
              type="reset"
              variant="outlined"
              key="cancel"
              color="warning"
              onClick={closeModal}
              size="medium"
            >
              Cancel
            </Button>
            {role_action === "Delete" && (
              <Button
                key="delete"
                onClick={handleDelete}
                loading={delete_role_loading}
                variant="contained"
                type="submit"
                sx={{
                  marginLeft: 1,
                }}
              >
                Delete
              </Button>
            )}
            {role_action !== "Delete" && (
              <Button
                key="submit"
                variant="contained"
                type="submit"
                form="manage_role"
                htmlType="submit"
                loading={
                  role_action === "Add"
                    ? create_role_loading
                    : update_role_loading
                }
                sx={{
                  marginLeft: 1,
                }}
              >
                {role_action === "Add" && "Add"}
                {role_action === "Edit" && "Update"}
              </Button>
            )}
          </div>
        }
      />
    </>
  );
};

export default ManageRole;
