import { Card, Col, Row } from "antd";
import React from "react";

const EPassLoginModal = (props) => {
  const { outside_india, within_india } = props;

  return (
    <div className="epass-landing-modal">
      <Row gutter={24}>
        <Col span={12}>
          <Card
            className={"cursor-pointer"}
            bordered={false}
            onClick={outside_india}
          >
            <b>Outside India</b>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            className={"cursor-pointer"}
            bordered={false}
            onClick={within_india}
          >
            <b>From Within India</b>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default EPassLoginModal;
