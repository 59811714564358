import { gql } from "@apollo/client";

export const query_fuel_type = gql`
  query get_fuel_type_on_date_basis_report($filter: report_filter_input) {
    get_fuel_type_on_date_basis_report(filter: $filter) {
      items {
        total_count
        counts {
          count
          field_name
        }
        total_count
        date
      }
      error {
        status_code
        message
      }
    }
  }
`;
