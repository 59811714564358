import { gql } from "@apollo/client";

export const get_vehicle_entry_list_report_query = gql`
  query get_vehicle_type_on_entry_date_basis_report(
    $filter: report_filter_input
  ) {
    get_vehicle_type_on_entry_date_basis_report(filter: $filter) {
      items {
        date
        counts {
          field_name: vehicle_type
          count
        }
        total_count
      }
    }
  }
`;

export const get_vehicle_entry_list_query = gql`
  query get_vehicle_type_on_entry_date_basis_report(
    $filter: report_filter_input
  ) {
    get_vehicle_type_on_entry_date_basis_report(filter: $filter) {
      items {
        date
        counts {
          field_name: vehicle_type
          count
        }
        total_count
      }
    }
  }
`;
