import { gql } from "@apollo/client";

export const query_state_on_entry_date_basis_report = gql`
  query get_state_on_entry_date_basis_report($filter: report_filter_input) {
    get_state_on_entry_date_basis_report(filter: $filter) {
      items {
        total_count
        counts {
          count
          field_name
        }
        date
      }
    }
  }
`;
