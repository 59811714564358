import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { validateMobileNumber } from "@helpers/functions";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { mutation_create_checker } from "@services/redux/slices/checker_list/graphql_checker_list";
import { useDispatch } from "react-redux";
import { showToast } from "@helpers/toast";

const AddCheckerForm = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    status: police_create_status,
    error: create_police_error,
    loading: create_police_loading,
  } = useDynamicSelector("create_police");

  const { handle_cancel } = props;

  useEffect(() => {
    if (props?.type === "add") {
      form.resetFields();
    }
  }, []);

  useEffect(() => {
    if (police_create_status == "success") {
      showToast({ type: "success", message: "Checker created successfully" });
      dispatch(dynamicClear("create_police"));
      props?.get_all_checker_list();
      props?.handle_cancel();
    } else if (create_police_error?.message) {
      showToast({ type: "error", message: create_police_error?.message });
      dispatch(dynamicClear("create_police"));
      props?.handle_cancel();
    }
  }, [police_create_status, create_police_error]);

  const create_checker = (values) => {
    let key = [{ key: "create_police", loading: true }];
    let query = mutation_create_checker;
    let variables = {
      data: {
        ...values,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_add_checker = (values) => {
    create_checker(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleNameChange = (e) => {
    form.setFieldsValue({ name: e.target.value });
  };

  return (
    <Form
      form={form}
      onFinish={handle_add_checker}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Form.Item
            label="Mobile"
            name="mobile"
            rules={[
              { required: true, message: "" },
              {
                validator: validateMobileNumber,
              },
            ]}
          >
            <Input placeholder="Mobile" type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item label="Name" name="name" labelAlign="top">
            <Input placeholder="Name" onChange={handleNameChange} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item className="add-checker-submit-wrapper">
        <Button onClick={handle_cancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={create_police_loading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddCheckerForm;
