import { useEffect, useState } from "react";
import { Button, Table, Space, Pagination, Card } from "antd";
import AddCheckerModal from "./add_checker_modal";
import UploadExcelModal from "./upload_excel_modal";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import {
  mutation_delete_checker,
  query_get_checker_list,
} from "@services/redux/slices/checker_list/graphql_checker_list";
import moment from "moment";
import { retrieveItem } from "@helpers/storage";
import { DeleteOutlined } from "@ant-design/icons";
import CommonDeleteModal from "../common/common_delete_modal";

const CheckerList = () => {
  const dispatch = useDispatch();
  const [checker_delete_modal_visible, set_checker_delete_modal_visible] =
    useState(false);

  const [record, set_record] = useState({});

  const {
    items: checker_list_items,
    pagination: checker_list_pagination,
    loading: checker_list_loading,
  } = useDynamicSelector("get_checker_list");

  const user = retrieveItem("user");

  const [checker_modal_visible, set_checker_modal_visible] = useState(false);
  const [excel_modal_visible, set_excel_modal_visible] = useState(false);
  const [type, set_type] = useState("");

  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  useEffect(() => {
    get_checker_list();
  }, [page_number, page_limit]);

  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth < 768) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  const get_checker_list = () => {
    let key = [{ key: "get_checker_list", loading: true }];
    let query = query_get_checker_list;
    let variables = {
      pagination: {
        page_number,
        page_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const delete_checker = (record) => {
    set_checker_delete_modal_visible(true);
    set_record(record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      align: "center",
    },
    {
      title: "Last Logged In On",
      dataIndex: "last_login_datetime",
      key: "lastLoginDate",
      align: "center",
      render: (data) => {
        return moment(Number(data)).format("DD/MM/YYYY hh:mm a");
      },
      sorter: (a, b) => a.last_login_datetime - b.last_login_datetime,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Total Checks",
      dataIndex: "verified_application_count",
      key: "totalChecks",
      sorter: (a, b) =>
        a.verified_application_count - b.verified_application_count,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      width: 100,
      dataIndex: "",
      key: "",
      align: "center",
      render: (text, record) => (
        <Space
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DeleteOutlined
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => delete_checker(record)}
          />
        </Space>
      ),
    },
  ];

  const handle_add_checker = () => {
    set_checker_modal_visible(true);
    set_type("add");
  };

  const close_excel_modal = () => {
    set_excel_modal_visible(false);
  };
  const open_excel_modal = () => {
    set_excel_modal_visible(true);
  };

  const handle_upload = () => {};

  const fetch_data = (page_number, page_limit) => {
    set_page_limit(page_limit);
    set_page_number(page_number);
  };

  return (
    <div className="list-wrapper">
      <div className="table-action-buttons">
        <Space>
          <Button type="primary" onClick={open_excel_modal}>
            Upload Excel
          </Button>

          {user?.user?.roles?.[0]?.name != "Admin" && (
            <Button type="primary" onClick={handle_add_checker}>
              Add
            </Button>
          )}
        </Space>
      </div>
      <div>
        {deviceType === "mobile" ? (
          <div style={{ marginTop: "10px" }}>
            {checker_list_items?.map((x) => (
              <Card style={{ marginBottom: "15px" }}>
                <table>
                  <tr>
                    <th>Name</th>
                    <td>{x.name}</td>
                  </tr>
                  <tr>
                    <th>Mobile</th>
                    <td>{x.mobile}</td>
                  </tr>
                  <tr>
                    <th>Last Logged in on</th>
                    <td>
                      {moment(Number(x.last_login_datetime)).format(
                        "DD/MM/YYYY hh:mm a"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Total Checks</th>
                    <td>{x.verified_application_count}</td>
                  </tr>
                </table>
              </Card>
            ))}
          </div>
        ) : (
          <Table
            dataSource={checker_list_items}
            columns={columns}
            pagination={false}
            className="custom-table"
            rowClassName={"custom-row"}
            loading={checker_list_loading}
          />
        )}
        <div className="table-pagination">
          <Pagination
            showTotal={(total) => `Total ${total} items`}
            total={checker_list_pagination?.total_count}
            showSizeChanger={true}
            page_number={3}
            hideOnSinglePage={false}
            onChange={fetch_data}
          />
        </div>
      </div>

      <AddCheckerModal
        get_checker_list={get_checker_list}
        visible={checker_modal_visible}
        set_visible={set_checker_modal_visible}
        type={type}
      />

      <UploadExcelModal
        visible={excel_modal_visible}
        handle_cancel={close_excel_modal}
        handle_upload={handle_upload}
      />

      <CommonDeleteModal
        text={"Checker"}
        delete_modal_visible={checker_delete_modal_visible}
        set_delete_modal_visible={set_checker_delete_modal_visible}
        record={record}
        set_record={set_record}
        selector_name={"delete_checker"}
        delete_query={mutation_delete_checker}
        get_all={get_checker_list}
      />
    </div>
  );
};

export default CheckerList;
