import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import useStyles from "./user-jss";
import { Form, Input } from "antd";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { FormControlLabel, TextField } from "@mui/material";
import { ArrowForward, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  dynamicClear,
  dynamicRequest,
  login,
  useDynamicSelector,
  userLogin,
} from "@services/redux";
import { ROUTES } from "@views/routes/my_routes";
import { storeItem } from "@helpers/storage";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import MuiInput from "@views/components/system/Forms/form_elements/mui_input";
import MuiPassword from "@views/components/system/Forms/form_elements/mui_password";
import SubmitButton from "@views/components/system/Forms/form_elements/submit_button";
import { MethodType } from "@helpers/service_call";
import { MdOutlineRefresh } from "react-icons/md";
import { HiPuzzle } from "react-icons/hi";
import { decrypt_data } from "@helpers/crypto";
import { navigate } from "@helpers/navigator";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

function LoginForm(props) {
  const { classes, cx } = useStyles();
  const { deco, is_india } = props;

  const { loading: captcha_loading, error: captcha_error } =
    useDynamicSelector("getCaptcha");

  const dispatch = useDispatch();
  let captcha = "QWRTSK";

  const [entered_captcha, setCaptcha] = useState("");
  const [has_otp, set_has_otp] = useState(true);
  const canvasRef = useRef();

  const {
    error,
    force_password_change,
    roles,
    home_page,
    token,
    loading: login_loading,
  } = useDynamicSelector("login");

  useEffect(() => {
    if (captcha) {
      createCaptcha();
    }
  }, [captcha]);

  useEffect(() => {
    storeItem("token", token);
    storeItem("role", roles?.[0]);

    if (error) {
      toast.error(error.message);
    }
    if (token) {
      if (force_password_change) {
        navigate(ROUTES.CHANGE_PASSWORD);
      } else {
        navigate(ROUTES.DASHBOARD);
      }
    }
  }, [token, error, home_page]);

  const handle_submit = (values) => {
    let user_login_key = [{ key: "login", loading: true }];
    let user_login_query = login;
    let user_login_variables = {
      username: values?.username,
      password: values?.password,
    };
    dispatch(
      dynamicRequest(
        user_login_key,
        user_login_query,
        user_login_variables,
        MethodType.POST
      )
    );
  };

  const go_to_change_password = () => {
    dispatch(dynamicClear("login"));
    navigate("/change-password");
  };

  const createCaptcha = () => {
    let decryptCaptcha = decrypt_data(captcha);

    if (canvasRef && canvasRef.current) {
      let canvas = canvasRef.current;
      canvas.width = 150;
      canvas.height = 100;
      let ctx = canvas.getContext("2d");
      ctx.font = "bold 20px Arial";
      ctx.fillStyle = "#13304e";
      ctx.fillText(decryptCaptcha, 10, 20);
      ctx.textAlign = "center";
      ctx.alignItems = "center";
      ctx.justifyContent = "center";
    }
  };

  const handle_click = () => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <Paper className={cx(classes.sideWrap, deco && classes.petal)}>
      <div className={classes.topBar}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {"E-PASS"}
          </Typography>
        </div>
      </div>
      <Typography variant="h5">Sign In</Typography>

      <section className={classes.socmedSideLogin}>
        <div className={classes.btnArea}></div>
      </section>
      <section className={classes.pageFormSideWrap}>
        <Form onFinish={handle_submit}>
          {!is_india && (
            <>
              <MuiInput
                field={"email"}
                label={"Email"}
                message={"Email is required"}
                is_required={true}
                class_name={classes.field}
              />

              <div className="captcha-section">
                <div>
                  <div>
                    <div
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#aec4d9",
                        alignItems: "center",
                        width: "140px",
                        height: "40px",
                      }}
                    >
                      <canvas
                        ref={canvasRef}
                        style={{
                          marginLeft: "50px",
                          objectFit: "cover",
                          marginTop: "6px",
                          display: captcha_loading ? "none" : "block",
                        }}
                      />
                      {captcha_loading && (
                        <div class="leap-frog">
                          <div class="leap-frog__dot"></div>
                          <div class="leap-frog__dot"></div>
                          <div class="leap-frog__dot"></div>
                        </div>
                      )}
                    </div>
                    <div>
                      {/* <IoReloadOutline
                        onClick={get_login_captcha}
                        size={20}
                        style={{ cursor: "pointer", color: "#14304e" }}
                      /> */}
                    </div>
                  </div>
                </div>
                <div>
                  <Form.Item
                    className="form-captcha"
                    name={"captcha"}
                    rules={[
                      {
                        required: true,
                        message: "Captcha is required",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: "40px",
                        height: "45px",
                        borderColor: "#14304e",
                      }}
                      className="captcha-input"
                      maxLength={6}
                      placeholder="Captcha"
                      // defaultValue={text}
                      // onPressEnter={handleKeypress}
                      onChange={(txt) => setCaptcha(txt.target.value)}
                      onChangeText={(txt) => setCaptcha(txt)}
                      autoComplete={false}
                      prefix={
                        entered_captcha ? (
                          <HiPuzzle color="#14304e" />
                        ) : (
                          <HiPuzzle color="#b5a4a4" />
                        )
                      }
                    />
                  </Form.Item>
                </div>
              </div>
            </>
          )}
          {is_india && (
            <MuiInput
              field={"mobile_number"}
              label={"Mobile Number"}
              message={"Mobile number is required"}
              is_required={true}
              class_name={classes.field}
            />
          )}
          {is_india && has_otp && (
            <MuiInput
              field={"otp"}
              label={"OTP"}
              message={"OTP number is required"}
              is_required={true}
              class_name={classes.field}
            />
          )}
          {/* <MuiPassword
            field={"password"}
            label={"Password"}
            message={"Password is required"}
            is_required={true}
            class_name={classes.field}
          /> */}

          <SubmitButton
            label={is_india ? "OTP" : "Login"}
            show_icon={true}
            class_name={classes.btnArea}
            icon_class_name={cx(classes.rightIcon, classes.iconSmall)}
            loading={login_loading}
            handle_click={handle_click}
          />
        </Form>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 5,
          }}
        >
          <Button
            size="small"
            // component={LinkBtn}
            onClick={() => {
              go_to_change_password();
            }}
            className={classes.buttonLink}
            style={{
              textDecoration: "underline",
            }}
          >
            <Typography>Change Password</Typography>
          </Button>
        </div> */}
      </section>
    </Paper>
  );
}

export default LoginForm;
