import { Box } from "@mui/material";
import { Card, Pagination, Select, Space, Table } from "antd";
import React, { useEffect, useState } from "react";

const EntryList = () => {
  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth < 768) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  const data = [
    {
      key: "1",
      pass_number: "PN12345",
      applicant: "John Doe",
      vehicle_type: "Car",
      total_passengers: 4,
      destination: "Kodaikkanal",
      valid_from: "07/05/2024",
      valid_till: "08/05/2024",
      contact: "hysrg6@gmail.com",
    },
    {
      key: "2",
      pass_number: "PN67890",
      applicant: "Jane Smith",
      vehicle_type: "SUV",
      total_passengers: 5,
      destination: "Nilgiris",
      valid_from: "07/05/2024",
      valid_till: "08/05/2024",
      contact: "8704565786",
    },
    {
      key: "3",
      pass_number: "PN11223",
      applicant: "Robert Brown",
      vehicle_type: "Van",
      total_passengers: 7,
      destination: "Kodaikkanal",
      valid_from: "07/05/2024",
      valid_till: "08/05/2024",
      contact: "jjjygs@gmail.com",
    },
    {
      key: "4",
      pass_number: "PN11276",
      applicant: "Robert Brown",
      vehicle_type: "Car",
      total_passengers: 3,
      destination: "Nilgiris",
      valid_from: "07/05/2024",
      valid_till: "08/05/2024",
      contact: "9898078788",
    },
  ];

  const columns = [
    {
      title: "Pass Number",
      dataIndex: "pass_number",
      key: "pass_number",
    },
    {
      title: "Applicant",
      dataIndex: "applicant",
      key: "applicant",
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "vehicle_type",
    },
    {
      title: "Valid From",
      dataIndex: "valid_from",
      key: "valid_from",
    },
    {
      title: "Valid Till",
      dataIndex: "valid_till",
      key: "valid_till",
    },
    {
      title: "Travelers Count",
      dataIndex: "total_passengers",
      key: "travelers count",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
  ];

  return (
    <div className="list-wrapper">
      {/* <div className="table-filter" style={{ marginBottom: 2 }}>
        <Space style={{ display: "flex", flexWrap: "wrap" }}>
          {user?.user?.roles?.[0]?.name === "Admin" && (
            <Select
              mode="multiple"
              placeholder="Destination"
              style={{ width: 120 }}
              value={filters.destination_ids}
              onChange={(value) => handle_change("destination_ids", value)}
              allowClear={true}
            >
              {local_storage_master_data?.destinations?.map((destination) => (
                <Option value={destination?.id} key={destination?.id}>
                  {destination?.name}
                </Option>
              ))}
            </Select>
          )}
          <Select
            mode="multiple"
            placeholder="Coming From"
            style={{ width: 120 }}
            value={filters.direction_ids}
            onChange={(value) => handle_change("direction_ids", value)}
            allowClear={true}
          >
            {local_storage_master_data?.directions?.map((direction) => (
              <Option value={direction?.id} key={direction?.id}>
                {direction?.name}
              </Option>
            ))}
          </Select>

          <Box style={{ width: "260px" }}>
            <RangePicker
              placeholder={["Applied From", "Applied Before"]}
              onChange={date_range_picker}
              allowClear={true}
              format="DD/MM/YYYY"
            />
          </Box>
          <Box style={{ width: "260px" }}>
            <RangePicker
              placeholder={["Valid From", "Valid Till"]}
              onChange={date_range_picker_validity}
              allowClear={true}
              format="DD/MM/YYYY"
            />
          </Box>

          <Select
            mode="multiple"
            placeholder="Vehicle Type"
            style={{ width: 120 }}
            value={filters.vehicle_type_ids}
            onChange={(value) => handle_change("vehicle_type_ids", value)}
            allowClear={true}
          >
            {local_storage_master_data?.vehicle_types?.map((vehicle_type) => (
              <Option value={vehicle_type?.id} key={vehicle_type?.id}>
                {vehicle_type?.name}
              </Option>
            ))}
          </Select>

          <Select
            mode="multiple"
            placeholder="Fuel Type"
            style={{ width: 120 }}
            value={filters.fuel_types}
            onChange={(value) => handle_change("fuel_types", value)}
            allowClear={true}
          >
            <Option value={"cng"}>CNG</Option>
            <Option value={"diesel"}>Diesel</Option>
            <Option value={"electric"}>Electric</Option>
            <Option value={"hybrid"}>Hybrid</Option>
            <Option value={"lpg"}>LPG</Option>
            <Option value={"petrol"}>Petrol</Option>
          </Select>

          <Select
            mode="multiple"
            placeholder="Purpose of Visit"
            style={{ width: 142 }}
            value={filters.reason_ids}
            onChange={(value) => handle_change("reason_ids", value)}
            allowClear={true}
          >
            {local_storage_master_data?.reasons?.map((reason) => (
              <Option key={reason?.id} value={reason?.id}>
                {reason?.name}
              </Option>
            ))}
          </Select>
          <Button onClick={handle_clear_filters}>Clear Filters</Button>
          <div>
            <Button
              type="primary"
              onClick={get_pass_list_without_pagination}
              loading={pass_list_without_pagination_loading}
            >
              Download Excel
            </Button>
          </div>
        </Space>
      </div> */}
      <div>
        {deviceType === "mobile" ? (
          <div style={{ marginTop: "10px" }}>
            {pass_list?.map((x) => (
              <Card style={{ marginBottom: "15px" }}>
                <table>
                  <tr>
                    <th>Pass Number</th>
                    <td>{x.pass_number}</td>
                  </tr>
                  <tr>
                    <th>Applicant Name</th>
                    <td>{x.name}</td>
                  </tr>
                  <tr>
                    <th>Valid From</th>
                    <td>{moment(x.valid_from).format("DD/MM/YYYY")}</td>
                  </tr>
                  <tr>
                    <th>Valid Till</th>
                    <td>{moment(x.valid_till).format("DD/MM/YYYY")}</td>
                  </tr>
                  <tr>
                    <th>Destination</th>
                    <td>{x?.destination?.name}</td>
                  </tr>
                  <tr>
                    <th>Traveler Count</th>
                    <td>{x.total_passengers}</td>
                  </tr>
                  <tr>
                    <th>Vehicle Type</th>
                    <td>{x?.vehicle_type?.name}</td>
                  </tr>
                  <tr>
                    <th>Fuel Type</th>
                    <td>{capitalize(x.vehicle_fuel_type)}</td>
                  </tr>
                </table>
              </Card>
            ))}
          </div>
        ) : (
          <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            className="custom-table"
            rowClassName={"custom-row"}
            // loading={pass_list_loading}
          />
        )}
        <div className="table-pagination">
          <Pagination
            // showTotal={(total) => `Total ${total} items`}
            // total={pass_list_pagination?.total_count}
            showSizeChanger={true}
            page_number={3}
            hideOnSinglePage={false}
            // onChange={fetch_data}
            // current={page_number}
          />
        </div>
        {/* <Modal
          title={"Download Your Pass"}
          open={model_open}
          width={800}
          onCancel={() => set_model_open(false)}
          footer={null}
        >
          <Pass data={pass_data} />
        </Modal> */}
      </div>
    </div>
  );
};

export default EntryList;
