import React, { useEffect, useRef } from "react";
import "ol/ol.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Icon, Style, Text, Fill } from "ol/style";

const MapWithMarker = (props) => {
  const { locations } = props;
  const mapRef = useRef(null);

  useEffect(() => {
    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),

        new VectorLayer({
          source: new VectorSource({
            features: locations.map((location) => {
              const iconStyle = new Style({
                image: new Icon({
                  src: require("../../../assets/icons/toll.png"),
                  anchor: [0.5, 1], // Set anchor to bottom center of the icon
                  height: 50,
                  width: 50,
                }),
              });

              const textStyle = new Style({
                text: new Text({
                  text: String(location?.total_scans),
                  offsetY: -45,
                  offsetX: -10,
                  fill: new Fill({ color: "#000" }),
                  backgroundFill: new Fill({
                    color: "rgba(255, 255, 255, 0.8)",
                  }),
                  font: "bold 14px Arial",
                }),
              });

              const combinedStyle = [iconStyle, textStyle];

              const feature = new Feature({
                geometry: new Point(
                  fromLonLat([location.latlong?.[1], location.latlong?.[0]])
                ),
              });

              feature.setStyle(combinedStyle);

              return feature;
            }),
          }),
        }),
      ],

      view: new View({
        center: fromLonLat([76.9558, 11.0168]),
        zoom: 8,
      }),
    });

    return () => map.dispose();
  }, []);

  return (
    <div
      ref={mapRef}
      className="map"
      style={{ width: "100%", height: "600px" }}
    ></div>
  );
};

export default MapWithMarker;
