import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import axios from "axios";

export const validateMobileNumber = (_, value) => {
  if (!value) {
    return Promise.reject("Mobile number is required");
  } else if (!/^[0-9]{10}$/.test(value)) {
    return Promise.reject("Mobile number must be 10 digits");
  }
  return Promise.resolve();
};

export const arrangeXLReportData = (data_items, excel_object) => {
  const rearranged_data = [];
  for (let i = 0; i < data_items?.length; i++) {
    let row_data = {};
    for (const key in excel_object) {
      if (excel_object.hasOwnProperty(key)) {
        row_data[key] = excel_object[key];
      }
    }
    rearranged_data.push(row_data);
  }
  return rearranged_data;
};

export function export_to_excel(data, file_name) {
  if (!data.length > 0) return;

  const style = {
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
  };

  const ws = XLSX.utils.json_to_sheet(data, {
    header: Object.keys(data[0]),
    styles: { header: [style], cell: [style] },
  });

  // Initialize arrays to store the maximum width and height for each column and row
  const column_widths = Array(Object.keys(data[0]).length).fill(0);
  const row_heights = Array(data.length).fill(0);

  // Iterate over each row of data to determine the maximum width and height needed for each column and row
  data.forEach((row, rowIndex) => {
    Object.entries(row).forEach(([key, cell], columnIndex) => {
      const cell_text_width = cell ? cell.toString().length * 1.5 : 0; // Adjust multiplier as needed
      if (cell_text_width > column_widths[columnIndex]) {
        column_widths[columnIndex] = cell_text_width;
      }
      const cell_text_height = cell ? cell.toString().split("\n").length : 1; // Counting lines for multi-line text
      if (cell_text_height > row_heights[rowIndex]) {
        row_heights[rowIndex] = cell_text_height;
      }
    });
  });

  // Set column widths and row heights in the worksheet
  ws["!cols"] = column_widths.map((width) => ({ width }));
  ws["!rows"] = row_heights.map((height) => ({ hpx: height * 15 })); // Assuming each line height is 15 pixels

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const blob = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blob, `${file_name}-${moment().format("DD/MM/YYYY hh:mm a")}.xlsx`);
}

export const get_excel_response = async (values) => {
  try {
    console.log("values", values);
    const file = values?.url?.[0]?.url;
    console.log("file", file);
    if (!file) {
      throw new Error("File URL not found in values");
    }

    let fileContent = await get_data_in_excel(file);
    let pay_officer_items = XLSX.utils.sheet_to_json(
      fileContent.Sheets["Sheet1"],
      {
        raw: true,
        cellText: true,
        cellDates: true,
      }
    );
    console.log("april_breakup", pay_officer_items);
    return pay_officer_items;
    // set_excel_response(pay_officer_items);
    // set_excel_response_modal(true);
  } catch (error) {
    console.error("Error occurred while handling submission:", error);
  }
};

async function get_data_in_excel(url) {
  const response = await axios.get(url, {
    responseType: "arraybuffer",
  });

  const excelData = new Uint8Array(response.data);
  const workbook = XLSX.read(excelData, {
    type: "array",
  });

  return workbook;
}

export const downloadReasonReportExcel = (items, title) => {
  const worksheet = XLSX.utils.json_to_sheet(items);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, title);
  XLSX.writeFile(workbook, `${title}.xlsx`);
};

export const countFormat = (value, { is_bold } = {}) => {
  if (typeof value === "number") return `${value?.toLocaleString("en-IN")}`;
  else return "0";
};
