import React from "react";
import { Box } from "@mui/material";
import { useDynamicSelector } from "@services/redux";
import PageItem from "./page_item";

const PageList = (props) => {
  const { loading: pages_loading, items: pages } = useDynamicSelector("get_system_ui_module_list");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {pages &&
        pages.map((page, page_index) => (
          <PageItem key={page_index} item={page} index={page_index} />
        ))}
    </div>
  );
};

export default PageList;
