import React, { useEffect, useState } from "react";
import {
  dynamicClear,
  dynamicRequest,
  dynamicSet,
  useDynamicSelector,
  //   get_ui_modules_query,
  //   create_ui_module_mutation,
  //   update_ui_module_mutation,
  //   delete_ui_module_mutation,
  //   create_ui_module_component_mutation,
  //   update_ui_module_component_mutation,
  //   delete_ui_module_component_mutation,
  //   link_endpoints_ui_module_component_mutation,
  //   get_roles_query,
} from "@services/redux";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  Box,
  Typography,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Chip,
} from "@mui/material";
import Modal from "@views/components/system/Modal/modal";

import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Form, Input } from "antd";
import {
  create_system_ui_module_component_mutation,
  create_system_ui_module_page,
  get_ui_modules_query,
} from "@services/redux/slices/access_rights/graphql_access_right";

const ManagePage = () => {
  const dispatch = useDispatch();
  const { loading: create_page_loading, status: create_page_status } =
    useDynamicSelector("create_ui_module");
  const { loading: update_page_loading, status: update_page_status } =
    useDynamicSelector("update_page");
  const { loading: delete_page_loading, status: delete_page_status } =
    useDynamicSelector("delete_page");
  const { loading: endpoints_loading, items: endpoints } =
    useDynamicSelector("endpoints");
  const page_action = useDynamicSelector("page_action");
  const page_item = useDynamicSelector("page_item");
  const [open, setOpen] = useState(false);
  const {
    loading: create_page_component_loading,
    status: create_page_component_status,
  } = useDynamicSelector("create_page_component");
  const {
    loading: update_page_component_loading,
    status: update_page_component_status,
  } = useDynamicSelector("update_page_component");
  const {
    loading: link_endpoints_page_component_loading,
    status: link_endpoints_page_component_status,
  } = useDynamicSelector("link_endpoints_page_component");

  const {
    loading: delete_page_component_loading,
    status: delete_page_component_status,
  } = useDynamicSelector("delete_page_component");
  const page_id = useDynamicSelector("page_component_page_id");
  const page_component_action = useDynamicSelector("page_component_action");
  const page_component_item = useDynamicSelector("page_component_item");
  const [page_component_open, setPageComponentOpen] = useState(false);
  const [endpoint_options, setEndpointOptions] = useState([]);
  const [form] = Form.useForm();
  const api_module_id_list = Form.useWatch("api_module_id_list", form);
  useEffect(() => {
    loadPages(true);
  }, []);

  useEffect(() => {
    let _options = [];
    if (endpoints) {
      for (let i = 0; i < endpoints.length; i++) {
        _options.push({
          value: endpoints[i].id,
          label: endpoints[i].name,
        });
      }
      setEndpointOptions(_options);
    }
  }, [endpoints]);

  useEffect(() => {
    if (page_action === "Add") {
      form.resetFields();
      setOpen(true);
    } else if (page_action === "Edit") {
      form.setFieldsValue(page_item);
      setOpen(true);
    } else if (page_action === "Delete") {
      setOpen(true);
    }
  }, [page_action]);

  useEffect(() => {
    if (create_page_status === "success") {
      toast.success("Page added successfully");
      loadPages(false);
      loadRoles(false);
      closeModal();
      dispatch(dynamicClear("create_system_ui_module"));
    } else if (create_page_status === "failure") {
      toast.error("Page cannot be created");

      dispatch(dynamicClear("create_system_ui_module"));
    }
  }, [create_page_status]);

  useEffect(() => {
    if (update_page_status === "success") {
      toast.success("Page updated successfully");

      loadRoles(false);
      loadPages(false);
      closeModal();
      dispatch(dynamicClear("update_page"));
    } else if (update_page_status === "failure") {
      toast.error("Page cannot be updated");

      dispatch(dynamicClear("update_page"));
    }
  }, [update_page_status]);

  useEffect(() => {
    if (delete_page_status === "success") {
      toast.success("Page deleted successfully");

      loadRoles(false);
      loadPages(false);
      closeModal();
      dispatch(dynamicClear("delete_page"));
    } else if (delete_page_status === "failure") {
      toast.error("Page cannot be deleted");

      dispatch(dynamicClear("delete_page"));
    }
  }, [delete_page_status]);

  useEffect(() => {
    if (page_component_action === "Add") {
      form.resetFields();
      setPageComponentOpen(true);
    } else if (page_component_action === "Edit") {
      form.setFieldsValue(page_component_item);
      setPageComponentOpen(true);
    } else if (page_component_action === "Link Endpoint To") {
      let json = {
        id: page_component_item.id,
        api_module_id_list: page_component_item.endpoints.map((x) => x.id),
      };
      form.setFieldsValue(json);
      setPageComponentOpen(true);
    } else if (page_component_action === "Delete") {
      setPageComponentOpen(true);
    }
  }, [page_component_action]);

  useEffect(() => {
    if (create_page_component_status === "success") {
      toast.success("Page Component added successfully");

      loadRoles(false);
      loadPages(false);
      closePageComponentModal();
      dispatch(dynamicClear("create_page_component"));
    } else if (create_page_component_status === "failure") {
      toast.error("Page Component cannot be created");

      dispatch(dynamicClear("create_page_component"));
    }
  }, [create_page_component_status]);

  useEffect(() => {
    if (update_page_component_status === "success") {
      let message = `Page Component updated successfully`;
      if (page_action === "Link Endpoint To") {
        message = `Page Component Endpoints linked successfully`;
      }

      toast.success(message);

      loadRoles(false);
      loadPages(false);
      closePageComponentModal();
      dispatch(dynamicClear("update_page_component"));
    } else if (update_page_component_status === "failure") {
      let message = `Page Component cannot be updated`;
      if (page_action === "Link Endpoint To") {
        message = `Page Component Endpoints cannot be linked.`;
      }

      toast.error(message);

      dispatch(dynamicClear("update_page_component"));
    }
  }, [update_page_component_status]);

  useEffect(() => {
    if (link_endpoints_page_component_status === "success") {
      let message = `Page Component Endpoints linked successfully`;

      toast.success(message);

      loadRoles(false);
      loadPages(false);
      closePageComponentModal();
      dispatch(dynamicClear("link_endpoints_page_component"));
    } else if (update_page_component_status === "failure") {
      let message = `Page Component Endpoints cannot be linked.`;

      toast.error(message);

      dispatch(dynamicClear("link_endpoints_page_component"));
    }
  }, [link_endpoints_page_component_status]);

  useEffect(() => {
    if (delete_page_component_status === "success") {
      toast.success("Page Component deleted successfully");

      loadRoles(false);
      loadPages(false);
      closePageComponentModal();
      dispatch(dynamicClear("delete_page_component"));
    } else if (delete_page_component_status === "failure") {
      toast.error("Page Component cannot be deleted");

      dispatch(dynamicClear("delete_page_component"));
    }
  }, [delete_page_component_status]);

  const loadPages = (should_load) => {
    let keys = [{ key: "get_system_ui_module_list", loading: should_load }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_ui_modules_query, variables));
  };
  const loadRoles = (should_load) => {
    let keys = [{ key: "roles", loading: should_load }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_roles_query, variables));
  };
  const openAddPage = () => {
    dispatch(dynamicSet("page_action", "Add"));
  };

  const closeModal = () => {
    dispatch(dynamicSet("page_action", ""));
    setOpen(false);
  };

  const handleSubmit = (values) => {
    if (page_action === "Add") {
      let keys = [{ key: "create_system_ui_module", loading: true }];
      let variables = {
        data: values,
      };
      dispatch(
        dynamicRequest(keys, create_system_ui_module_page, variables, "M")
      );
    } else if (page_action === "Edit") {
      let keys = [{ key: "update_page", loading: true }];
      let variables = {
        id: page_item.id,
        data: values,
      };
      dispatch(dynamicRequest(keys, update_ui_module_mutation, variables, "M"));
    }
  };

  const handleDelete = () => {
    let keys = [{ key: "delete_page", loading: true }];
    let variables = {
      id: page_item.id,
    };
    dispatch(dynamicRequest(keys, delete_ui_module_mutation, variables, "M"));
  };

  const closePageComponentModal = () => {
    dispatch(dynamicSet("page_component_page_id", ""));
    dispatch(dynamicSet("page_component_action", ""));
    setPageComponentOpen(false);
  };

  const handlePageComponentSubmit = (values) => {
    if (page_component_action === "Add") {
      values.ui_module_id = page_id;
      let keys = [{ key: "create_page_component", loading: true }];
      let variables = {
        data: values,
      };
      dispatch(
        dynamicRequest(
          keys,
          create_system_ui_module_component_mutation,
          variables,
          "M"
        )
      );
    } else if (page_component_action === "Edit") {
      let keys = [{ key: "update_page_component", loading: true }];
      let variables = {
        id: page_component_item.id,
        data: values,
      };
      dispatch(
        dynamicRequest(
          keys,
          update_ui_module_component_mutation,
          variables,
          "M"
        )
      );
    } else if (page_component_action === "Link Endpoint To") {
      let keys = [{ key: "link_endpoints_page_component", loading: true }];
      let variables = {
        id: page_component_item.id,
        data: values,
      };
      dispatch(
        dynamicRequest(
          keys,
          link_endpoints_ui_module_component_mutation,
          variables,
          "M"
        )
      );
    }
  };

  const handlePageComponentDelete = () => {
    let keys = [{ key: "delete_page_component", loading: true }];
    let variables = {
      id: page_component_item.id,
    };
    dispatch(
      dynamicRequest(keys, delete_ui_module_component_mutation, variables, "M")
    );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Button variant="contained" color="primary" onClick={openAddPage}>
          Add Page
        </Button>
      </Box>

      <Modal
        is_open={open}
        handleClose={closeModal}
        scroll={"body"}
        width={400}
        content={
          <>
            {page_action !== "Delete" && (
              <Form
                form={form}
                name={"manage_page"}
                className="access_rights_form"
                layout={"horizontal"}
                onFinish={handleSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label="Name"
                  name="name"
                  style={{ color: "black" }}
                  rules={[{ required: true, message: "Name is mandatory" }]}
                >
                  <Input />
                </Form.Item>
              </Form>
            )}
            {page_action === "Delete" && (
              <Typography
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                Do you want to delete the page "
                <Typography fontWeight="bold">{page_item.name}</Typography>"?
              </Typography>
            )}
          </>
        }
        title={`${page_action} Page`}
        footer={true}
        footer_content={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              margin: 10,
            }}
          >
            <Button
              type="reset"
              variant="outlined"
              key="cancel"
              color="warning"
              onClick={closeModal}
              size="medium"
            >
              Cancel
            </Button>
            {page_action === "Delete" && (
              <Button
                key="delete"
                variant="contained"
                type="submit"
                onClick={handleDelete}
                loading={delete_page_loading}
                sx={{
                  marginLeft: 1,
                }}
              >
                Delete
              </Button>
            )}
            {page_action !== "Delete" && (
              <Button
                key="submit"
                variant="contained"
                type="submit"
                form="manage_page"
                htmlType="submit"
                loading={
                  page_action === "Add"
                    ? create_page_loading
                    : update_page_loading
                }
                sx={{
                  marginLeft: 1,
                }}
              >
                {page_action === "Add" && "Add"}
                {page_action === "Edit" && "Update"}
              </Button>
            )}
          </div>
        }
      />

      <Modal
        is_open={page_component_open}
        handleClose={closePageComponentModal}
        scroll={"body"}
        width={"auto"}
        content={
          <>
            {(page_component_action === "Add" ||
              page_component_action === "Edit") && (
              <Form
                form={form}
                name={"manage_page_component"}
                className="access_rights_form"
                layout={"horizontal"}
                onFinish={handlePageComponentSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label="Name"
                  name="name"
                  style={{ color: "black" }}
                  rules={[{ required: true, message: "Name is mandatory" }]}
                >
                  <Input />
                </Form.Item>
              </Form>
            )}

            {page_component_action === "Link Endpoint To" && (
              <Form
                form={form}
                name={"manage_page_component"}
                className="access_rights_form"
                layout={"horizontal"}
                onFinish={handlePageComponentSubmit}
                autoComplete="off"
              >
                <Form.Item
                  label="Endpoints"
                  name="api_module_id_list"
                  style={{ color: "black" }}
                >
                  <Select
                    multiple={true}
                    variant="standard"
                    sx={{ width: "100%" }}
                    MenuProps={{
                      sx: {
                        "& .Mui-selected": {
                          backgroundColor: "turquoise",
                          color: "white",
                        },
                        "& .Mui-selected:hover": {
                          backgroundColor: "lightblue",
                          color: "white",
                        },
                        "& .MuiListItem-button:hover": {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      },
                    }}
                  >
                    {endpoint_options?.map((x, i) => {
                      return (
                        <MenuItem key={i} value={x.value}>
                          {x.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Form>
            )}
            {page_component_action === "Delete" && (
              <Typography
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                Do you want to delete the page component "
                <Typography fontWeight="bold">
                  {page_component_item.name}
                </Typography>
                "?
              </Typography>
            )}
          </>
        }
        title={`${page_component_action} Page Component`}
        footer={true}
        footer_content={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              margin: 10,
            }}
          >
            <Button
              key="cancel"
              type="reset"
              variant="outlined"
              color="warning"
              onClick={closePageComponentModal}
            >
              Cancel
            </Button>
            {page_component_action === "Delete" && (
              <Button
                key="delete"
                variant="contained"
                type="submit"
                onClick={handlePageComponentDelete}
                loading={delete_page_component_loading}
                sx={{
                  marginLeft: 1,
                }}
              >
                Delete
              </Button>
            )}
            {page_component_action === "Link Endpoint To" && (
              <Button
                key="submit"
                variant="contained"
                type="submit"
                form="manage_page_component"
                htmlType="submit"
                loading={link_endpoints_page_component_loading}
                sx={{
                  marginLeft: 1,
                }}
              >
                Link
              </Button>
            )}
            {(page_component_action === "Add" ||
              page_component_action === "Edit") && (
              <Button
                key="submit"
                variant="contained"
                type="submit"
                form="manage_page_component"
                htmlType="submit"
                loading={
                  page_component_action === "Add"
                    ? create_page_component_loading
                    : update_page_component_loading
                }
                sx={{
                  marginLeft: 1,
                }}
              >
                {page_component_action === "Add" && "Add"}
                {page_component_action === "Edit" && "Update"}
              </Button>
            )}
          </div>
        }
      />
    </>
  );
};

export default ManagePage;
