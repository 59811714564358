import React, { useEffect, useState } from "react";
import SingleCard from "./single_card";
import { useDynamicSelector } from "@services/redux";
import DashboardFilter from "./dashboard_filter";

const AdminDashboard = (props) => {
  const { items: dashboard_count } = useDynamicSelector("get_dashboard_count");

  return (
    <div>
      <DashboardFilter
        dashboard_type={props?.dashboard_type}
        tab_change={props?.tab_change}
      />
      <div>
        <div className="application-wrapper"></div>
        <h5 className="card-main-title first-title">
          <b>Applied</b>
        </h5>
        <div
          className={`${
            props?.show_today_tomorrow
              ? "application-wrapper"
              : "application-wrapper1"
          }`}
        >
          <SingleCard
            show={true}
            bg_color={"rgb(14, 137, 6)"}
            title={"Total"}
            vehicles_count={289}
            passengers_count={400}
            items={dashboard_count}
            dash_board_type={"AppliedTotal"}
          />
          {props?.show_today_tomorrow && (
            <>
              <SingleCard
                show={true}
                bg_color={"rgb(18, 122, 191)"}
                title={"Today"}
                vehicles_count={289}
                passengers_count={400}
                items={dashboard_count}
                dash_board_type={"AppliedToday"}
                show_yet_to_enter={true}
              />
              <SingleCard
                show={true}
                bg_color={"rgb(144, 80, 170)"}
                title={"Tomorrow"}
                vehicles_count={289}
                passengers_count={400}
                items={dashboard_count}
                dash_board_type={"AppliedTomorrow"}
              />
            </>
          )}
        </div>

        <h5 className="card-main-title">
          <b>Entry</b>
        </h5>
        <div className="application-wrapper">
          <SingleCard
            show={true}
            bg_color={"rgb(14, 137, 6)"}
            title={"Total"}
            vehicles_count={289}
            passengers_count={400}
            items={dashboard_count}
            dash_board_type={"EntryTotal"}
          />
          <SingleCard
            show={true}
            bg_color={"rgb(18, 122, 191)"}
            badge_bg_color={"rgb(18, 122, 191)"}
            title={"Today"}
            vehicles_count={289}
            passengers_count={400}
            items={dashboard_count}
            dash_board_type={"EntryToday"}
          />
          <SingleCard
            show={true}
            bg_color={"rgb(144, 80, 170)"}
            title={"Yesterday"}
            vehicles_count={289}
            passengers_count={400}
            items={dashboard_count}
            dash_board_type={"EntryYesterday"}
          />
        </div>

        {props?.show_inside && (
          <>
            <h5 className="card-main-title">
              <b>Inside Now</b>
            </h5>
            <div className="application-wrapper1">
              <SingleCard
                show={true}
                bg_color={"#0e8906"}
                title={"Total"}
                vehicles_count={289}
                passengers_count={400}
                items={dashboard_count}
                dash_board_type={"InsideNow"}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
