import React from "react";
import { Box } from "@mui/material";
import PageComponentItem from "./page_component_item";

const PageComponentList = (props) => {
  const { page_components } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {page_components &&
        page_components.map((page_component, page_component_index) => (
          <PageComponentItem
            key={page_component_index}
            item={page_component}
            index={page_component_index}
          />
        ))}
    </div>
  );
};

export default PageComponentList;
