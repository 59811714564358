import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch } from "react-redux";
import { dynamicSet, useDynamicSelector } from "@services/redux";
import ManagePageComponent from "../page_component/manage_page_component";
import PageComponentList from "../page_component/page_component_list";
import PageRoleCheckBox from "./page_role_check_box";

const PageItem = (props) => {
  const { item, index } = props;
  const dispatch = useDispatch();

  const { items: roles } = useDynamicSelector("roles");
  const [on_hover, setOnHover] = useState(false);

  const openEdit = () => {
    dispatch(dynamicSet("page_item", item));
    dispatch(dynamicSet("page_action", "Edit"));
  };

  const openDelete = () => {
    dispatch(dynamicSet("page_item", item));
    dispatch(dynamicSet("page_action", "Delete"));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            key={index}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "200px",
              height: "40px",
              bgcolor: "#84d1a0",
              position: "sticky",
              left: "0px",
              zIndex: "3",
              borderBottom: "1px solid black",
              borderLeft: "1px solid black",
              borderRight: "1px solid black",
              borderBottomColor: "black",
              borderLeftColor: "black",
              borderRightColor: "black",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            <Typography
              fontSize={14}
              variant="body1"
              width="180px"
              color="textPrimary"
            >
              {item.name}
            </Typography>
            {on_hover && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "absolute",
                  top: "3px",
                  right: "3px",
                  backgroundColor: "#84d1a0",
                }}
              >
                <ManagePageComponent page_id={item.id} />
                {/* <Tooltip title="Edit">
                  <IconButton
                    style={{ color: "blue" }}
                    onClick={openEdit}
                    aria-label="edit"
                  >
                    <EditIcon
                      sx={{
                        fontSize: 14,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    style={{ color: "red" }}
                    onClick={openDelete}
                    aria-label="delete"
                  >
                    <DeleteOutlineIcon
                      sx={{
                        fontSize: 14,
                      }}
                    />
                  </IconButton>
                </Tooltip> */}
              </div>
            )}
          </Box>
          {roles &&
            roles.map((role, role_index) => (
              <Box
                key={role_index}
                sx={{
                  width: "150px",
                  height: "40px",
                  bgcolor: "#fff3d4",
                  borderTop: "0px",
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  borderTopColor: "black",
                  borderBottomColor: "black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PageRoleCheckBox page={item} role={role} />
              </Box>
            ))}
        </div>
        <PageComponentList page_components={item.page_components} />
      </div>
    </div>
  );
};
export default PageItem;
