import React, { useEffect } from "react";
import { Button } from "antd";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import {
  dynamicClear,
  dynamicRequest,
  query_get_reason_report,
  query_get_reason_report_on_entry_date_basis,
  query_get_vehicle_report,
  useDynamicSelector,
} from "@services/redux";
import { get_vehicle_entry_list_query } from "@services/redux/slices/reports/graphql_vehicle_entry_report";
import {
  query_get_district_on_date_basis_report,
  query_get_district_on_entry_basis_report,
} from "@services/redux/slices/reports/graphql_get_district_on_date_basis_report";
import { query_state_on_entry_date_basis_report } from "@services/redux/slices/reports/graphql_state_on_entry_date_basis_report";
import { query_state_on_date_basis_report } from "@services/redux/slices/reports/state_on_date_basis_report";
import {
  query_country_on_date_basis_report,
  query_country_on_entry_date_basis_report,
} from "@services/redux/slices/reports/graphql_country_on_entry_date_basis_report";
import { query_get_fuel_type_on_entry_date_basis_report } from "@services/redux/slices/reports/fuel_type_on_entry_date_basis_report";
import { useDispatch } from "react-redux";
import moment from "moment";
import { export_to_excel } from "@helpers/functions";
import { query_fuel_type } from "@services/redux/slices/reports/graphql_fuel_type_report";
import { startCase } from "lodash";

const AdminReports = () => {
  const dispatch = useDispatch();

  // const { items: feedback_report_items, loading: feedback_items_loading } =
  //   useDynamicSelector("get_vehicle_type_on_date_basis_report");

  const { items: vehicle_date_items, loading: vehicle_date_items_loading } =
    useDynamicSelector("get_vehicle_type_on_date_basis_report");
  const { items: vehicle_entry_items, loading: vehicle_entry_items_loading } =
    useDynamicSelector("get_vehicle_type_on_entry_date_basis_report");

  const { items: fuel_date_items, loading: fuel_date_items_loading } =
    useDynamicSelector("get_fuel_type_on_date_basis_report");
  const { items: fuel_entry_items, loading: fuel_entry_items_loading } =
    useDynamicSelector("get_fuel_type_on_entry_date_basis_report");

  const { items: reason_date_items, loading: reason_date_items_loading } =
    useDynamicSelector("get_reason_on_date_basis_report");
  const { items: reason_entry_items, loading: reason_entry_items_loading } =
    useDynamicSelector("get_reason_on_entry_date_basis_report");

  const { items: district_date_items, loading: district_date_items_loading } =
    useDynamicSelector("get_district_on_date_basis_report");
  const { items: district_entry_items, loading: district_entry_items_loading } =
    useDynamicSelector("get_district_on_entry_date_basis_report");

  const { items: state_date_items, loading: state_date_items_loading } =
    useDynamicSelector("get_state_on_date_basis_report");
  const { items: state_entry_items, loading: state_entry_items_loading } =
    useDynamicSelector("get_state_on_entry_date_basis_report");

  const { items: country_date_items, loading: country_date_items_loading } =
    useDynamicSelector("get_country_on_date_basis_report");
  const { items: country_entry_items, loading: country_entry_items_loading } =
    useDynamicSelector("get_country_on_entry_date_basis_report");

  const vehicle_entry_report = () => {
    let key = [
      { key: "get_vehicle_type_on_entry_date_basis_report", loading: true },
    ];
    let query = get_vehicle_entry_list_query;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_vehicle_date_report = () => {
    let key = [{ key: "get_vehicle_type_on_date_basis_report", loading: true }];
    let query = query_get_vehicle_report;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const fuel_on_entry_date_basis_report = () => {
    let key = [
      { key: "get_fuel_type_on_entry_date_basis_report", loading: true },
    ];
    let query = query_get_fuel_type_on_entry_date_basis_report;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const fuel_on_date_basis_report = () => {
    let key = [{ key: "get_fuel_type_on_date_basis_report", loading: true }];
    let query = query_fuel_type;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_reason_report_date = () => {
    let key = [{ key: "get_reason_on_date_basis_report", loading: true }];
    let query = query_get_reason_report;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_reason_entry_date_based_report = () => {
    let key = [{ key: "get_reason_on_entry_date_basis_report", loading: true }];
    let query = query_get_reason_report_on_entry_date_basis;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const district_on_date_basic_report = () => {
    let key = [{ key: "get_district_on_date_basis_report", loading: true }];
    let query = query_get_district_on_date_basis_report;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const district_on_entry_basis_report = () => {
    let key = [
      { key: "get_district_on_entry_date_basis_report", loading: true },
    ];
    let query = query_get_district_on_entry_basis_report;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const state_on_entry_date_basic_report = () => {
    let key = [{ key: "get_state_on_entry_date_basis_report", loading: true }];
    let query = query_state_on_entry_date_basis_report;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const state_on_date_basic_report = () => {
    let key = [{ key: "get_state_on_date_basis_report", loading: true }];
    let query = query_state_on_date_basis_report;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const country_on_date_basic_report = () => {
    let key = [{ key: "get_country_on_date_basis_report", loading: true }];
    let query = query_country_on_date_basis_report;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const country_on_entry_date_basis_report = () => {
    let key = [
      { key: "get_country_on_entry_date_basis_report", loading: true },
    ];
    let query = query_country_on_entry_date_basis_report;
    let variables = {};
    dispatch(dynamicRequest(key, query, variables));
  };

  const arrange_xl_report_data = (report_items, data_items) => {
    let totalCounts = {
      ["S.No"]: "Total",
      ["Date"]: "",
      ["Total Count"]: "",
    };

    report_items[0].counts.forEach((item) => {
      totalCounts[startCase(item.field_name)] = 0;
    });

    const rearranged_data = [];
    let grandTotal = 0;
    for (let i = 0; i < report_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
      };
      row_data["Date"] = moment(report_items?.[i]?.date).format("DD/MM/YYYY");

      report_items[0].counts.forEach((item) => {
        const itemData = report_items?.[i]?.counts?.find(
          (dataItem) => dataItem?.field_name === item?.field_name
        );
        totalCounts[startCase(item.field_name)] += itemData?.count || 0;
        row_data[startCase(item.field_name)] = itemData?.count || 0;
      });
      grandTotal += report_items?.[i]?.total_count;
      row_data["Total Count"] = report_items?.[i]?.total_count;
      totalCounts["Total Count"] = grandTotal;
      rearranged_data.push(row_data);
    }

    rearranged_data.push(totalCounts);

    return rearranged_data;
  };

  const list_download = (items, query_name, filename) => {
    let arranged_data = arrange_xl_report_data(items);
    let title = "234567890";

    export_to_excel(arranged_data ?? [], filename, title);
    dispatch(dynamicClear(query_name));
  };

  useEffect(() => {
    if (vehicle_date_items) {
      list_download(
        vehicle_date_items,
        "get_vehicle_type_on_date_basis_report",
        "Application-Vehicle Type Wise"
      );
    } else if (vehicle_entry_items) {
      list_download(
        vehicle_entry_items,
        "get_vehicle_type_on_entry_date_basis_report",
        "Entry-Vehicle Type Wise"
      );
    } else if (fuel_date_items) {
      list_download(
        fuel_date_items,
        "get_fuel_type_on_date_basis_report",
        "Application-Fuel Type Wise"
      );
    } else if (fuel_entry_items) {
      list_download(
        fuel_entry_items,
        "get_fuel_type_on_entry_date_basis_report",
        "Entry-Fuel Type Wise"
      );
    } else if (state_date_items) {
      list_download(
        state_date_items,
        "get_state_on_date_basis_report",
        "Application-State Wise"
      );
    } else if (state_entry_items) {
      list_download(
        state_entry_items,
        "get_state_on_entry_date_basis_report",
        "Entry-State Wise"
      );
    } else if (district_date_items) {
      list_download(
        district_date_items,
        "get_district_on_date_basis_report",
        "Application-District Wise"
      );
    } else if (district_entry_items) {
      list_download(
        district_entry_items,
        "get_district_on_entry_date_basis_report",
        "Entry-District Wise"
      );
    } else if (country_date_items) {
      list_download(
        country_date_items,
        "get_country_on_date_basis_report",
        "Application-Country Wise"
      );
    } else if (country_entry_items) {
      list_download(
        country_entry_items,
        "get_country_on_entry_date_basis_report",
        "Entry-Country Wise"
      );
    } else if (reason_date_items) {
      list_download(
        reason_date_items,
        "get_reason_on_date_basis_report",
        "Application-Reason Wise"
      );
    } else if (reason_entry_items) {
      list_download(
        reason_entry_items,
        "get_reason_on_entry_date_basis_report",
        "Entry-Reason Wise"
      );
    }
  }, [
    vehicle_date_items,
    vehicle_entry_items,
    fuel_date_items,
    fuel_entry_items,
    state_date_items,
    state_entry_items,
    district_date_items,
    district_entry_items,
    country_date_items,
    country_entry_items,
    reason_date_items,
    reason_entry_items,
  ]);
  console.log("district_entry_items_loading", district_entry_items_loading);
  return (
    <div className="list-wrapper admin-reports">
      <div>
        <h4>Application Based Reports</h4>
        <Button
          loading={vehicle_date_items_loading}
          type="primary"
          className="report-button"
          onClick={handle_vehicle_date_report}
        >
          Vehicle Report
        </Button>
        <Button
          loading={fuel_date_items_loading}
          type="primary"
          className="report-button"
          onClick={fuel_on_date_basis_report}
        >
          Fuel Report
        </Button>
        <Button
          loading={reason_date_items_loading}
          type="primary"
          className="report-button"
          onClick={handle_reason_report_date}
        >
          Reason Report
        </Button>
        <Button
          loading={district_date_items_loading}
          type="primary"
          className="report-button"
          onClick={district_on_date_basic_report}
        >
          District Report
        </Button>

        <Button
          loading={state_date_items_loading}
          type="primary"
          className="report-button"
          onClick={state_on_date_basic_report}
        >
          State Report
        </Button>
        <Button
          loading={country_date_items_loading}
          type="primary"
          className="report-button"
          onClick={country_on_date_basic_report}
        >
          Country Report
        </Button>
      </div>

      <div>
        <h4 className="entry-based-report">Entry Based Reports</h4>
        <Button
          loading={vehicle_entry_items_loading}
          type="primary"
          className="report-button"
          onClick={vehicle_entry_report}
        >
          Vehicle Report
        </Button>
        <Button
          loading={fuel_entry_items_loading}
          type="primary"
          className="report-button"
          onClick={fuel_on_entry_date_basis_report}
        >
          Fuel Report
        </Button>
        <Button
          loading={reason_entry_items_loading}
          type="primary"
          className="report-button"
          onClick={handle_reason_entry_date_based_report}
        >
          Reason Report
        </Button>

        <Button
          loading={district_entry_items_loading}
          type="primary"
          className="report-button"
          onClick={district_on_entry_basis_report}
        >
          District Report
        </Button>

        <Button
          loading={state_entry_items_loading}
          type="primary"
          className="report-button"
          onClick={state_on_entry_date_basic_report}
        >
          State Report
        </Button>
        <Button
          loading={country_entry_items_loading}
          type="primary"
          className="report-button"
          onClick={country_on_entry_date_basis_report}
        >
          Country Report
        </Button>
      </div>

      {/* <div>
        <h4 className="entry-based-report">Feedback Report</h4>
        <Button
          loading={feedback_items_loading}
          type="primary"
          className="report-button"
          onClick={feedback_report_items}
        >
          Feedback Report
        </Button>
      </div> */}
    </div>
  );
};

export default AdminReports;
