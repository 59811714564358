import React, { useState, useEffect } from "react";
import "./login.css";
import logo from "@assets/images/tn_logo.png";
// import LoginForm from "@views/components/auth/login_form";
import { useParams } from "react-router-dom";
import LoginFormAdmin from "../auth/login_form_admin";

export default function AdminLogin() {
  const { type } = useParams();
  return (
    <div className="login-container" style={{ marginTop: "70px" }}>
      <div className="contact-box">
        <div className="left">
          <div className="login-card transparent">
            {/* <img className="login-card-img" src={home} alt="Card Image" /> */}
            <div className="login-overlay">
              <div className="row">
                <div className="icon">
                  <img src={logo} alt="icon" height={80} />
                </div>
                <div className="text-center ">
                  <h4 className="epass-text">Tamil Nadu ePass</h4>
                </div>
                {}
                <div className="text-center"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="text-center"></div>
          <h2 style={{ marginTop: "5px" }}>உள்நுழைய / Login</h2>
          <div className="field">
            <LoginFormAdmin />
          </div>
        </div>
      </div>
    </div>
  );
}
