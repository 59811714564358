import { gql } from "@apollo/client";

export const query_get_reason_report = gql`
  query get_reason_on_date_basis_report($filter: report_filter_input) {
    get_reason_on_date_basis_report(filter: $filter) {
      items {
        date
        counts {
          count
          field_name
        }
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
export const query_get_reason_report_on_entry_date_basis = gql`
  query get_reason_on_entry_date_basis_report($filter: report_filter_input) {
    get_reason_on_entry_date_basis_report(filter: $filter) {
      items {
        date
        counts {
          count
          field_name: reason
        }
        total_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
