import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { EditOutlined, DeleteOutline } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { dynamicSet } from "@services/redux";

const RoleItem = (props) => {
  const dispatch = useDispatch();
  const [on_hover, setOnHover] = useState(false);
  const { item, index } = props;

  const openEdit = () => {
    dispatch(dynamicSet("role_item", item));
    dispatch(dynamicSet("role_action", "Edit"));
  };

  const openDelete = () => {
    dispatch(dynamicSet("role_item", item));
    dispatch(dynamicSet("role_action", "Delete"));
  };

  return (
    <Box
      key={index}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      sx={{
        width: "150px",
        height: "75px",
        bgcolor: "#84d1a0",
        position: "relative",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
        borderRight: "1px solid black",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: 1,
        paddingRight: 1,
      }}
    >
      <Typography color="black" fontSize={14}>
        {item.name}
      </Typography>
      {on_hover && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "0px",
            right: "3px",
            backgroundColor: "#84d1a0",
          }}
        >
          <IconButton onClick={openEdit}>
            <EditOutlined sx={{ color: "blue", fontSize: 14 }} />
          </IconButton>
          <IconButton onClick={openDelete}>
            <DeleteOutline sx={{ color: "red", fontSize: 14 }} />
          </IconButton>
        </div>
      )}
    </Box>
  );
};

export default RoleItem;
