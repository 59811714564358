import { countFormat } from "@helpers/functions";
import { Skeleton, Space } from "antd";
import React from "react";

const SkeletonComponentSingleCard = (props) => {
  return (
    <div
      className={`single-card-wrapper text-white`}
      style={{
        backgroundColor: props?.bg_color,
      }}
    >
      <div className="card-title">
        <h4>
          <b className="title">{props.title}</b>
        </h4>
      </div>

      <div className="count-group">
        <div className="text-center">
          <h4 className="card-inside-text">Passengers</h4>
          <h1 className="count">
            <Skeleton.Button
              active={true}
              size={"default"}
              shape={"default"}
              block={true}
              className="single-card-skeleton-button"
            />
          </h1>
        </div>
        <div className="text-center">
          <h4 className="card-inside-text">Vehicles</h4>
          <h2 className="count">
            <Skeleton.Button
              active={true}
              size={"default"}
              shape={"default"}
              block={true}
              className="single-card-skeleton-button"
            />
          </h2>
        </div>
      </div>

      <div className="vehicle-list">
        <Space className="vehicle-count-wrapper">
          {Array.from({ length: 7 }).map((_, index) => (
            <div key={index} className="single-vehicle-count-skeleton">
              <Skeleton.Avatar
                active={true}
                size={"small"}
                shape={"circle"}
                className="skeleton-avatar"
              />
              <Skeleton.Input
                active={true}
                size={"small"}
                className="skeleton-input"
              />
            </div>
          ))}
        </Space>
      </div>
    </div>
  );
};

export default SkeletonComponentSingleCard;
