import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import ReasonReportListOnEntryDate from "@views/components/admin_reports/reason_report_on_entry_date";

const ReasonReportOnEntryDate = (props) => {
  return (
    <Box flex={1}>
      <ReasonReportListOnEntryDate />
    </Box>
  );
};
export default ReasonReportOnEntryDate;
