import React from "react";
import { Box } from "@mui/material";
import { useDynamicSelector } from "@services/redux";
import RoleItem from "./role_item";

const RoleList = () => {
  const { loading: roles_loading, items: roles } = useDynamicSelector("get_system_role_list");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {roles &&
        roles.map((role, role_index) => (
          <RoleItem key={role_index} item={role} index={role_index} />
        ))}
    </div>
  );
};

export default RoleList;
