import { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Table,
  Space,
  DatePicker,
  Select,
  Pagination,
  Modal,
  Spin,
} from "antd";
import {
  query_get_application_list,
  query_get_application_list_without_pagination,
} from "@services/redux/slices/pass_list/graphql_pass_list";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Box } from "@mui/material";
import { retrieveItem } from "@helpers/storage";
import { capitalize, filter } from "lodash";
import Pass from "./pass";
import { DOWNLOAD } from "@helpers/image_constants";
import { Card } from "@mui/material";
import { export_to_excel } from "@helpers/functions";
import { to_disabled_range } from "@helpers/constants";
const { Search } = Input;

const { RangePicker } = DatePicker;

const PassList = () => {
  const dispatch = useDispatch();
  const local_storage_master_data = retrieveItem("get_master_data");
  const user = retrieveItem("user");
  const search_ref = useRef();
  const {
    items: pass_list,
    loading: pass_list_loading,
    pagination: pass_list_pagination,
  } = useDynamicSelector("get_application_list");

  const {
    items: pass_list_without_pagination_items,
    loading: pass_list_without_pagination_loading,
  } = useDynamicSelector("pass_list_without_pagination");

  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [details_open, set_details_open] = useState(false);
  const [pass_values, set_pass_values] = useState();
  const [model_open, set_model_open] = useState(false);
  const [pass_data, set_pass_data] = useState("");
  const [coming_from, set_coming_from] = useState("");
  const [search, set_search] = useState(null);

  const [filters, set_filters] = useState({
    applied_from_date: null,
    applied_to_date: null,
    valid_from_date: null,
    valid_to_date: null,
    entry_from_date: null,
    entry_to_date: null,
    vehicle_type_ids: null,
    destination_ids: null,
    direction_ids: null,
    reason_ids: null,
    fuel_types: null,
    search_string: search,
  });

  useEffect(() => {
    if (filters?.direction_ids) {
      local_storage_master_data?.directions?.filter((data) => {
        if (data?.id === filters?.direction_ids) {
          set_coming_from(data?.name);
        }
      });
    } else {
      set_coming_from(null);
    }
  }, [filters?.direction_ids]);

  const [deviceType, setDeviceType] = useState("desktop");

  useEffect(() => {
    pass_list_without_pagination_download();
    dispatch(dynamicClear("pass_list_without_pagination"));
  }, [pass_list_without_pagination_items]);

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth < 768) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  useEffect(() => {
    if ((page_number, page_limit, filters)) {
      get_pass_list();
    }
  }, [page_number, page_limit, filters]);

  const handle_change = (key, value) => {
    set_filters({ ...filters, [key]: value });
  };

  const get_pass_list = () => {
    let key = [{ key: "get_application_list", loading: true }];
    let query = query_get_application_list;
    let variables = {
      pagination: {
        page_number,
        page_limit,
      },
      filters,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const get_pass_list_without_pagination = () => {
    let key = [{ key: "pass_list_without_pagination", loading: true }];
    let query = query_get_application_list_without_pagination;
    let variables = {
      pagination: {
        page_number: 1,
        page_limit: pass_list_pagination?.total_count,
      },
      filters,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const handle_clear_filters = () => {
    set_filters({
      applied_from_date: null,
      applied_to_date: null,
      valid_from_date: null,
      valid_to_date: null,
      entry_from_date: null,
      entry_to_date: null,
      vehicle_type_ids: null,
      destination_ids: null,
      direction_ids: null,
      fuel_types: null,
      reason_ids: null,
      fuel_types: null,
      search_string: "",
    });
  };
  const open_details_mobile = (data) => {
    set_model_open(true);
    set_pass_data(data);
  };

  const open_details_card = (data) => {
    const open_details = () => {
      set_model_open(true);
      set_pass_data(data);
    };
    return (
      <div className="table-applicant-wrapper">
        <p>{data?.name}</p>
        <p className="pass-link" onClick={open_details}>
          {data?.pass_number}
        </p>
      </div>
    );
  };

  const columns = [
    {
      title: "Applicant",
      dataIndex: "",
      key: "",
      render: (data) => {
        return open_details_card(data);
      },
    },
    {
      title: "Validity",
      dataIndex: "",
      key: "fromDate",
      render: (data) => (
        <>
          <p>
            {moment(data?.valid_from).format("DD/MM/YYYY") ===
            moment(data?.valid_till).format("DD/MM/YYYY")
              ? moment(data?.valid_from).format("DD/MM/YYYY")
              : moment(data?.valid_from).format("DD/MM/YYYY")}{" "}
            -
            <br /> {moment(data?.valid_till).format("DD/MM/YYYY")}
          </p>
        </>
      ),
    },
    {
      title: "Applied On",
      dataIndex: "applied_on",
      key: "applied_on",
      render: (data) => {
        return moment(data).format("DD/MM/YYYY");
      },
    },
    {
      title: "Travelers Count",
      dataIndex: "total_passengers",
      key: "travelers count",
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "vehicleType",
      render: (data) => {
        return data?.name;
      },
    },
    {
      title: "Fuel Type",
      dataIndex: "vehicle_fuel_type",
      key: "fuelType",
      render: (data) => {
        return capitalize(data);
      },
    },
    {
      title: "Coming From",
      dataIndex: "",
      key: "fuelType",
      render: (data) => {
        return data?.from_district
          ? data?.from_district?.name
          : data?.from_state
          ? data?.from_state?.name
          : data?.from_country?.name;
      },
    },
  ];

  if (user?.user?.roles?.[0]?.name === "Admin") {
    columns.push({
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
      render: (data) => {
        return data?.name;
      },
    });
  }

  const fetch_data = (page_number, page_limit) => {
    set_page_limit(page_limit);
    set_page_number(page_number);
  };

  const pass_list_without_pagination_download = () => {
    let arranged_data = arrange_xl_report_data(
      pass_list_without_pagination_items,
      filters
    );
    export_to_excel(arranged_data ?? [], "Pass List");
  };

  const arrange_xl_report_data = (data_items, filters) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Pass Number"]: data_items[i]?.pass_number,
        ["Applicant Name"]: data_items[i]?.name,
        ["Vehicle Number"]: data_items[i]?.vehicle_number,
        ["Vehicle Manufactured Year"]: data_items[i]?.vehicle_manufactured_year,
        ["Applied Date"]: moment(data_items[i]?.applied_on).format(
          "DD/MM/YYYY hh:mm a"
        ),
        ["Valid From"]: moment(data_items[i]?.valid_from).format("DD/MM/YYYY"),
        ["Valid Till"]: moment(data_items[i]?.valid_till).format("DD/MM/YYYY"),
        ["Destination"]: data_items[i]?.destination?.name,
        ["Travelers Count"]: data_items[i]?.travelers_count,
        ["Vehicle Type"]: data_items[i]?.vehicle_type?.name,
        ["Fuel Type"]: capitalize(data_items[i]?.vehicle_fuel_type),
        ["Coming From"]: data_items[i]?.coming_from,
        ["Mobile"]: data_items[i]?.applicant?.mobile,
        ["Email"]: data_items[i]?.applicant?.email,
        ["PIN Code"]: data_items[i]?.from_pincode,
        ["Reason"]: data_items[i]?.reason?.name,
        ["Address"]: data_items[i]?.from_address_1,
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  const date_range_picker_applied = (dates) => {
    if (dates === null) {
      set_filters({
        ...filters,
        applied_from_date: null,
        applied_to_date: null,
      });
    }

    if (dates) {
      set_filters({
        ...filters,
        applied_from_date: new Date(dates[0]),
        applied_to_date: new Date(dates[1]),
      });
    }
  };

  const date_range_picker_validity = (dates) => {
    if (dates) {
      set_filters({
        ...filters,
        valid_from_date: new Date(dates[0]),
        valid_to_date: new Date(dates[1]),
      });
    }

    if (dates === null) {
      set_filters({
        ...filters,
        valid_from_date: null,
        valid_to_date: null,
      });
    }
  };

  const date_range_picker_entry = (dates) => {
    if (dates) {
      set_filters({
        ...filters,
        entry_from_date: new Date(dates[0]),
        entry_to_date: new Date(dates[1]),
      });
    }

    if (dates === null) {
      set_filters({
        ...filters,
        entry_from_date: null,
        entry_to_date: null,
      });
    }
  };

  return (
    <div className="list-wrapper">
      {/* {deviceType === "mobile" ? (
        ""
      ) : ( */}
      <div className="table-filter" style={{ marginBottom: 2 }}>
        <Space style={{ display: "flex", flexWrap: "wrap" }}>
          {user?.user?.roles?.[0]?.name === "Admin" && (
            <Select
              mode="multiple"
              placeholder="Destination"
              style={{ width: 120 }}
              value={filters.destination_ids}
              onChange={(value) => handle_change("destination_ids", value)}
              allowClear={true}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
            >
              {local_storage_master_data?.destinations?.map((destination) => (
                <Option value={destination?.id} key={destination?.id}>
                  {destination?.name}
                </Option>
              ))}
            </Select>
          )}
          <Select
            // mode="multiple"
            placeholder="Coming From"
            style={{ width: 130 }}
            value={filters.direction_ids}
            onChange={(value) => handle_change("direction_ids", value)}
            allowClear={true}
            showSearch={true}
            filterOption={(inputValue, option) =>
              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          >
            {local_storage_master_data?.directions?.map((direction) => {
              return (
                <Option value={direction?.id} key={direction?.id}>
                  {direction?.name}
                </Option>
              );
            })}
          </Select>

          {coming_from === "Outside TN" && coming_from && (
            <Select
              mode="multiple"
              placeholder="State"
              style={{ width: 120 }}
              // value={filters.direction_ids}
              onChange={(value) => handle_change("from_state_ids", value)}
              allowClear={true}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
            >
              {local_storage_master_data?.countries[0]?.states?.map((state) => {
                if (state?.name !== "Tamil Nadu") {
                  return (
                    <Option value={state?.id} key={state?.id}>
                      {state?.name}
                    </Option>
                  );
                }
              })}
            </Select>
          )}

          {coming_from === "Within TN" && coming_from && (
            <Select
              mode="multiple"
              placeholder="District"
              style={{ width: 120 }}
              // value={filters.direction_ids}
              onChange={(value) => handle_change("from_district_ids", value)}
              allowClear={true}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
            >
              {local_storage_master_data?.countries[0]?.states[0]?.districts?.map(
                (district) => (
                  <Option value={district?.id} key={district?.id}>
                    {district?.name}
                  </Option>
                )
              )}
            </Select>
          )}

          {coming_from === "Foreign" && coming_from && (
            <Select
              mode="multiple"
              placeholder="Countries"
              style={{ width: 120 }}
              // value={filters.direction_ids}
              onChange={(value) => handle_change("from_country_ids", value)}
              allowClear={true}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
            >
              {local_storage_master_data?.countries?.map((country) => {
                if (country?.name !== "India") {
                  return (
                    <Option value={country?.id} key={country?.id}>
                      {country?.name}
                    </Option>
                  );
                }
              })}
            </Select>
          )}

          {/* <Box style={{ width: "110px" }}>
              <DatePicker
                placeholder="From Date"
                value={filters.from_date}
                onChange={(date) => handle_change("from_date", date)}
                allowClear={true}
              />
            </Box>
            <Box style={{ width: "110px" }}>
              <DatePicker
                placeholder="To Date"
                value={filters.to_date}
                onChange={(date) => handle_change("to_date", date)}
                allowClear={true}
              />
            </Box> */}

          <Box style={{ width: "260px" }}>
            <RangePicker
              placeholder={["Applied From", "Applied Before"]}
              onChange={date_range_picker_applied}
              allowClear={true}
              value={[
                filters.applied_from_date
                  ? moment(filters.applied_from_date)
                  : null,
                filters.applied_to_date
                  ? moment(filters.applied_to_date)
                  : null,
              ]}
              format="DD/MM/YYYY"
              disabledDate={(date) =>
                to_disabled_range(date, "2024-05-06", "2024-09-30")
              }
            />
          </Box>
          <Box style={{ width: "260px" }}>
            <RangePicker
              placeholder={["Valid From", "Valid Till"]}
              onChange={date_range_picker_validity}
              value={[
                filters.valid_from_date
                  ? moment(filters.valid_from_date)
                  : null,
                filters.valid_to_date ? moment(filters.valid_to_date) : null,
              ]}
              allowClear={true}
              format="DD/MM/YYYY"
              disabledDate={(date) =>
                to_disabled_range(date, "2024-05-07", "2024-09-30")
              }
            />
          </Box>
          <Box style={{ width: "260px" }}>
            <RangePicker
              placeholder={["Entry From", "Entry To"]}
              onChange={date_range_picker_entry}
              allowClear={true}
              value={[
                filters.entry_from_date
                  ? moment(filters.entry_from_date)
                  : null,
                filters.entry_to_date ? moment(filters.entry_to_date) : null,
              ]}
              format="DD/MM/YYYY"
              disabledDate={(date) =>
                to_disabled_range(date, "2024-05-07", moment())
              }
            />
          </Box>

          <Select
            mode="multiple"
            placeholder="Vehicle Type"
            style={{ width: 120 }}
            value={filters.vehicle_type_ids}
            onChange={(value) => handle_change("vehicle_type_ids", value)}
            allowClear={true}
            showSearch={true}
            filterOption={(inputValue, option) =>
              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          >
            {local_storage_master_data?.vehicle_types?.map((vehicle_type) => (
              <Option value={vehicle_type?.id} key={vehicle_type?.id}>
                {vehicle_type?.name}
              </Option>
            ))}
          </Select>

          <Select
            mode="multiple"
            placeholder="Fuel Type"
            style={{ width: 120 }}
            value={filters.fuel_types}
            onChange={(value) => handle_change("fuel_types", value)}
            allowClear={true}
            showSearch={true}
            filterOption={(inputValue, option) =>
              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          >
            <Option value={"cng"}>CNG</Option>
            <Option value={"diesel"}>Diesel</Option>
            <Option value={"electric"}>Electric</Option>
            <Option value={"hybrid"}>Hybrid</Option>
            <Option value={"lpg"}>LPG</Option>
            <Option value={"petrol"}>Petrol</Option>
          </Select>

          <Select
            mode="multiple"
            placeholder="Purpose of Visit"
            style={{ width: 142 }}
            value={filters.reason_ids}
            onChange={(value) => handle_change("reason_ids", value)}
            allowClear={true}
            showSearch={true}
            filterOption={(inputValue, option) =>
              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          >
            {local_storage_master_data?.reasons?.map((reason) => (
              <Option key={reason?.id} value={reason?.id}>
                {reason?.name}
              </Option>
            ))}
          </Select>
          <Search
            placeholder="Search"
            className="search-box"
            ref={search_ref}
            value={filters.search_string}
            onChange={(event, values) =>
              set_filters({ ...filters, search_string: event.target.value })
            }
            onSearch={(value) => {
              set_filters({ ...filters, search_string: value });
            }}
          />

          <Button onClick={handle_clear_filters}>Clear Filters</Button>
          <div>
            <Button
              type="primary"
              onClick={get_pass_list_without_pagination}
              loading={pass_list_without_pagination_loading}
            >
              Download
            </Button>
          </div>
        </Space>
      </div>
      {/* )} */}
      <div>
        {deviceType === "mobile" ? (
          <div style={{ marginTop: "10px" }}>
            {pass_list_loading && (
              <div className="spinner">
                <Spin size="large" />
              </div>
            )}
            {pass_list?.map((x, index) => (
              <Card style={{ marginBottom: "15px" }}>
                <table>
                  <tr>
                    <th>Pass Number</th>
                    <td
                      className="cursor-pointer text-as-link"
                      onClick={() => {
                        return open_details_mobile(pass_list[index]);
                      }}
                    >
                      {x.pass_number}
                    </td>
                  </tr>
                  <tr>
                    <th>Applicant Name</th>
                    <td>{x.name}</td>
                  </tr>
                  <tr>
                    <th>Coming From</th>
                    <td>
                      {x?.from_district
                        ? x?.from_district?.name
                        : x?.from_state
                        ? x?.from_state?.name
                        : x?.from_country?.name}
                    </td>
                  </tr>
                  <tr>
                    <th>Applied On</th>
                    <td>{moment(x.applied_on).format("DD/MM/YYYY")}</td>
                  </tr>

                  <tr>
                    <th>Valid From</th>
                    <td>{moment(x.valid_from).format("DD/MM/YYYY")}</td>
                  </tr>
                  <tr>
                    <th>Valid Till</th>
                    <td>{moment(x.valid_till).format("DD/MM/YYYY")}</td>
                  </tr>
                  <tr>
                    <th>Destination</th>
                    <td>{x?.destination?.name}</td>
                  </tr>
                  <tr>
                    <th>Traveler Count</th>
                    <td>{x.total_passengers}</td>
                  </tr>
                  <tr>
                    <th>Vehicle Type</th>
                    <td>{x?.vehicle_type?.name}</td>
                  </tr>
                  <tr>
                    <th>Fuel Type</th>
                    <td>{capitalize(x.vehicle_fuel_type)}</td>
                  </tr>
                </table>
              </Card>
            ))}
          </div>
        ) : (
          <Table
            dataSource={pass_list}
            columns={columns}
            pagination={false}
            className="custom-table"
            rowClassName={"custom-row"}
            loading={pass_list_loading}
          />
        )}
        <div className="table-pagination">
          <Pagination
            showTotal={(total) => `Total ${total} items`}
            total={pass_list_pagination?.total_count}
            showSizeChanger={true}
            page_number={3}
            hideOnSinglePage={false}
            onChange={fetch_data}
            current={page_number}
          />
        </div>

        <Modal
          title={"Download Your Pass"}
          open={model_open}
          width={800}
          onCancel={() => set_model_open(false)}
          footer={null}
        >
          <Pass data={pass_data} />
        </Modal>
      </div>
    </div>
  );
};

export default PassList;
