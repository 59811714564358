import { to_disabled_range } from "@helpers/constants";
import { export_to_excel } from "@helpers/functions";
import {
  dynamicRequest,
  query_get_reason_report,
  query_get_vehicle_report,
  query_get_vehicle_report_download,
  useDynamicSelector,
} from "@services/redux";
import { Button, Card, DatePicker, Pagination, Spin, Table } from "antd";
import { capitalize } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
const { RangePicker } = DatePicker;

const VehicleReport = () => {
  const dispatch = useDispatch();

  const [deviceType, setDeviceType] = useState("desktop");
  const [report_items, set_report_items] = useState([]);
  const [items, set_items] = useState([]);
  const [filters, set_filters] = useState([]);

  const { items: vehicle_report_items, loading: vehicle_reports_loading } =
    useDynamicSelector("get_vehicle_type_on_date_basis_report");

  const { loading: vehicle_reports_download_loading } = useDynamicSelector(
    "get_vehicle_type_on_date_basis_report_download"
  );

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth < 768) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  useEffect(() => {
    if (vehicle_report_items) {
      set_report_items(vehicle_report_items);
    }
  }, [vehicle_report_items]);

  useEffect(() => {
    get_vehicle_report_list();
  }, [filters]);

  const get_vehicle_report_list = () => {
    let key = [{ key: "get_vehicle_type_on_date_basis_report", loading: true }];
    let query = query_get_vehicle_report;
    let variables = {
      // filter: filters,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    let data = report_items?.map((item) => ({
      date_time: item.date,
      total_count: item.total_count,
      bus_count: getCountByReason(item.counts, "Bus"),
      car_count: getCountByReason(item.counts, "Car"),
      mini_bus_count: getCountByReason(item.counts, "Mini Bus"),
      two_wheelers_count: getCountByReason(item.counts, "Two Wheelers"),
      van_count: getCountByReason(item.counts, "Van"),
      others_count: getCountByReason(item.counts, "Others"),
    }));
    set_items(data);
  }, [report_items]);

  const getCountByReason = (counts, vehicle) => {
    const countItem = counts.find((count) => count.vehicle_type === vehicle);
    return countItem ? countItem.count : 0;
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render: (date) => {
        return <p>{moment(date).format("DD/MM/YYYY")}</p>;
      },
    },
    {
      title: "Bus",
      dataIndex: "bus_count",
      key: "bus_count",
    },
    {
      title: "Car",
      dataIndex: "car_count",
      key: "car_count",
    },
    {
      title: "Mini Bus",
      dataIndex: "mini_bus_count",
      key: "mini_bus_count",
    },
    {
      title: "Two Wheelers",
      dataIndex: "two_wheelers_count",
      key: "two_wheelers_count",
    },
    {
      title: "Van",
      dataIndex: "van_count",
      key: "van_count",
    },
    {
      title: "Others",
      dataIndex: "others_count",
      key: "others_count",
    },
    {
      title: "Total Count",
      dataIndex: "total_count",
      key: "total_count",
    },
  ];

  const vehicle_list_download = () => {
    let arranged_data = arrange_xl_report_data(items);
    export_to_excel(arranged_data ?? [], "Vehicle Report");
  };

  const arrange_xl_report_data = (data_items) => {
    let totalCounts = {
      ["S.No"]: "Total",
      ["Date"]: "", // You can leave this empty for the total row
      ["Bus"]: 0,
      ["Car"]: 0,
      ["Mini Bus"]: 0,
      ["Two Wheelers"]: 0,
      ["Van"]: 0,
      ["Others"]: 0,
      ["Total Count"]: 0,
    };

    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      console.log("vvv", data_items[i]);
      let row_data = {
        ["S.No"]: i + 1,
        ["Date"]: moment(data_items[i]?.date_time).format("DD/MM/YYYY"),
        ["Bus"]: data_items[i]?.bus_count,
        ["Car"]: data_items[i]?.car_count,
        ["Mini Bus"]: capitalize(data_items[i]?.mini_bus_count),
        ["Two Wheelers"]: data_items[i]?.two_wheelers_count,
        ["Van"]: data_items[i]?.van_count,
        ["Others"]: data_items[i]?.others_count,
        ["Total Count"]: data_items[i]?.total_count,
      };

      // Update total counts
      totalCounts["Bus"] += data_items[i]?.bus_count;
      totalCounts["Car"] += data_items[i]?.car_count;
      totalCounts["Mini Bus"] += data_items[i]?.mini_bus_count;
      totalCounts["Two Wheelers"] += data_items[i]?.two_wheelers_count;
      totalCounts["Van"] += data_items[i]?.van_count;
      totalCounts["Others"] += data_items[i]?.others_count;
      totalCounts["Total Count"] += data_items[i]?.total_count;

      rearranged_data.push(row_data);
    }

    // Push total counts row to the end
    rearranged_data.push(totalCounts);

    return rearranged_data;
  };

  const date_range_picker_applied = (dates) => {
    console.log("date_range_picker_applied", dates);

    if (dates === null) {
      set_filters({
        ...filters,
        from_date: null,
        to_date: null,
      });
    }
    if (dates) {
      set_filters({
        ...filters,
        from_date: new Date(dates[0]),
        to_date: new Date(dates[1]),
      });
    }
  };

  return (
    <>
      <div className="list-wrapper">
        <div className="excel-download-button">
          <div style={{ width: "260px" }}>
            <RangePicker
              width={"200px"}
              placeholder={["From Date", "To Date"]}
              onChange={date_range_picker_applied}
              allowClear={true}
              // value={[
              //   filters.applied_from_date || null,
              //   filters.applied_to_date || null,
              // ]}
              format="DD/MM/YYYY"
              disabledDate={(date) =>
                to_disabled_range(date, "2024-05-06", "2024-06-30")
              }
            />
          </div>
          <Button
            type="primary"
            onClick={vehicle_list_download}
            loading={vehicle_reports_download_loading}
          >
            Download
          </Button>
        </div>

        <div>
          {deviceType === "mobile" ? (
            <div style={{ marginTop: "10px" }}>
              {vehicle_reports_loading && (
                <div className="spinner">
                  <Spin size="large" />
                </div>
              )}
              {items &&
                items?.map((x) => {
                  console.log(x, "x");

                  return (
                    <Card style={{ marginBottom: "15px" }}>
                      <table>
                        <tr>
                          <th>Date</th>
                          <td>{moment(x?.date).format("DD/MM/YYYY")}</td>
                        </tr>
                        <tr>
                          <th>Bus</th>
                          <td>{x?.bus_count || 0}</td>
                        </tr>
                        <tr>
                          <th>Car</th>
                          <td>{x?.car_count || 0}</td>
                        </tr>

                        <tr>
                          <th>Mini Bus</th>
                          <td>{x?.mini_bus_count || 0}</td>
                        </tr>
                        <tr>
                          <th>Two Wheelers</th>
                          <td>{x?.two_wheelers_count || 0}</td>
                        </tr>
                        <tr>
                          <th>Van</th>
                          <td>{x?.van_count || 0}</td>
                        </tr>
                        <tr>
                          <th>Others</th>
                          <td>{x?.others_count || 0}</td>
                        </tr>
                        <tr>
                          <th>Total Count</th>
                          <td>{x?.total_count || 0}</td>
                        </tr>
                      </table>
                    </Card>
                  );
                })}
            </div>
          ) : (
            <Table
              dataSource={items}
              columns={columns}
              pagination={false}
              loading={vehicle_reports_loading}
              rowClassName={"custom-row"}
              bordered
            />
          )}
        </div>
      </div>
    </>
  );
};

export default VehicleReport;
