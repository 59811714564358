import React from "react";
import { Box } from "@mui/material";
import AccessRights from "@views/components/access_rights/access_rights";

const AccessRightsPage = () => {
  return (
    <Box
      sx={{
        flex: 1,
      }}
    >
      <AccessRights />
    </Box>
  );
};
export default AccessRightsPage;
