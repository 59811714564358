import React, { Fragment, useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Header from "@views/components/system/Header/Header";
import Sidebar from "@views/components/system/Sidebar";
import Breadcrumbs from "@views/components/system/BreadCrumb/BreadCrumb";
import useStyles from "./appStyles-jss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Decoration from "./Decoration";
import { MenuContent, titleException } from "@helpers/constants";
import { CSSTransition } from "react-transition-group";
import "../../index.css";
import MuiSubmitButton from "@views/components/system/Forms/form_elements/mui_submit_button";
import { navigateBack } from "@helpers/navigator";
import { Button, Icon } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Footer from "@views/components/system/Header/Footer";
import _ from "lodash";

function LeftSidebarLayout(props) {
  const { classes, cx } = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [openGuide, setOpenGuide] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [page_header, setPageHeader] = useState("");
  const [inProp, setInProp] = useState(false);
  const mainContentRef = useRef(null);

  const { children, mode, gradient, deco, bgPosition, changeMode } = props;

  useEffect(() => {
    let parts = history.location.pathname.split("/");
    let place = parts[parts.length - 1].replace("-", " ");

    setPageHeader(place);
  }, [location]);

  useEffect(() => {
    mainContentRef.current.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (location?.pathname) {
      setInProp(true);
    }
    if (inProp) {
      setTimeout(() => {
        setInProp(false);
      }, 200);
    }
  }, [location]);

  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const handleOpenGuide = () => {
    setOpenGuide(true);
  };

  return (
    <Fragment>
      <Header
        toggleDrawerOpen={toggleDrawer}
        margin={sidebarOpen}
        gradient={true}
        position="left-sidebar"
        changeMode={changeMode}
        mode={mode}
        place={page_header}
        history={history}
        openGuide={handleOpenGuide}
      />
      <Sidebar
        open={sidebarOpen}
        toggleDrawerOpen={toggleDrawer}
        loadTransition={false}
        dataMenu={MenuContent}
        leftSidebar
      />
      <main
        className={cx(
          classes.content,
          !sidebarOpen ? classes.contentPaddingLeft : ""
        )}
        id="mainContent"
        ref={mainContentRef}
      >
        <Decoration
          mode={"light"}
          gradient={true}
          decoration={true}
          bgPosition={"full"}
          horizontalMenu={false}
        />
        <section className={cx(classes.mainWrap, classes.sidebarLayout)}>
          {/* <CSSTransition in={inProp} timeout={3000} classNames="my-node"> */}
          <div>{children}</div>
          {/* </CSSTransition> */}
        </section>
      </main>
      {/* <Footer margin={sidebarOpen} position="left-sidebar" /> */}
    </Fragment>
  );
}

export default LeftSidebarLayout;
