import { to_disabled_range } from "@helpers/constants";
import { retrieveItem } from "@helpers/storage";
import { dynamicRequest } from "@services/redux";
import { query_get_dashboard_count } from "@services/redux/slices/admin_dashboard/graphql_admin_dashboard_count";
import { Button, Select, Space, DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const { RangePicker } = DatePicker;

const DashboardFilter = (props) => {
  const { dashboard_type, tab_change } = props;
  const user = retrieveItem("user");
  const dispatch = useDispatch();
  const local_storage_master_data = retrieveItem("get_master_data");

  const [coming_from, set_coming_from] = useState(null);

  const [filters, set_filters] = useState({
    destination_id: null,
    reason_id: null,
    from_country_id_list: [],
    from_state_id_list: [],
    from_district_id_list: [],
    fuel_type_list: [],
    applied_from_date: null,
    applied_to_date: null,
    entered_from_date: null,
    entered_to_date: null,
  });

  const handle_change = (key, value) => {
    set_filters({ ...filters, [key]: value });
  };

  const clear_filters = () => {
    set_filters({
      destination_id: null,
      reason_id: null,
      from_country_id_list: [],
      from_state_id_list: [],
      from_district_id_list: [],
      fuel_type_list: [],
      applied_from_date: null,
      applied_to_date: null,
      entered_from_date: null,
      entered_to_date: null,
    });
  };

  useEffect(() => {
    if (tab_change) {
      clear_filters();
    }
  }, [tab_change]);

  useEffect(() => {
    get_dashboard_count(filters);
  }, [filters]);

  const get_dashboard_count = (filters) => {
    let key = [{ key: "get_dashboard_count", loading: true }];
    let query = query_get_dashboard_count;
    let localite_variables = {
      filter: {
        type: "local_resident",
        destination_id: filters?.destination_id,
        fuel_type_list: filters.fuel_type_list,
        applied_from_date: filters.applied_from_date,
        applied_to_date: filters.applied_to_date,
      },
    };
    let visitor_variables = {
      filter: {
        type: "",
        destination_id: filters?.destination_id,
        reason_id: filters.reason_id,
        from_country_id_list: filters.from_country_id_list,
        from_district_id_list: filters.from_district_id_list,
        from_state_id_list: filters.from_state_id_list,
        fuel_type_list: filters.fuel_type_list,
        applied_from_date: filters.applied_from_date,
        applied_to_date: filters.applied_to_date,
        entered_from_date: filters.entered_from_date,
        entered_to_date: filters.entered_to_date,
      },
    };

    const hasActiveFilters = Object.values(filters || {}).some(
      (value) => !!value
    );
    if (tab_change === "local_resident" && filters?.fuel_type_list) {
      dispatch(dynamicRequest(key, query, localite_variables));
      return;
    }
    if (tab_change === "visitor_dashboard" || hasActiveFilters) {
      dispatch(dynamicRequest(key, query, visitor_variables));
    } else if (
      tab_change === "local_resident" ||
      filters.fuel_type_list.length > 0
    ) {
      dispatch(dynamicRequest(key, query, localite_variables));
    }
  };

  useEffect(() => {
    if (filters?.direction_ids) {
      local_storage_master_data?.directions?.forEach((data) => {
        if (data?.id === filters?.direction_ids) {
          set_coming_from(data?.name);
        }
      });
    } else {
      set_coming_from(null);
    }
  }, [filters?.direction_ids]);

  const date_range_picker_applied = (dates) => {
    if (dates) {
      set_filters({
        ...filters,
        applied_from_date: dates[0] ? new Date(dates[0]) : null,
        applied_to_date: dates[1] ? new Date(dates[1]) : null,
      });
    } else {
      set_filters({
        ...filters,
        applied_from_date: null,
        applied_to_date: null,
      });
    }
  };

  const date_range_picker_entry = (dates) => {
    if (dates) {
      set_filters({
        ...filters,
        entered_from_date: dates[0] ? new Date(dates[0]) : null,
        entered_to_date: dates[1] ? new Date(dates[1]) : null,
      });
    } else {
      set_filters({
        ...filters,
        entered_from_date: null,
        entered_to_date: null,
      });
    }
  };

  return (
    <div>
      <div
        className="table-filter dashboard-filter-wrapper"
        style={{ marginBottom: 2 }}
      >
        <Space style={{ display: "flex", flexWrap: "wrap" }}>
          {user?.user?.roles?.[0]?.name === "Admin" && (
            <Select
              // mode="multiple"
              placeholder="Destination"
              style={{ width: 120 }}
              value={filters.destination_id}
              onChange={(value) => handle_change("destination_id", value)}
              allowClear={true}
              showSearch={true}
              filterOption={(inputValue, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
            >
              {local_storage_master_data?.destinations?.map((destination) => (
                <Option value={destination?.id} key={destination?.id}>
                  {destination?.name}
                </Option>
              ))}
            </Select>
          )}
          {dashboard_type === "visitor" && (
            <>
              <Select
                placeholder="Coming From"
                style={{ width: 130 }}
                value={filters.direction_ids}
                onChange={(value) => handle_change("direction_ids", value)}
                allowClear={true}
                showSearch={true}
                filterOption={(inputValue, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
              >
                {local_storage_master_data?.directions?.map((direction) => (
                  <Select.Option value={direction?.id} key={direction?.id}>
                    {direction?.name}
                  </Select.Option>
                ))}
              </Select>

              {coming_from === "Outside TN" && coming_from && (
                <Select
                  mode="multiple"
                  placeholder="State"
                  style={{ width: 120 }}
                  onChange={(value) =>
                    handle_change("from_state_id_list", value)
                  }
                  allowClear={true}
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {local_storage_master_data?.countries[0]?.states?.map(
                    (state) => {
                      if (state?.name !== "Tamil Nadu") {
                        return (
                          <Select.Option value={state?.id} key={state?.id}>
                            {state?.name}
                          </Select.Option>
                        );
                      }
                      return null;
                    }
                  )}
                </Select>
              )}

              {coming_from === "Within TN" && coming_from && (
                <Select
                  mode="multiple"
                  placeholder="District"
                  style={{ width: 120 }}
                  onChange={(value) =>
                    handle_change("from_district_id_list", value)
                  }
                  allowClear={true}
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {local_storage_master_data?.countries[0]?.states[0]?.districts?.map(
                    (district) => (
                      <Select.Option value={district?.id} key={district?.id}>
                        {district?.name}
                      </Select.Option>
                    )
                  )}
                </Select>
              )}

              {coming_from === "Foreign" && coming_from && (
                <Select
                  mode="multiple"
                  placeholder="Countries"
                  style={{ width: 120 }}
                  onChange={(value) =>
                    handle_change("from_country_id_list", value)
                  }
                  allowClear={true}
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {local_storage_master_data?.countries?.map((country) => {
                    if (country?.name !== "India") {
                      return (
                        <Select.Option value={country?.id} key={country?.id}>
                          {country?.name}
                        </Select.Option>
                      );
                    }
                    return null;
                  })}
                </Select>
              )}

              <Select
                placeholder="Purpose of Visit"
                style={{ width: 142 }}
                value={filters.reason_id}
                onChange={(value) => handle_change("reason_id", value)}
                allowClear={true}
                showSearch={true}
                filterOption={(inputValue, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
              >
                {local_storage_master_data?.reasons?.map((reason) => (
                  <Select.Option key={reason?.id} value={reason?.id}>
                    {reason?.name}
                  </Select.Option>
                ))}
              </Select>
            </>
          )}

          <Select
            mode="multiple"
            placeholder="Fuel Type"
            style={{ width: 120 }}
            value={filters.fuel_type_list}
            onChange={(value) => handle_change("fuel_type_list", value)}
            allowClear={true}
            showSearch={true}
            filterOption={(inputValue, option) =>
              option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          >
            <Select.Option value={"cng"}>CNG</Select.Option>
            <Select.Option value={"diesel"}>Diesel</Select.Option>
            <Select.Option value={"electric"}>Electric</Select.Option>
            <Select.Option value={"hybrid"}>Hybrid</Select.Option>
            <Select.Option value={"lpg"}>LPG</Select.Option>
            <Select.Option value={"petrol"}>Petrol</Select.Option>
          </Select>

          {/* Date pickers */}
          <div style={{ width: "260px" }}>
            <RangePicker
              placeholder={["Applied From", "Applied Before"]}
              onChange={date_range_picker_applied}
              allowClear={true}
              value={[
                filters.applied_from_date
                  ? moment(filters.applied_from_date)
                  : null,
                filters.applied_to_date
                  ? moment(filters.applied_to_date)
                  : null,
              ]}
              format="DD/MM/YYYY"
              disabledDate={(date) =>
                to_disabled_range(date, "2024-05-07", "2024-09-30")
              }
            />
          </div>
          <div style={{ width: "240px" }}>
            <RangePicker
              placeholder={["Entry From", "Entry Before"]}
              onChange={date_range_picker_entry}
              allowClear={true}
              value={[
                filters.entered_from_date
                  ? moment(filters.entered_from_date)
                  : null,
                filters.entered_to_date
                  ? moment(filters.entered_to_date)
                  : null,
              ]}
              format="DD/MM/YYYY"
              disabledDate={(date) =>
                to_disabled_range(date, "2024-05-07", moment())
              }
            />
          </div>
          {/* Clear Filters button */}
          <Button onClick={clear_filters} style={{ marginLeft: "8px" }}>
            Clear Filters
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default DashboardFilter;
