import { gql } from "@apollo/client";

export const login = gql`
  query login_admin($data: admin_login_input) {
    login_admin(data: $data) {
      data
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_captcha = gql`
  query request_captcha {
    request_captcha {
      id
      captcha
    }
  }
`;
