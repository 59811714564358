import React, { useEffect, useState } from "react";
import Hidden from "@mui/material/Hidden";
import useStyles from "../components/auth/user-jss";
import LoginForm from "@views/components/auth/login_form";
import Modal from "@views/components/system/Modal/modal";
import EPassLoginModal from "@views/components/epass_login_type_modal/epass_login_type_modal";

function Login() {
  const { classes } = useStyles();
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [is_india, set_is_india] = useState(true);

  useEffect(() => {
    handle_open_modal();
  }, []);

  const handle_open_modal = () => {
    set_is_modal_open(true);
  };
  const handle_close_modal = () => {
    set_is_modal_open(false);
  };

  const handle_outside_india = () => {
    set_is_india(false);
    handle_close_modal();
  };

  const handle_from_within_india = () => {
    set_is_india(true);
    handle_close_modal();
  };

  return (
    <div className={classes.rootFull}>
      <Hidden mdDown>
        <div className="login-background-container" />
      </Hidden>

      <div className={classes.sideFormWrap}>
        <LoginForm is_india={is_india} />
      </div>
      <Modal
        is_open={is_modal_open}
        handleClose={handle_close_modal}
        scroll={"body"}
        content={
          <EPassLoginModal
            handle_close_modal={handle_close_modal}
            within_india={handle_from_within_india}
            outside_india={handle_outside_india}
          />
        }
        title={"Where are you coming from?"}
        footer={false}
        width={900}
        is_closable={true}
      />
    </div>
  );
}

export default Login;
