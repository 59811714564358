import { gql } from "@apollo/client";

export const query_get_dashboard_count = gql`
  query get_dashboard_count($filter: dashboard_count_filter_input) {
    get_dashboard_count(filter: $filter) {
      items {
        vehicle_name
        today_applied_vehicles_count
        tomorrow_applied_vehicles_count
        total_applied_vehicles_count
        total_applied_passengers_count
        today_applied_passengers_count
        tomorrow_applied_passengers_count
        total_vehicle_entry_today
        total_vehicle_entry_yesterday
        total_vehicle_entry_overall
        total_passenger_entry_today
        total_passenger_entry_yesterday
        total_passenger_entry_overall
        total_vehicles_inside_now
        total_passenger_inside_now
        today_yet_to_enroll_vehicles_count
        today_yet_to_enroll_passengers_count
      }
      error {
        status_code
        message
      }
    }
  }
`;
