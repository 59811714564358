import jsPDF from "jspdf";
import React, { useRef } from "react";
import PaperBlock from "../system/PaperBlock/PaperBlock";
import { LoadingButton } from "@mui/lab";
import ApplyEPassForm from "./epass_apply_form";

const ApplyEPass = () => {
  const epass_ref = useRef(null);

  const generatePDF = () => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
    });

    doc.setFont("Arial Unicode MS", "normal");
    doc.text(14, 14, "₹ 100");

    doc.html(epass_ref.current, {
      x: 10,
      y: 10,
      width: 600,
      callback(generatedDoc) {
        generatedDoc.save("document");
        // printTable();
      },
    });
  };
  return (
    <div className="apply-epass">
      <ApplyEPassForm />
    </div>
  );
};

export default ApplyEPass;
