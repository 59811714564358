import { to_disabled_range } from "@helpers/constants";
import { downloadReasonReportExcel, export_to_excel } from "@helpers/functions";
import {
  dynamicRequest,
  query_get_reason_report,
  useDynamicSelector,
} from "@services/redux";
import { Button, Card, DatePicker, Pagination, Spin, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ReasonReportList = () => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;

  const [deviceType, setDeviceType] = useState("desktop");
  const [report_items, set_report_items] = useState([]);
  const [items, set_items] = useState([]);
  const [dates, set_dates] = useState([]);
  const [is_loading, set_is_loading] = useState(false);

  const {
    items: get_reason_on_date_basis_report_items,
    error: get_reason_on_date_basis_report_error,
    loading: get_reason_on_date_basis_report_loading,
  } = useDynamicSelector("get_reason_on_date_basis_report");

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth < 768) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);

  useEffect(() => {
    if (get_reason_on_date_basis_report_items) {
      set_report_items(get_reason_on_date_basis_report_items);
    }
  }, [get_reason_on_date_basis_report_items]);

  useEffect(() => {
    get_reason_report(dates);
  }, [dates]);

  const get_reason_report = (dates) => {
    let key = [{ key: "get_reason_on_date_basis_report", loading: true }];
    let query = query_get_reason_report;
    let variables = {
      filter: {
        from_date: dates?.[0] || "",
        to_date: dates?.[1] || "",
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    let data = report_items?.map((item) => ({
      date_time: item.date,
      total_count: item.total_count,
      non_essential_commodity: getCountByReason(
        item.counts,
        "Non Essential Commodity"
      ),
      agri_commodity: getCountByReason(item.counts, "Agri Commodity"),
      visiting_family_relatives: getCountByReason(
        item.counts,
        "Visiting Family/Relatives"
      ),
      essential_commodity: getCountByReason(item.counts, "Essential Commodity"),
      business: getCountByReason(item.counts, "Business"),
      local_resident: getCountByReason(item.counts, "Local Resident"),
      tourist: getCountByReason(item.counts, "Tourist"),
    }));
    set_items(data);
  }, [report_items]);

  const getCountByReason = (counts, reason) => {
    const countItem = counts?.find((count) => count.reason === reason);
    return countItem ? countItem.count : 0;
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "",
      key: "date_time",
      fixed: "left",
      width: 120,
      render: (record) => {
        return <>{moment(record?.date_time).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: "Non Essential Commodity",
      dataIndex: "non_essential_commodity",
      key: "non_essential_commodity",
      width: 120,
    },
    {
      title: "Agri Commodity",
      dataIndex: "agri_commodity",
      key: "agri_commodity",
      width: 120,
    },
    {
      title: "Visiting Family/Relatives",
      dataIndex: "visiting_family_relatives",
      key: "visiting_family_relatives",
      width: 120,
    },
    {
      title: "Essential Commodity",
      dataIndex: "essential_commodity",
      key: "essential_commodity",
      width: 120,
    },
    {
      title: "Business",
      dataIndex: "business",
      key: "business",
      width: 120,
    },
    {
      title: "Local Resident",
      dataIndex: "local_resident",
      key: "local_resident",
      width: 120,
    },
    {
      title: "Tourist",
      dataIndex: "tourist",
      key: "tourist",
      width: 120,
    },

    {
      title: "Total Count",
      dataIndex: "total_count",
      key: "total_count",
      width: 120,
    },
  ];

  const download_report = () => {
    let arranged_data = arrange_xl_report_data(items);
    export_to_excel(arranged_data ?? [], "Reason Report");
  };

  const arrange_xl_report_data = (data_items) => {
    let totalCounts = {
      ["S.No"]: "Total",
      ["Date"]: "", // You can leave this empty for the total row
      ["Non Essential Commodity"]: 0,
      ["Agri Commodity"]: 0,
      ["Visiting Family/Relatives"]: 0,
      ["Essential Commodity"]: 0,
      ["Business"]: 0,
      ["Local Resident"]: 0,
      ["Tourist"]: 0,
      ["Total"]: 0,
    };

    const rearranged_data = [];

    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Date"]: moment(data_items[i]?.date_time).format("DD/MM/YYYY"),
        ["Non Essential Commodity"]: data_items[i]?.non_essential_commodity,
        ["Agri Commodity"]: data_items[i]?.agri_commodity,
        ["Visiting Family/Relatives"]: data_items[i]?.visiting_family_relatives,
        ["Essential Commodity"]: data_items[i]?.essential_commodity,
        ["Business"]: data_items[i]?.business,
        ["Local Resident"]: data_items[i]?.local_resident,
        ["Tourist"]: data_items[i]?.tourist,
        ["Total"]: data_items[i]?.total_count,
      };

      // Update total counts
      totalCounts["Non Essential Commodity"] +=
        data_items[i]?.non_essential_commodity;
      totalCounts["Agri Commodity"] += data_items[i]?.agri_commodity;
      totalCounts["Visiting Family/Relatives"] +=
        data_items[i]?.visiting_family_relatives;
      totalCounts["Essential Commodity"] += data_items[i]?.essential_commodity;
      totalCounts["Business"] += data_items[i]?.business;
      totalCounts["Local Resident"] += data_items[i]?.local_resident;
      totalCounts["Tourist"] += data_items[i]?.tourist;
      totalCounts["Total"] += data_items[i]?.total_count;

      rearranged_data.push(row_data);
    }

    // Push total counts row to the end
    rearranged_data.push(totalCounts);

    set_is_loading(false);

    return rearranged_data;
  };

  return (
    <>
      <div className="list-wrapper">
        <div className="excel-download-button">
          <div style={{ width: "260px" }}>
            <RangePicker
              width={"200px"}
              placeholder={["From Date", "To Date"]}
              allowClear={true}
              format="DD/MM/YYYY"
              disabledDate={(date) =>
                to_disabled_range(date, "2024-05-06", "2024-06-30")
              }
              onChange={(dates) => {
                set_dates(dates);
              }}
            />
          </div>
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                set_is_loading(true);

                download_report();
              }}
              loading={is_loading}
            >
              Download
            </Button>
          </div>
        </div>

        <div>
          {deviceType === "mobile" ? (
            <div style={{ marginTop: "10px" }}>
              {items &&
                items?.map((x) => (
                  <Card style={{ marginBottom: "15px" }}>
                    {get_reason_on_date_basis_report_loading && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Spin size="large" />
                      </div>
                    )}
                    <table>
                      <tr>
                        <th>Date</th>
                        <td>{moment(x.date_time).format("DD/MM/YYYY")}</td>
                      </tr>
                      <tr>
                        <th>Non Essential Commodity</th>
                        <td>{x.non_essential_commodity}</td>
                      </tr>
                      <tr>
                        <th>Agri Commodity</th>
                        <td>{x.agri_commodity}</td>
                      </tr>
                      <tr>
                        <th>Visiting Family/Relatives</th>
                        <td>{x.visiting_family_relatives}</td>
                      </tr>
                      <tr>
                        <th>Essential Commodity</th>
                        <td>{x.essential_commodity}</td>
                      </tr>
                      <tr>
                        <th>Business</th>
                        <td>{x.business}</td>
                      </tr>
                      <tr>
                        <th>Local Resident</th>
                        <td>{x.local_resident}</td>
                      </tr>
                      <tr>
                        <th>Tourist</th>
                        <td>{x.tourist}</td>
                      </tr>
                      <tr>
                        <th>Total</th>
                        <td>{x.total_count}</td>
                      </tr>
                    </table>
                  </Card>
                ))}
            </div>
          ) : (
            <Table
              dataSource={items}
              columns={columns}
              pagination={false}
              rowClassName={"custom-row"}
              bordered
              loading={get_reason_on_date_basis_report_loading}
              scroll={{
                x: 1300,
              }}
            />
          )}
          {/* <div className="table-pagination">
            <Pagination
              showSizeChanger={true}
              page_number={3}
              hideOnSinglePage={false}
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ReasonReportList;
