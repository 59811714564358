import { gql } from "@apollo/client";

export const query_get_vehicle_report = gql`
  query get_vehicle_type_on_date_basis_report($filter: report_filter_input) {
    get_vehicle_type_on_date_basis_report(filter: $filter) {
      items {
        date
        counts {
          field_name
          count
        }
        total_count
      }
    }
  }
`;

export const query_get_vehicle_report_download = gql`
  query get_vehicle_type_on_date_basis_report_download(
    $filter: report_filter_input
  ) {
    get_vehicle_type_on_date_basis_report_download: get_vehicle_type_on_date_basis_report(
      filter: $filter
    ) {
      items {
        date
        counts {
          field_name: vehicle_type
          count
        }
        total_count
      }
    }
  }
`;
