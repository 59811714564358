import { gql } from "@apollo/client";

export const query_get_application_list = gql`
  query get_application_list(
    $pagination: pagination_input
    $filters: get_application_list_filter_input
  ) {
    get_application_list(pagination: $pagination, filter: $filters) {
      items {
        application_number
        pass_number
        total_passengers
        vehicle_fuel_type
        applied_on
        destination {
          name
          code
        }
        vehicle_number
        vehicle_type {
          name
        }
        valid_till
        valid_from
        name
        reason {
          name
          code
        }
        total_passengers

        from_country {
          name
          code
        }
        from_state {
          name
          code
        }
        from_district {
          name
          code
        }
        applicant {
          name
          mobile
          email
        }
        from_pincode
        from_address_1
        vehicle_manufactured_year
        qr_data
        approved_by {
          name
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_application_list_without_pagination = gql`
  query get_application_list(
    $pagination: pagination_input
    $filters: get_application_list_filter_input
  ) {
    pass_list_without_pagination: get_application_list(
      pagination: $pagination
      filter: $filters
    ) {
      items {
        application_number
        pass_number
        total_passengers
        vehicle_fuel_type
        applied_on
        destination {
          name
        }
        vehicle_number
        vehicle_type {
          name
        }
        valid_till
        valid_from
        name
        total_passengers
        reason {
          name
          code
        }
        from_country {
          name
          code
        }
        from_state {
          name
          code
        }
        from_district {
          name
          code
        }
        applicant {
          name
          mobile
          email
        }
        from_pincode
        from_address_1
        vehicle_manufactured_year
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const query_get_master_data = gql`
  query get_master_data($custom: master_data_input_input) {
    get_master_data(custom: $custom) {
      destinations {
        id
        name
      }
      reasons {
        id
        name
      }
      directions {
        id
        name
      }
      vehicle_types {
        id
        name
      }
    }
  }
`;
