import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  dynamicRequest,
//   get_api_modules_query,
//   introspection_query,
//   sync_api_modules_mutation,
  useDynamicSelector,
} from "@services/redux";
import { Button } from "@mui/material";
import toast from "react-hot-toast";
import { get_api_modules_query, introspection_query, sync_system_api_modules_mutation } from "@services/redux/slices/access_rights/graphql_access_right";

const SyncEndpoints = () => {
  const { loading, types } = useDynamicSelector("schema");
  const { loading: sync_loading, status } =
    useDynamicSelector("sync_endpoints");
  const dispatch = useDispatch();

  useEffect(() => {
    loadEndpoints();
  }, []);

  useEffect(() => {
    if (types) {
      let keys = [{ key: "sync_endpoints", loading: true }];
      let queries = types
        .find((x) => x.name === "Query")
        .fields.map((x) => x.name);
      let mutations = types
        .find((x) => x.name === "Mutation")
        .fields.map((x) => x.name);
      let api_names = [...queries, ...mutations];
      let variables = {
        custom:{
            api_names,
        }
       
      };
      dispatch(dynamicRequest(keys, sync_system_api_modules_mutation, variables, "M"));
    }
  }, [types]);

  useEffect(() => {
    if (status) {
      if (status === "success") {
        loadEndpoints();

        toast.success("Endpoints synced successfully");
      }
    }
  }, [status]);

  const loadEndpoints = () => {
    let keys = [{ key: "endpoints", loading: true }];
    let variables = {};
    dispatch(dynamicRequest(keys, get_api_modules_query, variables));
  };

  const handleSyncEndpoints = () => {
    let keys = [{ key: "schema", loading: true }];
    let variables = null;
    dispatch(dynamicRequest(keys, introspection_query, variables));
  };

  return (
    <Box width="100%">
      <Button
        variant="contained"
        color="primary"
        onClick={handleSyncEndpoints}
        disabled={loading || sync_loading}
      >
        Sync Endpoints
      </Button>
    </Box>
  );
};
export default SyncEndpoints;
