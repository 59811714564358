import Login from "@views/pages/login";
import Redirector from "@views/pages/redirector";
import LeftSidebarLayout from "@views/layouts/LeftSidebarLayout";
import AccessRightsPage from "@views/pages/access_rights";
import Outer from "@views/layouts/Outer";
import UserDashboard from "@views/pages/user_dashboard";
import ChangePassword from "@views/pages/change_password";
import ApplyEPass from "@views/components/epass_apply/apply_epass";
import PassList from "@views/components/pass_list/pass_list";
import CheckerList from "@views/components/checker_list/checker_list";
import AdminDashboard from "@views/components/admin_dashboard/dashboard_tab";
import Pass from "@views/pages/pass";
import AdminReports from "@views/pages/reports";
import AdminLogin from "@views/components/admin_login/admin_login";
import FeedBack from "@views/components/feedback/feedback";
import EntryList from "@views/components/entry_list/entry_list";
import InsideList from "@views/components/inside_list/inside_list";
import VehicleReport from "@views/components/admin_reports/vehicle_report";
import ReasonReport from "@views/pages/reason_report";
import ReasonReportOnEntryDate from "@views/pages/reason_report_on_entry_date";
import VehicleEntryReport from "@views/components/admin_reports/vehicle_entry_report";
import DistrictOnDateBasisReport from "@views/components/admin_reports/district_on_date_basis_report";
import StateOnEntryDateBasisReport from "@views/components/admin_reports/state_on_entry_date_basis_report";
import FuelTypeOnEntryDateBasisReport from "@views/components/admin_reports/fuel_type_on_entry_date_basis_report";
import CountryOnEntryDateBasisReport from "@views/components/admin_reports/country_on_entry_date_basis_report";
import StateOnDateBasisReport from "@views/components/admin_reports/state_on_date_basis_report";
import FuelTypeReport from "@views/components/admin_reports/fuel_type_report";
import ScannedLocations from "@views/pages/scanned_locations";

export const ROUTES = {
  REDIRECTOR: "/",
  LOGIN: "/login",
  CHANGE_PASSWORD: "/change-password",
  // DASHBOARD: "/dashboard",
  ACCESS_RIGHTS: "/access-rights",
  PROJECT_DETAILS: "/project-details/:project_id/:name",
  DRAW_IO: "/flow-chart",
  TIME_SHEET: "/timesheet",
  REIMBURSEMENT: "/reimbursement",
  REIMBURSEMENT_EMPLOYEE_DETAILS: "/reimbursement-details",
  EPASS_DOWNLOAD: "/epass-download",
  PASS: "/pass",
  APPLY_EPASS: "/apply-epass",
  PASS_LIST: "/pass-list",
  REPORTS: "/reports",
  CHECKER_LIST: "/checker-list",
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_LOGIN: "/admin-login",
  FEEDBACK: "/feedback",
  ENTRY_LIST: "/entry-list",
  INSIDE_LIST: "/inside-list",
  VEHICLE_REPORT: "/vehicle-report",
  REASON_REPORT: "/reason-report",
  REASON_ON_ENTRY_DATE_BASED_REPORT: "/reason-on-entry-date-based-report",
  VEHICLE_ENTRY_REPORT: "/vehicle-entry-report",
  DISTRICT_ON_DATE_BASIS_REPORT: "/district-on-date-basis-report",
  STATE_ON_ENTRY_DATE_BASIS_REPORT: "/state-on-entry-date-basis-report",
  FUEL_TYPE_REPORT: "/fuel-type-report",
  STATE_ON_DATE_BASIS_REPORT: "/state-on-date-basis-report",
  COUNTRY_ON_ENTRY_DATE_BASIS_REPORT: "/country-on-entry-date-basis-report",
  FUEL_TYPE_ON_ENTRY_DATE_BASIS_REPORT: "/fuel-on-entry-date-basis-report",
  CHECKPOST: "/checkpost",
};

const myRoutes = [
  {
    path: ROUTES.REDIRECTOR,
    exact: true,
    key: "redirector",
    component: Redirector,
    authenticate: false,
  },
  {
    path: ROUTES.PASS,
    exact: true,
    key: "pass",
    component: Pass,
    authenticate: false,
  },

  // {
  //   path: ROUTES.APPLY_EPASS,
  //   key: "Admin",
  //   component: ApplyEPass,
  //   authenticate: false,
  // },

  {
    path: ROUTES.ACCESS_RIGHTS,
    key: "Access Rights",
    authenticate: false,
    component: AccessRightsPage,
  },
  {
    path: ROUTES.ADMIN_LOGIN,
    key: "admin-dashboard",
    component: AdminLogin,
    authenticate: false,
  },
  {
    key: "user_login",
    authenticate: false,
    component: Outer,
    children: [
      {
        path: ROUTES.LOGIN,
        key: "login",
        component: Login,
        authenticate: false,
      },
      {
        path: ROUTES.CHANGE_PASSWORD,
        key: "change-password",
        component: ChangePassword,
        authenticate: false,
      },
      {
        path: ROUTES.DASHBOARD,
        key: "dashboard",
        component: UserDashboard,
        authenticate: false,
      },
      {
        path: ROUTES.APPLY_EPASS,
        key: "Admin",
        component: ApplyEPass,
        authenticate: false,
      },
    ],
  },

  {
    key: "Admin",
    component: LeftSidebarLayout,
    authenticate: false,
    children: [
      {
        path: ROUTES.ADMIN_DASHBOARD,
        key: "dashboard",
        component: AdminDashboard,
        authenticate: false,
      },
      {
        path: ROUTES.PASS_LIST,
        key: "pass list",
        component: PassList,
        authenticate: false,
      },
      {
        path: ROUTES.ENTRY_LIST,
        key: "entry-list",
        component: EntryList,
        authenticate: false,
      },
      {
        path: ROUTES.FEEDBACK,
        key: "feedback",
        component: FeedBack,
        authenticate: false,
      },
      {
        path: ROUTES.CHECKER_LIST,
        key: "checker list",
        component: CheckerList,
        authenticate: false,
      },
      {
        path: ROUTES.INSIDE_LIST,
        key: "inside list",
        component: InsideList,
        authenticate: false,
      },
      {
        path: ROUTES.REPORTS,
        key: "reports",
        component: AdminReports,
        authenticate: false,
      },
      {
        path: ROUTES.VEHICLE_REPORT,
        key: "vehicle report",
        component: VehicleReport,
        authenticate: false,
      },
      {
        path: ROUTES.REASON_REPORT,
        key: "reason report",
        component: ReasonReport,
        authenticate: false,
      },
      {
        path: ROUTES.REASON_ON_ENTRY_DATE_BASED_REPORT,
        key: "reason on entry date based report",
        component: ReasonReportOnEntryDate,
        authenticate: false,
      },
      {
        path: ROUTES.VEHICLE_ENTRY_REPORT,
        key: "vehicle entry report",
        component: VehicleEntryReport,
        authenticate: false,
      },
      {
        path: ROUTES.DISTRICT_ON_DATE_BASIS_REPORT,
        key: "district on date basis report",
        component: DistrictOnDateBasisReport,
        authenticate: false,
      },
      {
        path: ROUTES.STATE_ON_ENTRY_DATE_BASIS_REPORT,
        key: "district on date basis report",
        component: StateOnEntryDateBasisReport,
        authenticate: false,
      },
      {
        path: ROUTES.FUEL_TYPE_REPORT,
        key: "fuel type report",
        component: FuelTypeReport,
        authenticate: false,
      },
      {
        path: ROUTES.STATE_ON_DATE_BASIS_REPORT,
        key: "fuel type report",
        component: StateOnDateBasisReport,
        authenticate: false,
      },
      {
        path: ROUTES.COUNTRY_ON_ENTRY_DATE_BASIS_REPORT,
        key: "fuel type report",
        component: CountryOnEntryDateBasisReport,
        authenticate: false,
      },
      {
        path: ROUTES.FUEL_TYPE_ON_ENTRY_DATE_BASIS_REPORT,
        key: "fuel type report",
        component: FuelTypeOnEntryDateBasisReport,
        authenticate: false,
      },
      {
        path: ROUTES.CHECKPOST,
        key: "checkpost",
        component: ScannedLocations,
        authenticate: false,
      },
    ],
  },
];
export default myRoutes;
