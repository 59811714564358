import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import useStyles from "./user-jss";
import { Form } from "antd";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { FormControlLabel, TextField } from "@mui/material";
import { ArrowForward, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  dynamicClear,
  dynamicRequest,
  login,
  useDynamicSelector,
  userLogin,
} from "@services/redux";
import { navigate } from "@helpers/navigator";
import { ROUTES } from "@views/routes/my_routes";
import { storeItem } from "@helpers/storage";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import MuiInput from "@views/components/system/Forms/form_elements/mui_input";
import MuiPassword from "@views/components/system/Forms/form_elements/mui_password";
import SubmitButton from "@views/components/system/Forms/form_elements/submit_button";
import { MethodType } from "@helpers/service_call";

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) {
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

function ChangePasswordForm(props) {
  const { classes, cx } = useStyles();
  const { deco } = props;

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const {
    error,
    // page_accesses,
    // page_component_accesses,
    name,
    roles,
    home_page,
    token,
    loading: login_loading,
  } = useDynamicSelector("login");
  useEffect(() => {
    storeItem("token", token);
    // storeItem("page_accesses", page_accesses);
    // storeItem("page_component_accesses", page_component_accesses);
    storeItem("role", roles?.[0]);

    if (error) {
      toast.error(error.message);
    }
    if (token) {
      navigate(ROUTES.DASHBOARD);
    }
    // if (home_page) {
    //   if (home_page === "Super Admin") {
    //     navigate(ROUTES.ACCESS_RIGHTS);
    //   } else if (home_page === "Operator Home") {
    //     navigate(ROUTES.OPERATOR_HOME);
    //   } else if (home_page === "Verification") {
    //     navigate(ROUTES.ADMIN_CROP_PHOTO_VERIFICATION);
    //   } else if (home_page === "Admin") {
    //     navigate(ROUTES.SUBSCRIPTION_PLANS);
    //   } else if (home_page === "Franchise") {
    //     navigate(ROUTES.FRANCHISE_MEMBERS);
    //   } else if (home_page === "Telecaller Home") {
    //     navigate(ROUTES.TELE_CALLER_HOME);
    //   }
    // }
  }, [token, error, home_page]);

  const handle_submit = (values) => {
    let user_login_key = [{ key: "login", loading: true }];
    let user_login_query = login;
    let user_login_variables = {
      username: values?.username,
      password: values?.password,
    };
    dispatch(
      dynamicRequest(
        user_login_key,
        user_login_query,
        user_login_variables,
        MethodType.POST
      )
    );
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Paper className={cx(classes.sideWrap, deco && classes.petal)}>
      <div className={classes.topBar}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {/* <img
            src={require("../../../assets/images/v.png")}
            alt={"name"}
            style={{
              width: 50,
            }}
          /> */}
          <Typography variant="h4" gutterBottom>
            {"E-PASS"}
          </Typography>
        </div>
        {/* <Button
          size="small"
          className={classes.buttonLink}
          component={LinkBtn}
          to="/register-v2"
        >
          <Icon className={classes.icon}>arrow_forward</Icon>
          Create new account
        </Button> */}
      </div>
      <Typography variant="h5">Change Password</Typography>
      {/* <Typography
        variant="caption"
        className={classes.subtitle}
        gutterBottom
        align="center"
      >
        Lorem ipsum dolor sit amet
      </Typography> */}
      <section className={classes.socmedSideLogin}>
        <div className={classes.btnArea}></div>
      </section>
      <section className={classes.pageFormSideWrap}>
        <Form onFinish={handle_submit}>
          <MuiPassword
            field={"password"}
            label={"New Password"}
            message={"Password is required"}
            is_required={true}
            class_name={classes.field}
          />
          <MuiPassword
            field={"password"}
            label={"Confirm New Password"}
            message={"Confirm New is required"}
            is_required={true}
            class_name={classes.field}
          />

          <SubmitButton
            label={"Change Password"}
            show_icon={true}
            class_name={classes.btnArea}
            icon_class_name={cx(classes.rightIcon, classes.iconSmall)}
            loading={login_loading}
          />
        </Form>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 5,
          }}
        >
          <Button
            size="small"
            component={LinkBtn}
            to="/login"
            className={classes.buttonLink}
            style={{
              textDecoration: "underline",
            }}
          >
            <Typography>Login</Typography>
          </Button>
        </div>
      </section>
    </Paper>
  );
}

export default ChangePasswordForm;
