import React from "react";
import { Box, Typography } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars-2";
import ManageRole from "./role/manage_role";
import ManagePage from "./page/manage_page";
import SyncEndpoints from "./endpoint/sync_endpoints";
import RoleList from "./role/role_list";
import PageList from "./page/page_list";

const AccessRights = (props) => {
  const { height } = props;

  return (
    <Box
      sx={{
        flex: 1,
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // marginBottom: "10px",
            margin: 10,
          }}
        >
          <Typography variant="h6" fontWeight="bold" textTransform="uppercase">
            Access Rights
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "420px",
              height: "75px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "120px" }}>
              <ManageRole />
            </div>
            <div style={{ width: "120px" }}>
              <ManagePage />
            </div>
            <div style={{ width: "150px" }}>
              <SyncEndpoints />
            </div>
          </div>
        </div>
        <div>
          <Scrollbars
            id="sbSuperAdminContent"
            style={{ width: "100%", height: height || "600px" }}
            universal={true}
            renderView={(props) => (
              <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
            )}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                position: "sticky",
                top: "0px",
                zIndex: 99,
              }}
            >
              <div
                style={{
                  position: "sticky",
                  left: "0px",
                  minWidth: "200px",
                  height: "75px",
                  backgroundColor: "#5bcf85",
                  borderTop: "1px solid black",
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  zIndex: "2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Pages
                </Typography>
              </div>
              <RoleList />
            </div>
            <PageList />
          </Scrollbars>
        </div>
      </div>
    </Box>
  );
};

export default AccessRights;
