import { gql } from "@apollo/client";

export const get_feedbacks_query = gql`
  query get_feedback_list(
    $data: feedback_list_filter_input
    $pagination: pagination_input
  ) {
    get_feedback_list(data: $data, pagination: $pagination) {
      items {
        id
        created_datetime
        description
        feedback_type_id
        system_user {
          id
          name
          mobile
          email
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;

export const query_get_feedback_list_without_pagination = gql`
  query get_feedback_list(
    $data: feedback_list_filter_input
    $pagination: pagination_input
  ) {
    feedback_list_without_pagination: get_feedback_list(
      data: $data
      pagination: $pagination
    ) {
      items {
        id
        created_datetime
        description
        feedback_type_id
        system_user {
          id
          name
          mobile
          email
        }
      }
      pagination {
        total_count
        page_number
        page_limit
      }
    }
  }
`;
