import React, { useState } from "react";
import useStyles from "../components/auth/user-jss";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "@assets/images/tn_logo.png";
import { Country } from "country-state-city";
function Pass() {
  const [loading, set_loading] = useState(false);
  let data = {
    id: "12345678",
    name: "Someshkumar G",
    pass_no: "EP/N/LR/0705/1234567",
    mobile: "7708950755",
    vehicle_type: "Car",
    total_travelers: 4,
    purpose_of_visit: "Local resident",
    address: {
      line1: "109 B2, Vaigai Street, Thiruvalluvar Nagar, Vickramasingapuram",
      line2: "",
      district: {
        name: "Nilgiris",
        code: "TVL",
      },
      state: {
        name: "Tamil Nadu",
        code: "TN",
      },
      country: {
        name: "India",
      },
      pincode: "643211",
    },
    valid_from: "07-06-2024",
    valid_to: "30-06-2024",
    issued_by: "District Collector, Nilgiris",
    issued_on: "04-06-2024",
    district_name: "Chittoor",
    state_name: "",
    to_district: {
      name: "Nilgiris",
      code: "NGL",
    },
    vehicle_no: "TN36AD5802",
    qr_value: "12345678",
    // color: "#0D47A1",
    // color: "purple",
    color: "darkgreen",
    // pass_type:"commercial",
    pass_type: "local",
    // pass_type: "commercial",
  };

  const terms = [
    {
      name: "Plastic is banned in Kodaikanal / Nilgiris. Do not carry or use plastic carry bags, plastic cups, water bottles, soft drink bottles, etc., in Kodaikanal / Nilgiris. Fines will be imposed on violators. (WMP.No.15112/2019 in W.P.No.15120/2019)",
    },
    {
      name: "Do not litter.",
    },
    {
      name: "Do not cook or consume food items along the roadside.",
    },
    {
      name: "Avoid speeding.",
    },
    {
      name: "vehicles might be stopped wherever necessary, such as at the border check post for plastic checking and collection of toll tax.",
    },
  ];
  const savePDF = async () => {
    set_loading(true);

    // var PassDesktop = document.getElementById("pass");
    // if (PassDesktop) {
    //   PassDesktop.style.display = "block";
    // }
    var options = {
      scrollX: 0,
      scrollY: 0,
      width: 793,
      height: 1122,
    };
    // if (this.props.match) {
    //   options.scrollX = -7;
    // }
    window.scrollTo(0, 0);
    const pdf = new jsPDF("p", "mm", "a4", true);
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    var dataURL = "";
    const printArea = document.getElementById("pass");
    await html2canvas(printArea, options).then((canvas) => {
      dataURL = canvas.toDataURL();
    });
    pdf.addImage(dataURL, "JPEG", 0, 0, width, height, "", "FAST");

    var pagePromises = [];

    // Promise.all(pagePromises).then((values) => {
    pdf.save("pass.pdf");
    // });
    // if (PassDesktop) {
    //   PassDesktop.style.display = "none";
    // }
    setTimeout(() => {
      set_loading(false);
    }, 10000);
  };
  let from_code =
    data?.address?.district?.code || data?.address?.state?.code || "F";
  let to_code = data?.to_district?.code;
  let address = data?.address?.line1;
  if (data?.address?.line2) {
    address += ", " + data?.address?.line2;
  }
  if (data?.address?.district?.name)
    address += ", " + data?.address?.district?.name;
  else if (data?.address?.district_name)
    address += ", " + data?.address?.district_name;
  if (data?.address?.state?.name) address += ", " + data?.address?.state?.name;
  else if (data?.address?.state_name)
    address += ", " + data?.address?.state_name;
  address += ", " + data?.address?.country?.name;
  address += " - " + data?.address?.pincode;

  return (
    <div div className="row">
      <div id="pass" className="container text-center d-inline-block pass">
        <div className="row">
          <div
            className="col-2 small-card"
            style={{ backgroundColor: data?.color }}
          >
            {data?.pass_type === "tourist" ? (
              <div className="row" style={{ display: "ruby" }}>
                {from_code.split("").map((x, i) => (
                  <div key={i} style={{ margin: "auto", color: "white" }}>
                    {x}
                  </div>
                ))}
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto", color: "white" }}>-</div>
                <div style={{ margin: "auto" }}> </div>
                <div style={{ margin: "auto" }}> </div>
                {to_code.split("").map((x, i) => (
                  <div key={i} style={{ margin: "auto", color: "white" }}>
                    {x}
                  </div>
                ))}
              </div>
            ) : (
              <div className="row">
                {data?.pass_type?.split("").map((x, i) => (
                  <div key={i} style={{ margin: "auto", color: "white" }}>
                    {x}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="col-10 mt-3">
            <div className="center">
              <img src={logo} alt="logo" width={100} />
              <br />
              <span className="fw-bold fs-3">Tamil Nadu ePass</span>
            </div>
            <div className="row">
              <div className="col-4">
                <QRCode
                  id="QR"
                  value={data?.qr_value || " "}
                  level={"H"}
                  includeMargin={true}
                  renderAs={"canvas"}
                  size={200}
                />
              </div>
              <div className="col-6 text-center vehicle-number">
                {data?.vehicle_no}
              </div>
            </div>
            <div style={{ gap: "20px", display: "grid" }}>
              <div className="row">
                <div className="col-4 text-start fw-bold">Pass Number</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start ">{data?.pass_no}</div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Name</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{data?.name}</div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Mobile Number</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{data?.mobile}</div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Vehicle Type</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{data?.vehicle_type}</div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Total Travelers</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{data?.total_travelers}</div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Purpose of Visit</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{data?.purpose_of_visit}</div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">From</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">{address}</div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Validity</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">
                  From {data?.valid_from} until {data?.valid_to}
                </div>
              </div>
              <div className="row">
                <div className="col-4 text-start fw-bold">Issued By</div>
                <div className="col-1 text-center">:</div>
                <div className="col-7 text-start">
                  {data?.issued_by} on {data?.issued_on}
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="fw-bold text-start">Terms and Conditions</div>
              <ol>
                {terms.map((x, i) => (
                  <li key={i} className="text-start">
                    {i + 1}. {x.name}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="download-button">
        <button onClick={savePDF}>Download</button>
      </div>
    </div>
  );
}

export default Pass;
