import { gql } from "@apollo/client";

export const query_get_district_on_date_basis_report = gql`
  query get_district_on_date_basis_report($filter: report_filter_input) {
    get_district_on_date_basis_report(filter: $filter) {
      items {
        total_count
        date
        counts {
          count
          field_name
        }
      }
    }
  }
`;

export const query_get_district_on_entry_basis_report = gql`
  query get_district_on_entry_date_basis_report($filter: report_filter_input) {
    get_district_on_entry_date_basis_report(filter: $filter) {
      items {
        total_count
        date
        counts {
          count
          field_name
        }
      }
    }
  }
`;
