import React, { useState } from "react";
import { Modal, Button, Upload, Form as AntdForm } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Form from "@views/components/ui/antd_form";
import { get_excel_response } from "@helpers/functions";
import * as XLSX from "xlsx";

const UploadExcelModal = (props) => {
  const { visible, handle_cancel, handle_upload } = props;
  const [fileList, setFileList] = useState([]);
  const [excelData, setExcelData] = useState([]);

  const handle_change = (info) => {
    setFileList(info.fileList);
  };

  const handle_remove = () => {
    setFileList([]);
  };

  const handle_submit = (values) => {
    console.log("values", values);
    let response = get_excel_response();
    console.log("response", response);
    // handle_upload(fileList[0==]);
  };

  const handleExcelUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(jsonData);
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  const handleSubmit = () => {
    // Send the excelData to your API
    console.log("Excel data:", excelData);
  };

  const on_submit = (values) => {
    console.log(values, "values");
  };
  return (
    <Modal
      title="Upload Checker List Excel"
      open={visible}
      onCancel={handle_cancel}
      footer={[
        <Button key="cancel" onClick={handle_cancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handle_submit}
          htmlType="submit"
        >
          Upload
        </Button>,
      ]}
    >
      {/* <AntdForm layout="vertical" onSubmit={on_submit}>
        <Form.File
          label="File"
          field="url"
          rules={[{ required: true, message: "File is required" }]}
          allowFileFormats={["xlsx"]}
        />
      </AntdForm> */}
      <Upload
        accept=".xlsx, .xls"
        beforeUpload={handleExcelUpload}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>Upload Excel File</Button>
      </Upload>
    </Modal>
  );
};

export default UploadExcelModal;
