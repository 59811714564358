import { countFormat } from "@helpers/functions";
import React from "react";
import { FaCarSide } from "react-icons/fa";
import { FaVanShuttle } from "react-icons/fa6";
import { FaBus } from "react-icons/fa6";
import { MdDirectionsBusFilled } from "react-icons/md";
import { RiMotorbikeFill } from "react-icons/ri";
import { RiEBikeFill } from "react-icons/ri";
import SkeletonComponentSingleCard from "./skeleton_component_single_card";

const SingleCardAdmin = (props) => {
  const vehicles = props.items;
  const render_icon = (vehicle) => {
    if (vehicle === "Car") return <FaCarSide />;
    if (vehicle === "Van") return <FaVanShuttle />;
    if (vehicle === "Mini Bus") return <MdDirectionsBusFilled />;
    if (vehicle === "Two Wheelers") return <RiMotorbikeFill />;
    if (vehicle === "Bus") return <FaBus />;
    if (vehicle === "Others") return <RiEBikeFill />;
    if (vehicle === "Unknown") return <RiEBikeFill />;
  };
  console.log("vehicles", vehicles);
  return (
    <>
      {vehicles ? (
        <div
          className={`single-card-wrapper text-white`}
          style={{
            backgroundColor: props?.bg_color,
          }}
        >
          <div className="card-title">
            <h4>
              <b className="title">{props.title}</b>
            </h4>
          </div>

          <div className="count-group">
            <div className="text-center">
              <h4 className="card-inside-text">Passengers</h4>
              <h1 className="count">
                <b>
                  {props?.dash_board_type === "AppliedTotal"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (
                            (curr?.total_applied_passengers_count || 0) + acc
                          );
                        }, 0)
                      )
                    : props?.dash_board_type === "AppliedToday"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (
                            (Number(
                              curr?.today_yet_to_enroll_passengers_count
                            ) || 0) + acc
                          );
                        }, 0)
                      )
                    : props?.dash_board_type === "AppliedTomorrow"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (
                            (curr?.tomorrow_applied_passengers_count || 0) + acc
                          );
                        }, 0)
                      )
                    : props?.dash_board_type === "EntryTotal"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (
                            (curr?.total_passenger_entry_overall || 0) + acc
                          );
                        }, 0)
                      )
                    : props?.dash_board_type === "EntryToday"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (curr?.total_passenger_entry_today || 0) + acc;
                        }, 0)
                      )
                    : props?.dash_board_type === "EntryYesterday"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (
                            (curr?.total_passenger_entry_yesterday || 0) + acc
                          );
                        }, 0)
                      )
                    : props?.dash_board_type === "InsideNow"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (curr?.total_passenger_inside_now || 0) + acc;
                        }, 0)
                      )
                    : ""}
                </b>
              </h1>
            </div>
            <div className="text-center">
              <h4 className="card-inside-text">Vehicles</h4>
              <h2 className="count">
                <b>
                  {" "}
                  {props?.dash_board_type === "AppliedTotal"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (
                            (curr?.total_applied_vehicles_count || 0) + acc
                          );
                        }, 0)
                      )
                    : props?.dash_board_type === "AppliedToday"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (
                            (Number(curr?.today_yet_to_enroll_vehicles_count) ||
                              0) + acc
                          );
                        }, 0)
                      )
                    : props?.dash_board_type === "AppliedTomorrow"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (
                            (curr?.tomorrow_applied_vehicles_count || 0) + acc
                          );
                        }, 0)
                      )
                    : props?.dash_board_type === "EntryTotal"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (curr?.total_vehicle_entry_overall || 0) + acc;
                        }, 0)
                      )
                    : props?.dash_board_type === "EntryToday"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (curr?.total_vehicle_entry_today || 0) + acc;
                        }, 0)
                      )
                    : props?.dash_board_type === "EntryYesterday"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (
                            (curr?.total_vehicle_entry_yesterday || 0) + acc
                          );
                        }, 0)
                      )
                    : props?.dash_board_type === "InsideNow"
                    ? countFormat(
                        vehicles?.reduce((acc, curr) => {
                          return (curr?.total_vehicles_inside_now || 0) + acc;
                        }, 0)
                      )
                    : ""}
                </b>
              </h2>
            </div>
          </div>

          <div className="vehicle-list">
            {vehicles?.map((vehicle, index) => (
              <div className="single-vehicle">
                <div className="icon-and-name">
                  {render_icon(vehicle?.vehicle_name)}
                  {` `}
                  <span className="vehicle-name">{vehicle?.vehicle_name}</span>
                </div>
                <div className="icon-and-name">
                  <h6
                    className="vehicle-count-single"
                    // style={{ marginTop: "5px" }}
                  >
                    &nbsp;
                    {`${
                      props?.dash_board_type === "AppliedTotal"
                        ? countFormat(vehicle?.total_applied_vehicles_count)
                        : props?.dash_board_type === "AppliedToday"
                        ? // ? vehicle?.today_applied_vehicles_count
                          vehicle?.today_yet_to_enroll_vehicles_count
                        : props?.dash_board_type === "AppliedTomorrow"
                        ? countFormat(vehicle?.tomorrow_applied_vehicles_count)
                        : props?.dash_board_type === "EntryTotal"
                        ? countFormat(vehicle?.total_vehicle_entry_overall)
                        : props?.dash_board_type === "EntryToday"
                        ? countFormat(vehicle?.total_vehicle_entry_today)
                        : props?.dash_board_type === "EntryYesterday"
                        ? countFormat(vehicle?.total_vehicle_entry_yesterday)
                        : props?.dash_board_type === "InsideNow"
                        ? countFormat(vehicle?.total_vehicles_inside_now)
                        : ""
                    }`}
                  </h6>
                </div>
              </div>
            ))}
          </div>
          {props?.dash_board_type === "AppliedToday" &&
            props?.show_yet_to_enter && (
              <h6 className="vehicle-list">
                <b className="card-note-text">
                  * Applied with validity today but not entered yet
                </b>
              </h6>
            )}
        </div>
      ) : (
        <>
          <SkeletonComponentSingleCard {...props} />
        </>
      )}
    </>
  );
};

export default SingleCardAdmin;
