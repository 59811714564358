import React, { useState } from "react";
import { Col, Form, Divider, Row } from "antd";
import { makeStyles } from "tss-react/mui";
import MuiInput from "@views/components/system/Forms/form_elements/mui_input";
import MuiSubmitButton from "@views/components/system/Forms/form_elements/mui_submit_button";
import EpassDownloadModal from "../epass_download_modal/epass_download_modal";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Radio,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import {
  purpose_of_visit_list,
  states_in_india,
  tamilnadu_districts,
} from "@helpers/constants";
import DatePicker from "@mui/lab/DatePicker";

const { Item } = Form;

const useStyles = makeStyles()((theme, _params, classes) => ({
  field: {
    width: "100%",
  },
  dateInput: {
    width: "100%", // Adjust this value as needed
  },
}));

const ApplyEPassForm = (props) => {
  const { handle_close_modal } = props;
  const { classes } = useStyles();

  const [visible, set_visible] = useState(false);
  const [country, set_country] = useState("");
  const [state, set_state] = useState("");
  const [vehicle, set_vehicle] = useState("");
  const [district, set_district] = useState("");
  const [purpose_of_visit, set_purpose_of_visit] = useState("");

  const [selected_from_date, set_selected_from_date] = useState(null);

  const handle_from_date_change = (date) => {
    set_selected_from_date(date);
  };
  const handle_country_change = (event) => {
    set_country(event.target.value);
    console.log(event.target.value, "ajsg");
  };
  const handle_state_change = (event) => {
    set_state(event.target.value);
  };
  const handle_district_change = (event) => {
    set_district(event.target.value);
  };
  const handle_vehicle_change = (event) => {
    set_vehicle(event.target.value);
  };
  const purpose_of_visit_change = (event) => {
    set_purpose_of_visit(event.target.value);
  };

  const handle_values_change = (val, values) => {};

  const handle_submit = (values) => {
    set_visible(true);
    console.log(values, "values");
  };

  const vehicle_type = [
    "Car",
    "Van",
    "Mini Bus",
    "Bus",
    "Two Wheelers",
    "Others",
  ];

  return (
    <>
      <Form
        name={"projects"}
        layout={"vertical"}
        onFinish={handle_submit}
        onValuesChange={handle_values_change}
        autoComplete="off"
        // className="user_form ant-select-selector ant-select-selection-search"
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={8}>
            <Item>
              <MuiInput
                field={"name"}
                label={"Applicant Name"}
                message={"Applicant name is required"}
                is_required={true}
                class_name={classes.field}
                input_type={"text"}
                is_loading={false}
              />
            </Item>
          </Col>
          <Col xs={24} sm={8}>
            <Item>
              <MuiInput
                field={"Vehicle_reg_number"}
                label={"Vehicle Registration Number"}
                message={"Vehicle registration number is required"}
                is_required={true}
                class_name={classes.field}
                input_type={"text"}
                is_loading={false}
              />
            </Item>
          </Col>
          <Col xs={24} sm={8}>
            <Item>
              <FormControl
                variant="standard"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="vehicle-label">Vehicle Type</InputLabel>
                <Select
                  labelId="vehicle-label"
                  id="vehicle-type"
                  name="vehicle_type"
                  value={vehicle}
                  onChange={handle_vehicle_change}
                >
                  {vehicle_type.map((vehicle, index) => (
                    <MenuItem key={index} value={vehicle}>
                      {vehicle}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={8}>
            <Item>
              <FormControl
                variant="standard"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="country-label">Country</InputLabel>
                <Select
                  labelId="country-label"
                  id="country"
                  name="country"
                  value={country}
                  onChange={handle_country_change}
                >
                  <MenuItem value={"India"}>India</MenuItem>
                  <MenuItem value={"China"}>China</MenuItem>
                </Select>
              </FormControl>
            </Item>
          </Col>

          <Col xs={24} sm={8}>
            {country === "India" ? (
              <Item>
                <FormControl
                  variant="standard"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel id="state-label">State</InputLabel>
                  <Select
                    labelId="state-label"
                    id="state"
                    name="state"
                    value={state}
                    onChange={handle_state_change}
                  >
                    {states_in_india.map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Item>
            ) : (
              <Item>
                <MuiInput
                  field={"state"}
                  label={"State"}
                  message={"State is required"}
                  is_required={true}
                  class_name={classes.field}
                  input_type={"text"}
                  is_loading={false}
                />
              </Item>
            )}
          </Col>

          <Col xs={24} sm={8}>
            <Item>
              {state === "Tamil Nadu" ? (
                <FormControl
                  variant="standard"
                  fullWidth
                  className={classes.formControl}
                >
                  <InputLabel id="state-label">District</InputLabel>
                  <Select
                    labelId="district-label"
                    id="district"
                    name="district"
                    value={district}
                    onChange={handle_district_change}
                  >
                    {tamilnadu_districts.map((district, index) => (
                      <MenuItem key={index} value={district}>
                        {district}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <MuiInput
                  field={"District"}
                  label={"District"}
                  message={"District is required"}
                  is_required={true}
                  class_name={classes.field}
                  input_type={"text"}
                  is_loading={false}
                />
              )}
            </Item>
          </Col>

          <Col xs={24} sm={8}>
            <Item>
              <MuiInput
                field={"address_line_1"}
                label={"Address Line 1"}
                message={"Address line1 is required"}
                is_required={true}
                class_name={classes.field}
                input_type={"text"}
                is_loading={false}
              />
            </Item>
          </Col>
          <Col xs={24} sm={8}>
            <Item>
              <MuiInput
                field={"address_line_2"}
                label={"Address Line 2"}
                message={"Address line2 is required"}
                class_name={classes.field}
                input_type={"text"}
                is_loading={false}
              />
            </Item>
          </Col>
          <Col xs={24} sm={8}>
            <Item>
              <MuiInput
                field={"pincode"}
                label={"PIN Code"}
                message={"City is required"}
                is_required={true}
                class_name={classes.field}
                input_type={"number"}
                is_loading={false}
              />
            </Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={8}>
            <Item>
              <TextField
                id="date"
                label={"Date of Entry"}
                type="date"
                field="date_of_entry"
                variant="standard"
                className={classes.dateInput}
                // defaultValue={default_value}
                //   onRateChange={(date) => {
                //     console.log("date", date);
                //   }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Item>
          </Col>
          <Col xs={24} sm={8}>
            <Item>
              <TextField
                id="date"
                label={"Date of Exit"}
                type="date"
                field="date_of_exit"
                variant="standard"
                className={classes.dateInput}
                // defaultValue={default_value}
                //   onRateChange={(date) => {
                //     console.log("date", date);
                //   }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Item>
          </Col>
          <Col xs={24} sm={8}>
            <Item>
              <MuiInput
                field={"no_of_passangers"}
                label={"Number of Passengers"}
                message={"Number of passengers is required"}
                is_required={true}
                class_name={classes.field}
                is_loading={false}
                input_type={"number"}
              />
            </Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={8}>
            <Item>
              <FormControl
                variant="standard"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel id="purpose-of-visit-label">
                  Purpose of Visit
                </InputLabel>
                <Select
                  labelId="purpose-of-visit-label"
                  id="purpose_of_visit"
                  name="purpose_of_visit"
                  value={purpose_of_visit}
                  onChange={purpose_of_visit_change}
                  MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
                  renderValue={(value) => (
                    <>
                      <ListItemText primary={value} />
                    </>
                  )}
                >
                  {purpose_of_visit_list.map((value, index) => (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Col>
        </Row>

        <Divider />

        <Row justify="end">
          <Col>
            <div style={{ marginRight: 0 }}>
              <MuiSubmitButton
                label={"Submit"}
                action={"submit"}
                button_type={"primary"}
              />
            </div>
          </Col>
        </Row>
      </Form>

      <EpassDownloadModal visible={visible} set_visible={set_visible} />
    </>
  );
};

export default ApplyEPassForm;
