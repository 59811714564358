import { gql } from "@apollo/client";

export const query_get_checker_list = gql`
  query get_checker_list($pagination: pagination_input) {
    get_checker_list(pagination: $pagination) {
      items {
        id
        name
        mobile
        last_login_datetime
        verified_application_count
      }
      pagination {
        total_count
        page_number
        page_limit
      }
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_create_checker = gql`
  mutation create_police($data: create_user_input) {
    create_police(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;

export const mutation_delete_checker = gql`
  mutation delete_checker($data: delete_user_input) {
    delete_checker(data: $data) {
      id
      status
      error {
        status_code
        message
      }
    }
  }
`;
