import { Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import AdminDashboard from "./admin_dashboard";
import { dynamicRequest, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { query_get_dashboard_count } from "@services/redux/slices/admin_dashboard/graphql_admin_dashboard_count";

const AdminDashboardTab = () => {
  const dispatch = useDispatch();
  const [tab_change, set_tab_change] = useState("");
  const onChange = (key) => {
    set_tab_change(key);
  };

  // useEffect(() => {
  //   if (tab_change) {
  //     get_dashboard_count(tab_change);
  //   }
  // }, [tab_change]);

  const get_dashboard_count = () => {
    let key = [{ key: "get_dashboard_count", loading: true }];
    let query = query_get_dashboard_count;
    let variables = {
      filter: { type: tab_change },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    get_dashboard_count("");
  }, []);

  const items = [
    {
      key: "visitor_dashboard",
      tab: "Visitor Dashboard",
      children: (
        <AdminDashboard
          show_inside={true}
          show_today_tomorrow={true}
          dashboard_type={"visitor"}
          tab_change={tab_change}
          // filters={filters}
          // set_filters={set_filters}
        />
      ),
    },
    {
      key: "local_resident",
      tab: "Localite Dashboard",
      children: (
        <AdminDashboard
          show_inside={false}
          show_today_tomorrow={false}
          dashboard_type={"localite"}
          tab_change={tab_change}
          // filters={filters}
          // set_filters={set_filters}
        />
      ),
    },
  ];

  return (
    <div className="dashboard-wrapper">
      {/* <div className="dashboard-filter-wrapper">
        <DashboardFilter filters={filters} set_filters={set_filters} />
      </div> */}
      <div className="dashboard-card">
        <Tabs
          defaultActiveKey="1"
          onChange={onChange}
          centered
          tabBarStyle={{ fontWeight: "bold", overflowY: "hidden" }}
        >
          {items.map((item) => (
            <Tabs.TabPane
              key={item.key}
              tab={
                <span
                  className="admin-dashboard-tab"
                  style={{ fontSize: "20px" }}
                >
                  {item.tab}
                </span>
              }
            >
              {item.children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default AdminDashboardTab;
