import React from "react";
import { Modal, Button } from "antd";
import AddCheckerForm from "./add_checker_form";

const AddCheckerModal = (props) => {
  const { visible, set_visible } = props;

  const handle_ok = () => {
    set_visible(false);
  };

  const handle_cancel = () => {
    set_visible(false);
  };

  return (
    <Modal
      title="Add Checker"
      open={visible}
      onCancel={handle_cancel}
      footer={null}
      destroyOnClose={true}
    >
      <AddCheckerForm
        handle_cancel={handle_cancel}
        get_all_checker_list={props?.get_checker_list}
      />
    </Modal>
  );
};

export default AddCheckerModal;
