import { export_to_excel } from "@helpers/functions";
import {
  dynamicClear,
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import {
  get_feedbacks_query,
  query_get_feedback_list_without_pagination,
} from "@services/redux/slices/feedbacks/graphql_feedback";
import { Button, Modal, Pagination, Select, Space, Table, Card } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useDispatch } from "react-redux";

const FeedBack = () => {
  const dispatch = useDispatch();
  const [feedback_filter, set_feedback_filter] = useState();
  const [feedback_value, set_feedback_value] = useState("");
  const [modal_open, set_modal_open] = useState(false);
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const { items, loading, pagination } =
    useDynamicSelector("get_feedback_list");
  const [deviceType, setDeviceType] = useState("desktop");

  const {
    items: feedback_list_without_pagination_items,
    loading: feedback_list_without_pagination_loading,
    pagination: feedback_list_without_pagination,
  } = useDynamicSelector("feedback_list_without_pagination");

  console.log(
    "feedback_list_without_pagination_items",
    feedback_list_without_pagination_items
  );

  useEffect(() => {
    const updateDeviceType = () => {
      if (window.innerWidth < 768) {
        setDeviceType("mobile");
      } else if (window.innerWidth < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("desktop");
      }
    };

    updateDeviceType();

    window.addEventListener("resize", updateDeviceType);

    return () => {
      window.removeEventListener("resize", updateDeviceType);
    };
  }, []);
  useEffect(() => {
    get_feedbacks();
  }, [feedback_filter]);

  useEffect(() => {
    feedback_list_download();
    dispatch(dynamicClear("feedback_list_without_pagination"));
  }, [feedback_list_without_pagination_items]);

  useEffect(() => {
    get_feedbacks();
  }, [page_number, page_limit]);

  const handle_view_feedback = (value) => {
    set_modal_open(true);
    set_feedback_value(value);
  };

  const feedback_list_download = () => {
    let arranged_data = arrange_xl_report_data(
      feedback_list_without_pagination_items,
      filters
    );
    export_to_excel(arranged_data ?? [], "Feedback List");
  };

  const arrange_xl_report_data = (data_items, filters) => {
    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let feedback_type_name =
        filters.find((obj) => obj?.id === data_items[i]?.feedback_type_id)
          ?.name || "Unknown";
      let row_data = {
        ["S.No"]: i + 1,
        ["Feedback Type"]: feedback_type_name,
        ["Submission Date"]: moment(data_items[i]?.created_datetime).format(
          "DD/MM/YYYY hh:mm a"
        ),
        ["Mobile"]: data_items[i]?.system_user?.mobile,
        ["Content"]: data_items[i]?.description,
      };
      rearranged_data.push(row_data);
    }
    return rearranged_data;
  };

  const action_items = (record) => {
    return (
      <Space
        style={{
          width: "90px",
          justifyContent: "center",
        }}
      >
        <div onClick={() => handle_view_feedback(record)}>
          <FaEye style={{ cursor: "pointer" }} />
        </div>
      </Space>
    );
  };
  const columns = [
    {
      title: "Feedback Type",
      key: "feedback type",
      render: (record) => {
        const feedbackId = record?.feedback_type_id;
        const filter = filters.find((f) => f.id === feedbackId);
        return filter ? filter.name : "Unknown";
      },
    },
    // {
    //   title: "Name",
    //   key: "name",
    //   render: (record) => {
    //     return record?.system_user?.name;
    //   },
    // },
    {
      title: "Contact",
      key: "contact",
      align: "center",
      render: (record) => {
        return record?.system_user?.mobile || record?.system_user?.email;
      },
    },
    {
      title: "Submitted Date",
      dataIndex: "",
      key: "date_time",
      align: "center",
      render: (record) => {
        return moment(record?.created_datetime).format("DD/MM/YYYY hh:mm a");
      },
    },
    {
      title: "View",
      key: "view",
      align: "center",
      render: (record) => action_items(record?.description),
    },
  ];

  const filters = [
    {
      id: "b8278c26-19f5-4b6c-842d-73a1b06ffa73",
      name: "General Feedback",
    },
    { id: "ae4e383a-62a4-4652-a45a-576d857ab740", name: "Grievance" },
    {
      id: "72ebb4b8-5fd4-40f6-a521-4420bfe6e428",
      name: "Portal Issues",
    },
    {
      id: "819d81b4-cada-4a55-afae-d00fc2ae685b",
      name: "Portal Suggestions",
    },
    { id: "71d0f50f-78fd-4b79-8b18-8170309aad68", name: "Others" },
  ];

  const handle_change = (value) => {
    set_feedback_filter(value);
  };

  const get_feedbacks = () => {
    let key = [{ key: "get_feedback_list", loading: true }];
    let query = get_feedbacks_query;
    let variables = {
      data: {
        feedback_type_ids: feedback_filter,
      },
      pagination: {
        page_number: page_number,
        page_limit: page_limit,
      },
    };
    dispatch(dynamicRequest(key, query, variables, "Q"));
  };

  const get_checker_list_without_pagination = () => {
    let key = [{ key: "feedback_list_without_pagination", loading: true }];
    let query = query_get_feedback_list_without_pagination;
    let variables = {
      data: {
        feedback_type_ids: feedback_filter,
      },
      pagination: {
        page_number: 1,
        page_limit: pagination?.total_count,
      },
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  const fetch_data = (page_number, page_limit) => {
    set_page_limit(page_limit);
    set_page_number(page_number);
  };

  return (
    <div className="list-wrapper">
      <div className="table-filter" style={{ marginBottom: 2 }}>
        <Space
          style={{ width: "100%", display: "flex" }}
          justifyContent={"space-between"}
        >
          <Select
            mode="multiple"
            placeholder="Feedback type"
            style={{ width: 160 }}
            value={feedback_filter}
            onChange={handle_change}
            allowClear={true}
          >
            {filters.map((filter) => (
              <Option value={filter.id} key={filter.id}>
                {filter.name}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={get_checker_list_without_pagination}
            loading={feedback_list_without_pagination_loading}
          >
            Download Excel
          </Button>
        </Space>
      </div>
      <div>
        {deviceType === "mobile" ? (
          <div style={{ marginTop: "10px" }}>
            {items?.map((x) => (
              <Card style={{ marginBottom: "15px" }}>
                <table>
                  <tr>
                    <th>Feedback Type</th>
                    <td>
                      {filters.find((f) => f.id === x?.feedback_type_id)
                        ?.name || "Unknown"}
                    </td>
                  </tr>
                  <tr>
                    <th>Contact</th>
                    <td>{x?.system_user?.mobile || x?.system_user?.email}</td>
                  </tr>
                  <tr>
                    <th>Submitted Date</th>
                    <td>{moment(x?.created_datetime).format("DD/MM/YYYY")}</td>
                  </tr>
                  <tr>
                    <th>View</th>
                    <td>{action_items(x?.description)}</td>
                  </tr>
                </table>
              </Card>
            ))}
          </div>
        ) : (
          <Table
            dataSource={items}
            columns={columns}
            pagination={false}
            className="custom-table"
            rowClassName={"custom-row"}
            loading={loading}
          />
        )}
        <div className="table-pagination">
          <Pagination
            showTotal={(total) => `Total ${total} items`}
            total={pagination?.total_count}
            showSizeChanger={true}
            page_number={3}
            hideOnSinglePage={false}
            onChange={fetch_data}
            current={page_number}
          />
        </div>
        <Modal
          title="Feedback"
          open={modal_open}
          onCancel={() => set_modal_open(false)}
          footer={null}
        >
          <div
            style={{
              border: "1px solid black",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <p
              style={{
                fontSize: "17px",
              }}
            >
              {feedback_value}
            </p>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default FeedBack;
