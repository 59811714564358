import { useState, useEffect } from "react";
import { retrieveItem } from "./storage";

const useCheckLogin = () => {
  // const [is_logged_in, setIsLoggedIn] = useState(null);
  let is_logged_in = null;
  // useEffect(() => {
  let token = retrieveItem("user");
  try {
    if (token) {
      is_logged_in = true;
    } else {
      is_logged_in = false;
    }
  } catch (e) {
    is_logged_in = false;
  }
  // });
  return is_logged_in;
};

const usePageAccess = (key) => {
  // const [hasAccess, setHasAccess] = useState(null);
  let hasAccess = null;
  // useEffect(() => {
  let page_accesses = retrieveItem("page_accesses");

  try {
    let pageAccess = page_accesses.find((x) => x === key);
    if (pageAccess) {
      // setHasAccess(true);
      hasAccess = true;
    } else {
      // setHasAccess(false);
      hasAccess = false;
    }
  } catch (e) {
    // setHasAccess(false);
    hasAccess = false;
  }
  // });
  return hasAccess;
};

const usePageComponentAccess = (key) => {
  const [hasAccess, setHasAccess] = useState(null);
  useEffect(() => {
    let page_component_accesses = retrieveItem("page_component_accesses");
    try {
      let pageComponentAccess = page_component_accesses.find((x) => x === key);
      if (pageComponentAccess) {
        setHasAccess(true);
      } else {
        setHasAccess(false);
      }
    } catch (e) {
      setHasAccess(false);
    }
  });
  return hasAccess;
};

export {
  useCheckLogin as useCheckLogin,
  usePageAccess as usePageAccess,
  usePageComponentAccess as usePageComponentAccess,
};
