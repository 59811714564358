import React, { useState } from "react";
import { Modal, Button } from "antd";

const EpassDownloadModal = (props) => {
  const { visible, set_visible } = props;

  const show_modal = () => {
    set_visible(true);
  };

  const handle_ok = () => {
    console.log("Clicked OK");
    set_visible(false);
  };

  const handle_cancel = () => {
    console.log("Clicked Cancel");
    set_visible(false);
  };

  const handle_download = () => {
    console.log("Download initiated");
  };

  return (
    <div>
      {/* <Button type="primary" onClick={show_modal}>
        Open Modal
      </Button> */}

      <Modal
        title="This is your E-Pass"
        open={visible}
        onOk={handle_ok}
        onCancel={handle_cancel}
        footer={[
          <Button key="cancel" onClick={handle_cancel}>
            Back
          </Button>,
          <Button key="download" type="primary" onClick={handle_download}>
            Download
          </Button>,
        ]}
      >
        <div className="epass-download-modal">
          <p></p>
        </div>
      </Modal>
    </div>
  );
};

export default EpassDownloadModal;
