import React, { useEffect, useState } from "react";
import { useCheckLogin } from "@helpers/auth";
import { navigate } from "@helpers/navigator";
import { Box } from "@mui/material";
import LoginForm from "@views/components/auth/login_form";
import LoginFormAdmin from "@views/components/auth/login_form_admin";
import { ROUTES } from "@views/routes/my_routes";
import ReasonReportList from "@views/components/admin_reports/reason_report";

const ReasonReport = (props) => {
  return (
    <Box flex={1}>
      <ReasonReportList />
    </Box>
  );
};
export default ReasonReport;
