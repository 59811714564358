import React from "react";
import { dynamicSet } from "@services/redux";
import { useDispatch } from "react-redux";
import BusinessIcon from "@mui/icons-material/Business";
import { IconButton } from "@mui/material";
const ManagePageComponent = (props) => {
  const { page_id } = props;
  const dispatch = useDispatch();

  const openAddPageComponent = () => {
    dispatch(dynamicSet("page_component_page_id", page_id));
    dispatch(dynamicSet("page_component_action", "Add"));
  };

  return (
    <IconButton
      sx={{ cursor: "pointer", color: "brown" }}
      onClick={openAddPageComponent}
    >
      <BusinessIcon
        sx={{
          fontSize: 14,
        }}
      />
    </IconButton>
  );
};
export default ManagePageComponent;
