import { to_disabled_range } from "@helpers/constants";
import { export_to_excel } from "@helpers/functions";
import {
  dynamicRequest,
  useDynamicSelector,
} from "@services/redux";
import { query_fuel_type } from "@services/redux/slices/reports/graphql_fuel_type_report";
import { Button, Card, DatePicker, Spin, Table } from "antd";
import { capitalize } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
const { RangePicker } = DatePicker;

const FuelTypeReport = () => {
  const dispatch = useDispatch();

  const [fuel_type, set_fuel_type] = useState("desktop");
  const [report_items, set_report_items] = useState([]);
  const [items, set_items] = useState([]);
  const [filters, set_filters] = useState([]);

  const { items: vehicle_report_items, loading: vehicle_reports_loading } =
    useDynamicSelector("get_fuel_type_on_date_basis_report");


  useEffect(() => {
    const update_fuel_type = () => {
      if (window.innerWidth < 768) {
        set_fuel_type("mobile");
      } else if (window.innerWidth < 1024) {
        set_fuel_type("tablet");
      } else {
        set_fuel_type("desktop");
      }
    };

    update_fuel_type();

    window.addEventListener("resize", update_fuel_type);

    return () => {
      window.removeEventListener("resize", update_fuel_type);
    };
  }, []);

  useEffect(() => {
    if (vehicle_report_items) {
      set_report_items(vehicle_report_items);
    }
  }, [vehicle_report_items]);

  useEffect(() => {
    get_fuel_type_report_list();
  }, [filters]);

  const get_fuel_type_report_list = () => {
    let key = [{ key: "get_fuel_type_on_date_basis_report", loading: true }];
    let query = query_fuel_type;
    let variables = {
      filter: filters,
    };
    dispatch(dynamicRequest(key, query, variables));
  };

  useEffect(() => {
    let data = report_items?.map((item) => ({
      date_time: item.date,
      total_count: item.total_count,
      petrol: getCountByReason(item.counts, "petrol"),
      diesel: getCountByReason(item.counts, "diesel"),
      cng: getCountByReason(item.counts, "cng"),
      electric: getCountByReason(item.counts, "electric"),
      lpg: getCountByReason(item.counts, "lpg"),
    }));
    set_items(data);
  }, [report_items]);

  const getCountByReason = (counts, vehicle) => {
    const countItem = counts.find((count) => count.field_name === vehicle);
    return countItem ? countItem.count : 0;
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date_time",
      key: "date_time",
      render: (date) => {
        return <p>{moment(date).format("DD/MM/YYYY")}</p>;
      },
    },
    {
      title: "Petrol",
      dataIndex: "petrol",
      key: "petrol",
    },
    {
      title: "Diesel",
      dataIndex: "diesel",
      key: "diesel",
    },
    {
      title: "CNG",
      dataIndex: "cng",
      key: "cng",
    },
    {
      title: "Electric",
      dataIndex: "electric",
      key: "electric",
    },

    {
      title: "LPG",
      dataIndex: "lpg",
      key: "lpg",
    },
    {
      title: "Total Count",
      dataIndex: "total_count",
      key: "total_count",
    },
  ];

  const fuel_type_download = () => {
    let arranged_data = arrange_xl_report_data(items);
    export_to_excel(arranged_data ?? [], "Fuel Report");
  };

  const arrange_xl_report_data = (data_items) => {
    let totalCounts = {
      ["S.No"]: "Total",
      ["Date"]: "", // You can leave this empty for the total row
      ["Petrol"]: 0,
      ["Diesel"]: 0,
      ["CNG"]: 0,
      ["Electric"]: 0,
      ["LPG"]: 0,
      ["Total Count"]: 0,
    };

    const rearranged_data = [];
    for (let i = 0; i < data_items?.length; i++) {
      let row_data = {
        ["S.No"]: i + 1,
        ["Date"]: moment(data_items[i]?.date_time).format("DD/MM/YYYY"),
        ["Petrol"]: data_items[i]?.petrol,
        ["Diesel"]: data_items[i]?.diesel,
        ["CNG"]: capitalize(data_items[i]?.cng),
        ["Electric"]: data_items[i]?.electric,
        ["LPG"]: data_items[i]?.lpg,
        ["Total Count"]: data_items[i]?.total_count,
      };

      // Update total counts
      totalCounts["Petrol"] += data_items[i]?.petrol;
      totalCounts["Diesel"] += data_items[i]?.diesel;
      totalCounts["Electric"] += data_items[i]?.cng;
      totalCounts["CNG"] += data_items[i]?.electric;
      totalCounts["LPG"] += data_items[i]?.lpg;
      totalCounts["Total Count"] += data_items[i]?.total_count;

      rearranged_data.push(row_data);
    }

    // Push total counts row to the end
    rearranged_data.push(totalCounts);

    return rearranged_data;
  };

  const date_range_picker_applied = (dates) => {
    if (dates === null) {
      set_filters({
        ...filters,
        from_date: null,
        to_date: null,
      });
    }
    if (dates) {
      set_filters({
        ...filters,
        from_date: new Date(dates[0]),
        to_date: new Date(dates[1]),
      });
    }
  };

  return (
    <>
      <div className="list-wrapper">
        <div className="excel-download-button">
          <div style={{ width: "260px" }}>
            <RangePicker
              width={"200px"}
              placeholder={["From Date", "To Date"]}
              onChange={date_range_picker_applied}
              allowClear={true}

              format="DD/MM/YYYY"
              disabledDate={(date) =>
                to_disabled_range(date, "2024-05-06", "2024-06-30")
              }
            />
          </div>
          <Button
            type="primary"
            onClick={fuel_type_download}
          >
            Download
          </Button>
        </div>

        <div>
          {fuel_type === "mobile" ? (
            <div style={{ marginTop: "10px" }}>
              {vehicle_reports_loading && (
                <div className="spinner">
                  <Spin size="large" />
                </div>
              )}
              {items &&
                items?.map((x) => {
                  console.log(x, "x");

                  return (
                    <Card style={{ marginBottom: "15px" }}>
                      <table>
                        <tr>
                          <th>Date</th>
                          <td>{moment(x?.date).format("DD/MM/YYYY")}</td>
                        </tr>
                        <tr>
                          <th>Petrol</th>
                          <td>{x?.petrol || 0}</td>
                        </tr>
                        <tr>
                          <th>Diesel</th>
                          <td>{x?.diesel || 0}</td>
                        </tr>

                        <tr>
                          <th>LPG</th>
                          <td>{x?.lpg || 0}</td>
                        </tr>
                        <tr>
                          <th>CNG</th>
                          <td>{x?.cng || 0}</td>
                        </tr>
                        <tr>
                          <th>Eclectic</th>
                          <td>{x?.electric || 0}</td>
                        </tr>
                        <tr>
                          <th>Total Count</th>
                          <td>{x?.total_count || 0}</td>
                        </tr>
                      </table>
                    </Card>
                  );
                })}
            </div>
          ) : (
            <Table
              dataSource={items}
              columns={columns}
              pagination={false}
              loading={vehicle_reports_loading}
              rowClassName={"custom-row"}
              bordered
            />
          )}
        </div>
      </div>
    </>
  );
};

export default FuelTypeReport;
